import { inputsLabelsConstants } from "@constants";

const {
    email: emailInputLabel,
    password: passwordInputLabel,
} = inputsLabelsConstants;

const loginFormData = [
    {
        col: { xs: 12 },
        label: emailInputLabel,
        name: "email",
    },
    {
        col: { xs: 12 },
        label: passwordInputLabel,
        name: "password",
    },
];

export default loginFormData;
