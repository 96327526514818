import * as types from "redux/types";

const initialState = { show: true };

const loadingReducer = (state = initialState, action) => {
    const { type } = action;

    switch (type) {
    case types.SHOW_LOADING_LOADER: return { show: true };

    case types.HIDE_LOADING_LOADER: return { show: false };

    default: return state;
    }
};

export default loadingReducer;
