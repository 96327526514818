import { combineReducers } from "redux";
import storage from "redux-persist/lib/storage";

import adminsReducer from "./admins";
import alertReducer from "./alert";
import authReducer from "./auth";
import eventsReducer from "./events";
import loadingReducer from "./loading";
import photographersReducer from "./photographers";
import sendingReducer from "./sending";
import sidebarActiveTabReducer from "./sidebar-active-tab";
import themeReducer from "./theme";

export const initialState = {
    key: "root",
    storage,
    whitelist: [],
};

export const rootReducer = combineReducers({
    adminsReducer,
    alertReducer,
    authReducer,
    eventsReducer,
    loadingReducer,
    photographersReducer,
    sendingReducer,
    sidebarActiveTabReducer,
    themeReducer,
});
