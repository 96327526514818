import { inputsLabelsConstants } from "@constants";

const {
    confirmPassword: confirmPasswordInputLabel,
    password: passwordInputLabel,
} = inputsLabelsConstants;

const setPasswordFormData = [
    {
        col: { xs: 12 },
        label: passwordInputLabel,
        name: "password",
    },
    {
        col: { xs: 12 },
        label: confirmPasswordInputLabel,
        name: "confirmPassword",
    },
];

export default setPasswordFormData;
