import { yupResolver } from "@hookform/resolvers/yup";
import { TextField } from "@mui/material";
import { updateEventFormData } from "data";
import { FormLayout } from "layouts";
import { useCallback, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import * as types from "redux/types";
import { updateEventSocialSharingFormSchema } from "schemas";
import { debounce, renderFormController } from "utils";

function UpdateEventSocialSharingForm({
    action,
    onSubmitForm,
    tabValue,
    values,
}) {
    const dispatch = useDispatch();

    const formMethods = useForm({
        defaultValues: values,
        mode: "onChange",
        resolver: yupResolver(updateEventSocialSharingFormSchema),
    });

    const {
        control,
        formState: { errors },
        setValue,
    } = formMethods;

    const formData = updateEventFormData.forms.socialSharing;

    const changeTextFieldHandler = useCallback( // eslint-disable-line
        debounce(
            (value, requestName) => {
                dispatch({
                    payload: {
                        data: {
                            name: requestName,
                            value,
                        },
                    },
                    type: types.CHANGE_EVENT_UPDATE_FORM_FIELD,
                });
            },
            500,
        ),
        [],
    );

    const renderFormControllerChildren = (
        field,
        label,
        name,
        type,
        requestName,
    ) => (
        <TextField
            {...field}
            error={errors[name]}
            helperText={errors[name] && errors[name]?.message}
            label={label}
            maxRows={8}
            minRows={4}
            multiline={type === "text-area"}
            variant="outlined"
            fullWidth
            onInput={(e) => {
                changeTextFieldHandler(
                    e.target.value,
                    requestName,
                );
            }}
        />
    );

    useEffect(() => {
        Object.keys(values).forEach((key) => {
            setValue(
                key,
                values?.[key],
            );
        });
    }, [tabValue]); // eslint-disable-line

    return (
        <FormLayout
            action={action}
            formMethods={formMethods}
            data={renderFormController(
                control,
                formData,
                renderFormControllerChildren,
            )}
            onSubmitForm={onSubmitForm}
        />
    );
}

export default UpdateEventSocialSharingForm;
