import * as types from "redux/types";

const initialState = {
    activeTab: {
        id: null,
        parentId: null,
    },
};

const sidebarActiveTabReducer = (state = initialState, action) => {
    const {
        payload,
        type,
    } = action;

    const { activeTab } = payload || {};

    if (type === types.SET_SIDEBAR_ACTIVE_TAB) {
        return {
            ...state,
            activeTab: {
                id: activeTab?.id,
                route: activeTab?.route,
            },
        };
    }

    return state;
};

export default sidebarActiveTabReducer;
