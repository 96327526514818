import "react-awesome-lightbox/build/style.css";

import ReactLightbox from "react-awesome-lightbox";

function Lightbox({
    lightboxData,
    setLightboxData,
}) {
    return lightboxData.images.length > 1 ? (
        <ReactLightbox
            images={lightboxData.images}
            key={lightboxData.startIndex}
            startIndex={lightboxData.startIndex}
            onClose={() => setLightboxData({
                images: [],
                open: false,
                startIndex: 0,
            })}
        />
    ) : (
        <ReactLightbox
            image={lightboxData.images[0].url}
            key={lightboxData.startIndex}
            title={lightboxData.images[0].title}
            onClose={() => setLightboxData({
                images: [],
                open: false,
                startIndex: 0,
            })}
        />
    );
}

export default Lightbox;
