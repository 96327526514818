import { actionsConstants, btnsNamesConstants } from "@constants";
import { LoadingButton } from "@mui/lab";
import {
    Box, Button, CardContent, Grid,
} from "@mui/material";
import { useEffect } from "react";
import { FormProvider } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import * as types from "redux/types";
import { StyledCard } from "styles";

function FormLayout({
    action,
    data,
    formMethods,
    hasSteps,
    onSubmitForm,
    otherFields,
    stepBtnData,
}) {
    const dispatch = useDispatch();

    const sending = useSelector((state) => state.sendingReducer.show);

    const { key: updateKey } = actionsConstants.update;

    const {
        create: createBtnName,
        save: saveBtnName,
    } = btnsNamesConstants;

    const {
        formState: {
            dirtyFields,
            errors,
        },
        getValues,
        handleSubmit,
        reset,
    } = formMethods;

    useEffect(() => {
        if (Object.entries(errors).length > 0) {
            dispatch({
                payload: { data: false },
                type: types.CHANGE_EVENT_UPDATE_FORM_VALIDATION,
            });
        } else {
            dispatch({
                payload: { data: true },
                type: types.CHANGE_EVENT_UPDATE_FORM_VALIDATION,
            });
        }
    }, [Object.entries(errors).length]); // eslint-disable-line

    return (
        <FormProvider {...formMethods}>
            <form
                onSubmit={handleSubmit(() => {
                    localStorage.setItem( // eslint-disable-line
                        "submit",
                        true,
                    );

                    onSubmitForm(
                        getValues(),
                        reset,
                    );
                })}
            >
                <Grid
                    spacing={2}
                    container
                >
                    <Grid
                        xs={12}
                        item
                    >
                        <StyledCard>
                            <CardContent>
                                <Grid
                                    spacing={2}
                                    container
                                >
                                    {data}
                                    {otherFields}
                                </Grid>
                            </CardContent>
                        </StyledCard>
                        <Grid
                            display="flex"
                            gap={2}
                            justifyContent="space-between"
                            mb={2}
                            item
                        >
                            {hasSteps && (
                                <Button
                                    size="large"
                                    startIcon={stepBtnData.icon}
                                    variant="outlined"
                                    onClick={stepBtnData.action}
                                >
                                    {stepBtnData.text}
                                </Button>

                            )}
                            <Box>
                                <LoadingButton
                                    color="primary"
                                    disabled={Object.keys(dirtyFields).length === 0 && action === updateKey}
                                    loading={sending}
                                    size="large"
                                    type="submit"
                                    variant="contained"
                                >
                                    {action === updateKey ? saveBtnName : createBtnName}
                                </LoadingButton>
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
            </form>
        </FormProvider>
    );
}
export default FormLayout;
