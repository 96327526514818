const directionsData = {
    admins: [
        "asc",
        "asc",
        "asc",
        "asc",
    ],
    eventGuestPhotos: ["desc"],
    eventGuests: ["asc", "asc"],
    eventPhotos: ["desc"],
    events: ["asc"],
    photographers: [
        "asc",
        "asc",
        "asc",
        "asc",
    ],
};

export default directionsData;
