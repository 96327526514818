import {
    btnsNamesConstants,
    headerTitlesConstants,
    tooltipsConstants,
} from "@constants";
import { yupResolver } from "@hookform/resolvers/yup";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
    CardContent,
    Grid,
    IconButton,
    InputAdornment,
    TextField,
    Tooltip,
    Typography,
} from "@mui/material";
import logo from "assets/images/logo.svg";
import { Media } from "atoms";
import { loginFormData } from "data";
import { useState } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { loginFormSchema } from "schemas";
import { StyledCard } from "styles";

function LoginForm({
    onSubmitForm,
    values,
}) {
    const sending = useSelector((state) => state.sendingReducer.show);

    const [showPassword, setShowPassword] = useState(false);

    const formMethods = useForm({
        defaultValues: values,
        mode: "onChange",
        resolver: yupResolver(loginFormSchema),
    });

    const { login: loginHeaderTitle } = headerTitlesConstants.auth;

    const { login: loginBtnName } = btnsNamesConstants;

    const {
        hidePassword: hidePasswordTooltip,
        showPassword: showPasswordTooltip,
    } = tooltipsConstants;

    const {
        control,
        formState: { errors },
        getValues,
        handleSubmit,
        reset,
    } = formMethods;

    return (
        <FormProvider {...formMethods}>
            <form
                onSubmit={handleSubmit(() => onSubmitForm(
                    getValues(),
                    reset,
                ))}
            >
                <Grid
                    alignItems="center"
                    flexDirection="column"
                    gap={2}
                    container
                >
                    <Grid
                        bgcolor="#fff"
                        borderRadius={2}
                        xs={12}
                        item
                    >
                        <Media
                            alternativeText="logo"
                            height={80}
                            src={logo}
                            type="image"
                            width={220}
                        />
                    </Grid>
                    <Grid
                        xs={12}
                        item
                    >
                        <Typography
                            color="primary"
                            component="h1"
                            mb={2}
                            variant="h5"
                        >
                            {loginHeaderTitle}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid
                    gap={2}
                    container
                >
                    <Grid
                        xs={12}
                        item
                    >
                        <StyledCard>
                            <CardContent>
                                <Grid
                                    gap={2}
                                    container
                                >
                                    {loginFormData.map(({
                                        col,
                                        label,
                                        name,
                                    }) => (
                                        <Grid
                                            {...col}
                                            key={name}
                                            item
                                        >
                                            <Controller
                                                control={control}
                                                name={name}
                                                render={({ field }) => (
                                                    <TextField
                                                        {...field}
                                                        error={errors[name]}
                                                        helperText={errors[name] && errors[name]?.message}
                                                        label={label}
                                                        type={name === "email" || !showPassword ? name : "text"}
                                                        variant="outlined"
                                                        InputProps={{
                                                            endAdornment: name === "password" && (
                                                                <InputAdornment position="end">
                                                                    <Tooltip title={showPassword ? hidePasswordTooltip : showPasswordTooltip}>
                                                                        <IconButton onClick={() => setShowPassword(!showPassword)}>{showPassword ? <VisibilityOff /> : <Visibility />}</IconButton>
                                                                    </Tooltip>
                                                                </InputAdornment>
                                                            ),
                                                        }}
                                                        fullWidth
                                                    />
                                                )}
                                            />
                                        </Grid>
                                    ))}
                                </Grid>
                            </CardContent>
                        </StyledCard>
                        <Grid mb={2}>
                            <LoadingButton
                                color="primary"
                                loading={sending}
                                size="large"
                                type="submit"
                                variant="contained"
                                fullWidth
                            >
                                {loginBtnName}
                            </LoadingButton>
                        </Grid>
                    </Grid>
                </Grid>
            </form>
        </FormProvider>
    );
}

export default LoginForm;
