import { actionsConstants, viewsConstants } from "@constants";
import { Box, Typography } from "@mui/material";
import { Loader } from "atoms";
import { Header } from "components";
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";
import { NotFoundView, ServerErrorView } from "views";

function CreateAndUpdateLayout({
    FormComponent,
    action,
    backRouteUrl,
    error,
    eventFormData,
    hasHeaderBtn,
    headerBtnData,
    headerTitle,
    moduleName,
    onSubmitForm,
    pageTitle,
    tabValue,
    values,
}) {
    const loading = useSelector((state) => state.loadingReducer.show);

    const { key: updateKey } = actionsConstants.update;

    const {
        createEvent: {
            stepOne: stepOneConstant,
            stepTwo: stepTwoConstant,
        },
    } = viewsConstants;

    if (error?.status === 404) return <NotFoundView />;

    if (error?.status === 500) return <ServerErrorView />;

    return (
        <>
            <Helmet>
                <title>{pageTitle}</title>
            </Helmet>
            <Header
                backRouteUrl={backRouteUrl}
                hasBackBtn={!!backRouteUrl}
                hasHeaderBtn={hasHeaderBtn}
                headerBtnData={headerBtnData}
                title={headerTitle}
            />
            {eventFormData && (
                <Box
                    alignItems="center"
                    display="flex"
                >
                    <Typography
                        color="primary"
                        variant="h4"
                    >
                        {eventFormData.step === 1 ? stepOneConstant : stepTwoConstant}
                    </Typography>
                </Box>
            )}
            {loading && action === updateKey ? <Loader /> : FormComponent({
                action,
                eventFormData,
                moduleName,
                onSubmitForm,
                tabValue,
                values,
            })}
        </>
    );
}

export default CreateAndUpdateLayout;
