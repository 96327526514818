import { yupResolver } from "@hookform/resolvers/yup";
import { TextField } from "@mui/material";
import { adminFormData } from "data";
import { FormLayout } from "layouts";
import { useForm } from "react-hook-form";
import { adminAndPhotographerFormSchema } from "schemas";
import { renderFormController } from "utils";

function AdminForm({
    action,
    onSubmitForm,
    values,
}) {
    const formMethods = useForm({
        defaultValues: values,
        mode: "onChange",
        resolver: yupResolver(adminAndPhotographerFormSchema),
    });

    const {
        control,
        formState: { errors },
    } = formMethods;

    const renderFormControllerChildren = (field, label, name) => (
        <TextField
            {...field}
            error={errors[name]}
            helperText={errors[name] && errors[name]?.message}
            label={label}
            variant="outlined"
            fullWidth
        />
    );

    return (
        <FormLayout
            action={action}
            formMethods={formMethods}
            data={renderFormController(
                control,
                adminFormData,
                renderFormControllerChildren,
            )}
            onSubmitForm={onSubmitForm}
        />
    );
}

export default AdminForm;
