import { tableColumnsNamesConstants } from "@constants";

const {
    authorities: authoritiesColumnName,
    createdAt: createdAtColumnName,
    email: emailColumnName,
    isDefault: isDefaultColumnName,
    name: nameColumnName,
    phoneNumber: phoneNumberColumnName,
    photographers: photographersColumnName,
    status: statusColumnName,
    title: titleColumnName,
} = tableColumnsNamesConstants;

const tableData = {
    admins: {
        columnsNames: [nameColumnName, emailColumnName, phoneNumberColumnName, statusColumnName, authoritiesColumnName],
        fields: ["name", "email", "phoneNumber", "status", "authorities"],
    },
    eventGuests: {
        email: {
            columnsNames: [emailColumnName],
            fields: ["email"],
        },
        phone: {
            columnsNames: [phoneNumberColumnName],
            fields: ["phoneNumber"],
        },
    },
    eventTemplates: {
        columnsNames: [titleColumnName, createdAtColumnName],
        fields: ["title", "createdAt"],
    },
    events: {
        columnsNames: [titleColumnName, photographersColumnName],
        fields: ["title", "photographers"],
    },
    photographers: {
        columnsNames: [nameColumnName, emailColumnName, phoneNumberColumnName, statusColumnName, isDefaultColumnName],
        fields: ["name", "email", "phoneNumber", "status", "isDefault"],
    },
};

export default tableData;
