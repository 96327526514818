import {
    actionsConstants,
    btnsNamesConstants,
    messagesConstants,
    tooltipsConstants,
} from "@constants";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
    Box,
    Button,
    Grid,
    Link,
    TextField,
    Tooltip,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { actionsData, regexData, resendEventPhotoFormData } from "data";
import { useDeleteEventPhotoData, useResendEventPhotoData, useToggleEventSlideshowPhotoData } from "hooks";
import { useEffect, useState } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { downloadEventPhoto, openAlert, showLoadingLoader } from "redux/actions";
import * as types from "redux/types";
import { baseTheme as theme } from "theme";

function PhotoActionsMenu({
    dropboxOverlayImage,
    eventId,
    mainImage,
    modalData,
    moduleName,
    overlayImage,
    photoId,
    setModalData,
    slideshowStatus,
}) {
    const dispatch = useDispatch();

    const [anchorEl, setAnchorEl] = useState(null);

    const formMethods = useForm({
        defaultValues: {
            email: "",
            phoneNumber: "",
        },
    });

    const {
        isLoading: resendEventPhotoIsLoading,
        mutate,
    } = useResendEventPhotoData();

    const {
        isLoading: deleteEventPhotoIsLoading,
        mutate: deleteEventPhotoMutation,
    } = useDeleteEventPhotoData();

    const {
        add: addBtnName,
        delete: deleteBtnName,
        remove: removeBtnName,
        resend: resendBtnName,
    } = btnsNamesConstants;

    const {
        addToSlideshowMsgs: { alert: addToSlideshowAlertMsg },
        deleteMsgs: { alert: deleteAlertMsg },
        fail: failMsg,
        removeFromSlideshowMsgs: { alert: removeFromSlideshowAlertMsg },
        resendEventPhotoMsgs: {
            alert: resendEventPhotoAlertMsg,
            pending: resendEventPhotoPendingMsg,
            validation: resendEventPhotoValidationFailMsg,
        },
    } = messagesConstants;

    const {
        addAndRemoveSlideshow: { key: addAndRemoveSlideshowKey },
        addToSlideshow: { key: addToSlideshowKey },
        delete: { key: deleteKey },
        downloadOriginal: { key: downloadOriginalKey },
        downloadWithOverlay: { key: downloadWithOverlayKey },
        removeFromSlideshow: { key: removeFromSlideshowKey },
        resend: { key: resendKey },
        viewDropboxOverlay: { key: viewDropboxOverlayKey },
    } = actionsConstants;

    const { more: moreTooltip } = tooltipsConstants;

    const {
        allDigitsSameNumber: allDigitsSameNumberRegex,
        email: emailRegex,
        incrementingNumbers: incrementalNumbersRegex,
        phoneNumber: phoneNumberRegex,
        repeatingPatternTypeOne: repeatingPatternTypeOneRegex,
        repeatingPatternTypeTwo: repeatingPatternTypeTwoRegex,
    } = regexData;

    const {
        control,
        formState: { errors },
        getValues,
    } = formMethods;

    const openMenu = Boolean(anchorEl);

    const {
        isLoading: toggleEventSlideshowPhotoIsLoading,
        mutate: toggleEventSlideshowPhotoMutate,
    } = useToggleEventSlideshowPhotoData(slideshowStatus ? removeFromSlideshowKey : addToSlideshowKey);

    const clickPhotoActionHandler = (action) => {
        switch (action) {
        case downloadOriginalKey: dispatch(downloadEventPhoto(mainImage));
            break;
        case downloadWithOverlayKey: dispatch(downloadEventPhoto(overlayImage));
            break;
        case deleteKey:
            setModalData({
                btnColor: "primary",
                btnName: deleteBtnName,
                id: photoId,
                show: true,
                submitModalActionHandler: () => {
                    setModalData({
                        ...modalData,
                        show: false,
                    });

                    deleteEventPhotoMutation(photoId);
                },
                title: deleteAlertMsg,
            });
            break;
        case resendKey:
            setModalData({
                btnColor: "primary",
                btnName: resendBtnName,
                content: (
                    <FormProvider {...formMethods}>
                        <form>
                            <Grid
                                spacing={2}
                                container
                            >
                                {resendEventPhotoFormData.map(({
                                    col,
                                    label,
                                    name,
                                }) => (
                                    <Grid
                                        {...col}
                                        key={name}
                                        item
                                    >
                                        <Controller
                                            control={control}
                                            name={name}
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    error={errors[name]}
                                                    helperText={errors[name] && errors[name]?.message}
                                                    label={label}
                                                    variant="outlined"
                                                    fullWidth
                                                />
                                            )}
                                        />
                                    </Grid>
                                ))}
                            </Grid>
                        </form>
                    </FormProvider>
                ),
                eventId,
                photoId,
                show: true,
                submitModalActionHandler: () => {
                    const phoneNumberValue = getValues().phoneNumber;

                    const emailValue = getValues().email;

                    if (
                        emailValue.match(emailRegex)
                                    && phoneNumberValue.match(phoneNumberRegex)
                                    && phoneNumberValue.match(allDigitsSameNumberRegex)
                                    && phoneNumberValue.match(incrementalNumbersRegex)
                                    && phoneNumberValue.match(repeatingPatternTypeOneRegex)
                                    && phoneNumberValue.match(repeatingPatternTypeTwoRegex)
                                    && phoneNumberValue
                                    && emailValue
                    ) {
                        setModalData({
                            ...modalData,
                            show: false,
                        });

                        toast.loading(resendEventPhotoPendingMsg);

                        mutate({
                            data: [
                                { email: getValues().email },
                                { phoneNumber: getValues().phoneNumber },
                            ],
                            query: `?photoId=${photoId}&eventId=${eventId}`,
                        });
                    } else {
                        dispatch(openAlert(
                            resendEventPhotoValidationFailMsg,
                            failMsg,
                        ));
                    }
                },
                title: resendEventPhotoAlertMsg,
            });
            break;
        case addToSlideshowKey:
            setModalData({
                btnColor: "primary",
                btnName: addBtnName,
                id: photoId,
                show: true,
                submitModalActionHandler: () => {
                    setModalData({
                        ...modalData,
                        show: false,
                    });

                    toggleEventSlideshowPhotoMutate(photoId);
                },
                title: addToSlideshowAlertMsg,
            });
            break;
        case removeFromSlideshowKey:
            setModalData({
                btnColor: "primary",
                btnName: removeBtnName,
                id: photoId,
                show: true,
                submitModalActionHandler: () => {
                    setModalData({
                        ...modalData,
                        show: false,
                    });

                    toggleEventSlideshowPhotoMutate(photoId);
                },
                title: removeFromSlideshowAlertMsg,
            });
            break;
        }
    };

    const renderActions = (
        Icon,
        action,
        key,
        addToSlideshow,
        removeFromSlideshow,
    ) => {
        switch (key) {
        case downloadOriginalKey:
        case downloadWithOverlayKey:
        case viewDropboxOverlayKey:
            return (
                <MenuItem
                    component={Link}
                    href={key === downloadOriginalKey ? mainImage : key === downloadWithOverlayKey ? overlayImage : dropboxOverlayImage} // eslint-disable-line
                    key={key}
                    target="_blank"
                    style={{
                        display: key === viewDropboxOverlayKey && !dropboxOverlayImage && "none",
                        width: "100%",
                    }}
                    disableRipple
                    onClick={() => (key !== viewDropboxOverlayKey ? clickPhotoActionHandler(key) : {})}
                >
                    <Icon style={{ marginRight: "5px" }} />
                    {action}
                </MenuItem>
            );
        case deleteKey:
        case resendKey:
            return (
                <MenuItem
                    component={Button}
                    key={key}
                    style={{
                        textTransform: "none",
                        width: "100%",
                    }}
                    disableRipple
                    onClick={() => clickPhotoActionHandler(key)}
                >
                    <Icon style={{ marginRight: "5px" }} />
                    {action}
                </MenuItem>
            );
        case addAndRemoveSlideshowKey:
            if (slideshowStatus) {
                return (
                    <MenuItem
                        component={Button}
                        key={key}
                        style={{ width: "100%" }}
                        disableRipple
                        onClick={() => clickPhotoActionHandler(removeFromSlideshowKey)}
                    >
                        <removeFromSlideshow.Icon style={{ marginRight: "5px" }} />
                        {removeFromSlideshow.action}
                    </MenuItem>
                );
            }

            return (
                <MenuItem
                    component={Button}
                    key={key}
                    style={{
                        textTransform: "none",
                        width: "100%",
                    }}
                    disableRipple
                    onClick={() => clickPhotoActionHandler(addToSlideshowKey)}
                >
                    <addToSlideshow.Icon style={{ marginRight: "5px" }} />
                    {addToSlideshow.action}
                </MenuItem>
            );
        }
    };

    useEffect(() => {
        if (deleteEventPhotoIsLoading || toggleEventSlideshowPhotoIsLoading) {
            dispatch(showLoadingLoader());

            dispatch({ type: types.EVENTS_REQUEST });
        }

        if (resendEventPhotoIsLoading) dispatch({ type: types.EVENTS_REQUEST });
    }, [resendEventPhotoIsLoading || deleteEventPhotoIsLoading, toggleEventSlideshowPhotoIsLoading]); // eslint-disable-line

    return (
        <Box>
            <Tooltip title={moreTooltip}>
                <IconButton onClick={(event) => setAnchorEl(event.currentTarget)}><MoreVertIcon style={{ color: theme.palette.grey[100] }} /></IconButton>
            </Tooltip>
            <Menu
                anchorEl={anchorEl}
                open={openMenu}
                onClose={() => setAnchorEl(null)}
            >
                {actionsData[moduleName]?.photo.map(({
                    Icon,
                    action,
                    addToSlideshow,
                    key,
                    removeFromSlideshow,
                }) => renderActions(
                    Icon,
                    action,
                    key,
                    addToSlideshow,
                    removeFromSlideshow,
                ))}
            </Menu>
        </Box>
    );
}

export default PhotoActionsMenu;
