const routesUrlsData = {
    admins: {
        create: "/admins/create",
        url: "/admins",
    },
    auth: {
        login: "/auth/login",
        setPassword: "/auth/set-password",
    },
    events: {
        create: "/events/create",
        guests: "/guests",
        photos: "/photos",
        slideshow: "/slideshow",
        templates: {
            create: "/templates/create",
            url: "/templates",
        },
        url: "/events",
    },
    photographers: {
        create: "/photographers/create",
        url: "/photographers",
    },
    update: "/update",
};

export default routesUrlsData;
