import * as types from "redux/types";

const initialState = {
    activated: false,
    created: false,
    data: {
        data: [],
        noData: false,
        pagination: {},
    },
    deactivated: false,
    details: {},
    error: null,
    lastActivated: {},
    lastCreated: {},
    lastDeactivated: {},
    lastUpdated: {},
    resetPassword: false,
    updated: false,
};

const photographersReducer = (state = initialState, action) => {
    const {
        payload,
        type,
    } = action;

    const {
        data,
        error,
    } = payload || {};

    switch (type) {
    case types.GET_PHOTOGRAPHERS_SUCCESS:
        return {
            ...state,
            data,
        };

    case types.GET_PHOTOGRAPHER_SUCCESS:
        return {
            ...state,
            details: data,
        };

    case types.CREATE_PHOTOGRAPHER_SUCCESS:
        return {
            ...state,
            created: true,
            lastCreated: data,
        };

    case types.UPDATE_PHOTOGRAPHER_SUCCESS:
        return {
            ...state,
            lastUpdated: data,
            updated: true,
        };

    case types.ACTIVATE_PHOTOGRAPHER_SUCCESS:
        return {
            ...state,
            activated: true,
            lastActivated: data,
        };

    case types.DEACTIVATE_PHOTOGRAPHER_SUCCESS:
        return {
            ...state,
            deactivated: true,
            lastDeactivated: data,
        };

    case types.RESET_PHOTOGRAPHER_PASSWORD_SUCCESS:
        return {
            ...state,
            resetPassword: true,
        };

    case types.PHOTOGRAPHERS_REQUEST:
        return {
            ...state,
            activated: false,
            created: false,
            deactivated: false,
            error: null,
            resetPassword: false,
            updated: false,
        };

    case types.PHOTOGRAPHERS_FAIL:
        return {
            ...state,
            error,
        };

    default: return state;
    }
};

export default photographersReducer;
