import loadable from "@loadable/component";
import { Outlet, Route, Routes as ReactRoutes } from "react-router-dom";
import ProtectedRoute from "routes/protected-route";

const lazyLoadableComponents = {
    AdminsCreateView: loadable(() => import("views/dashboard/admins/create")),
    AdminsListView: loadable(() => import("views/dashboard/admins")),
    AdminsUpdateView: loadable(() => import("views/dashboard/admins/update")),
    DashboardView: loadable(() => import("views/dashboard")),
    EventGuestPhotosListView: loadable(() => import("views/dashboard/events/guests/guest-photos")),
    EventGuestsListView: loadable(() => import("views/dashboard/events/guests")),
    EventPhotosListView: loadable(() => import("views/dashboard/events/photos")),
    EventSlideshowListView: loadable(() => import("views/dashboard/events/slideshow")),
    EventTemplatesCreateView: loadable(() => import("views/dashboard/events/templates/create")),
    EventTemplatesListView: loadable(() => import("views/dashboard/events/templates")),
    EventTemplatesUpdateView: loadable(() => import("views/dashboard/events/templates/update")),
    EventsCreateView: loadable(() => import("views/dashboard/events/create")),
    EventsListView: loadable(() => import("views/dashboard/events")),
    EventsUpdateView: loadable(() => import("views/dashboard/events/update")),
    LoginView: loadable(() => import("views/auth/login")),
    NotFoundView: loadable(() => import("views/not-found")),
    PhotographersCreateView: loadable(() => import("views/dashboard/photographers/create")),
    PhotographersListView: loadable(() => import("views/dashboard/photographers")),
    PhotographersUpdateView: loadable(() => import("views/dashboard/photographers/update")),
    SetPasswordView: loadable(() => import("views/auth/set-password")),
};

const {
    AdminsCreateView,
    AdminsListView,
    AdminsUpdateView,
    DashboardView,
    EventGuestPhotosListView,
    EventGuestsListView,
    EventPhotosListView,
    EventSlideshowListView,
    EventTemplatesCreateView,
    EventTemplatesListView,
    EventTemplatesUpdateView,
    EventsCreateView,
    EventsListView,
    EventsUpdateView,
    LoginView,
    NotFoundView,
    PhotographersCreateView,
    PhotographersListView,
    PhotographersUpdateView,
    SetPasswordView,
} = lazyLoadableComponents;

function Routes() {
    return (
        <ReactRoutes>
            <Route
                element={<NotFoundView />}
                path="*"
            />
            <Route
                element={<Outlet />}
                path="auth"
            >
                <Route
                    element={<LoginView />}
                    path="login"
                />
                <Route
                    element={<SetPasswordView />}
                    path="set-password"
                />
            </Route>
            <Route element={<ProtectedRoute />}>
                <Route
                    element={<DashboardView />}
                    path="/"
                >
                    <Route
                        element={<Outlet />}
                        path="admins"
                    >
                        <Route
                            element={<AdminsListView />}
                            index
                        />
                        <Route
                            element={<AdminsCreateView />}
                            path="create"
                        />
                        <Route
                            element={<AdminsUpdateView />}
                            path=":id/update"
                        />
                    </Route>
                    <Route
                        element={<Outlet />}
                        path="photographers"
                    >
                        <Route
                            element={<PhotographersListView />}
                            index
                        />
                        <Route
                            element={<PhotographersCreateView />}
                            path="create"
                        />
                        <Route
                            element={<PhotographersUpdateView />}
                            path=":id/update"
                        />
                    </Route>
                    <Route
                        element={<Outlet />}
                        path="events"
                    >
                        <Route
                            element={<EventsListView />}
                            index
                        />
                        <Route
                            element={<EventGuestsListView />}
                            path=":id/guests"
                        />
                        <Route
                            element={<EventPhotosListView />}
                            path=":id/photos"
                        />
                        <Route
                            element={<EventSlideshowListView />}
                            path=":id/slideshow"
                        />
                        <Route
                            element={<EventGuestPhotosListView />}
                            path=":eventId/guests/:guestId/photos"
                        />
                        <Route
                            element={<EventTemplatesListView />}
                            path="templates"
                        />
                        <Route
                            element={<EventTemplatesCreateView />}
                            path="templates/create"
                        />
                        <Route
                            element={<EventTemplatesUpdateView />}
                            path="templates/:id/update"
                        />
                        <Route
                            element={<EventsCreateView />}
                            path="create"
                        />
                        <Route
                            element={<EventsUpdateView />}
                            path=":id/update"
                        />
                    </Route>
                </Route>
            </Route>
        </ReactRoutes>
    );
}

export default Routes;
