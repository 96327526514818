import {
    actionsConstants,
    btnsNamesConstants,
    messagesConstants,
    modulesConstants,
    tooltipsConstants,
} from "@constants";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Box, Button, Tooltip } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { actionsData } from "data";
import {
    useActivateAdminData,
    useActivatePhotographerData,
    useDeactivateAdminData,
    useDeactivatePhotographerData,
    useDeleteEventData,
    useResetAdminPasswordData,
    useResetPhotographerPasswordData,
    useToggleArchiveEventData,
} from "hooks";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { showLoadingLoader } from "redux/actions";
import * as types from "redux/types";

function ListActionsMenu({
    activateStatus,
    archiveStatus,
    id,
    modalData,
    moduleName,
    routes,
    setModalData,
}) {
    const dispatch = useDispatch();

    const user = useSelector((state) => state.authReducer.user);

    const [anchorEl, setAnchorEl] = useState(null);

    const location = useLocation();

    const {
        isLoading: activateAdminIsLoading,
        mutate: activateAdminMutate,
    } = useActivateAdminData();

    const {
        isLoading: deactivateAdminIsLoading,
        mutate: deactivateAdminMutate,
    } = useDeactivateAdminData();

    const {
        isLoading: resetAdminPasswordIsLoading,
        mutate: resetAdminPasswordMutate,
    } = useResetAdminPasswordData();

    const {
        isLoading: activatePhotographerIsLoading,
        mutate: activatePhotographerMutate,
    } = useActivatePhotographerData();

    const {
        isLoading: deactivatePhotographerIsLoading,
        mutate: deactivatePhotographerMutate,
    } = useDeactivatePhotographerData();

    const {
        isLoading: resetPhotographerPasswordIsLoading,
        mutate: resetPhotographerPasswordMutate,
    } = useResetPhotographerPasswordData();

    const {
        isLoading: deleteEventIsLoading,
        mutate: deleteEventMutate,
    } = useDeleteEventData();

    const {
        activate: activateBtnName,
        archive: archiveBtnName,
        deactivate: deactivateBtnName,
        delete: deleteBtnName,
        send: sendBtnName,
        unarchive: unarchiveBtnName,
    } = btnsNamesConstants;

    const {
        activateMsgs: { alert: activateAlertMsg },
        archiveMsgs: { alert: archiveAlertMsg },
        deactivateMsgs: { alert: deactivateAlertMsg },
        deleteMsgs: { alert: deleteAlertMsg },
        resetPasswordMsgs: {
            alert: resetPasswordAlertMsg,
            pending: resetPasswordPendingMsg,
        },
        unarchiveMsgs: { alert: unarchiveAlertMsg },
    } = messagesConstants;

    const {
        activate: { key: activateKey },
        activateAndDeactivate: { key: activateAndDeactivateKey },
        archive: { key: archiveKey },
        archiveAndUnarchive: { key: archiveAndUnarchiveKey },
        createTemplate: { key: createTemplateKey },
        deactivate: { key: deactivateKey },
        delete: { key: deleteKey },
        eventGuestPhotos: { key: eventGuestPhotosKey },
        eventGuests: { key: eventGuestsKey },
        eventPhotos: { key: eventPhotosKey },
        eventSlideshow: { key: eventSlideshowKey },
        resetPassword: { key: resetPasswordKey },
        unarchive: { key: unarchiveKey },
        update: { key: updateKey },
    } = actionsConstants;

    const { admins: adminsModule } = modulesConstants;

    const { more: moreTooltip } = tooltipsConstants;

    const openMenu = Boolean(anchorEl);

    const {
        isLoading: toggleArchiveEventIsLoading,
        mutate: toggleArchiveEventMutate,
    } = useToggleArchiveEventData(archiveStatus ? unarchiveKey : archiveKey);

    const clickListActionHandler = (action) => {
        switch (action) {
        case activateKey:
            setModalData({
                btnColor: "primary",
                btnName: activateBtnName,
                id,
                show: true,
                submitModalActionHandler: () => {
                    setModalData({
                        ...modalData,
                        show: false,
                    });

                    if (moduleName === adminsModule) activateAdminMutate(id);
                    else activatePhotographerMutate(id);
                },
                title: activateAlertMsg,
            });
            break;
        case deactivateKey:
            setModalData({
                btnColor: "primary",
                btnName: deactivateBtnName,
                id,
                show: true,
                submitModalActionHandler: () => {
                    setModalData({
                        ...modalData,
                        show: false,
                    });

                    if (moduleName === adminsModule) deactivateAdminMutate(id);
                    else deactivatePhotographerMutate(id);
                },
                title: deactivateAlertMsg,
            });
            break;
        case resetPasswordKey:
            setModalData({
                btnColor: "primary",
                btnName: sendBtnName,
                id,
                show: true,
                submitModalActionHandler: () => {
                    setModalData({
                        ...modalData,
                        show: false,
                    });

                    if (moduleName === adminsModule) {
                        toast.loading(resetPasswordPendingMsg);

                        resetAdminPasswordMutate(id);
                    } else {
                        toast.loading(resetPasswordPendingMsg);

                        resetPhotographerPasswordMutate(id);
                    }
                },
                title: resetPasswordAlertMsg,
            });
            break;
        case deleteKey:
            setModalData({
                btnColor: "primary",
                btnName: deleteBtnName,
                id,
                show: true,
                submitModalActionHandler: () => {
                    setModalData({
                        ...modalData,
                        show: false,
                    });

                    deleteEventMutate(id);
                },
                title: deleteAlertMsg,
            });
            break;
        case archiveKey:
            setModalData({
                btnColor: "primary",
                btnName: archiveBtnName,
                id,
                show: true,
                submitModalActionHandler: () => {
                    setModalData({
                        ...modalData,
                        show: false,
                    });

                    toggleArchiveEventMutate(id);
                },
                title: archiveAlertMsg,
            });
            break;
        case unarchiveKey:
            setModalData({
                btnColor: "primary",
                btnName: unarchiveBtnName,
                id,
                show: true,
                submitModalActionHandler: () => {
                    setModalData({
                        ...modalData,
                        show: false,
                    });

                    toggleArchiveEventMutate(id);
                },
                title: unarchiveAlertMsg,
            });
            break;
        }
    };

    const renderActions = (
        Icon,
        action,
        activate,
        deactivate,
        archive,
        unarchive,
        key,
    ) => {
        switch (key) {
        case updateKey:
        case eventPhotosKey:
        case eventGuestsKey:
        case eventGuestPhotosKey:
        case eventSlideshowKey:
        case createTemplateKey:
            return (
                <MenuItem
                    component={Link}
                    key={key}
                    state={{ from: location.pathname + location.search }}
                    style={{ width: "100%" }}
                    to={routes[key]}
                    disableRipple
                >
                    <Icon style={{ marginRight: "5px" }} />
                    {action}
                </MenuItem>
            );
        case resetPasswordKey:
        case deleteKey:
            return (
                <MenuItem
                    component={Button}
                    key={key}
                    style={{
                        textTransform: "none",
                        width: "100%",
                    }}
                    disableRipple
                    onClick={() => clickListActionHandler(key)}
                >
                    <Icon style={{ marginRight: "5px" }} />
                    {action}
                </MenuItem>
            );
        case archiveAndUnarchiveKey:
            if (archiveStatus) {
                return (
                    <MenuItem
                        component={Button}
                        key={key}
                        style={{ width: "100%" }}
                        disableRipple
                        onClick={() => clickListActionHandler(unarchiveKey)}
                    >
                        <unarchive.Icon style={{ marginRight: "5px" }} />
                        {unarchive.action}
                    </MenuItem>
                );
            }

            return (
                <MenuItem
                    component={Button}
                    key={key}
                    style={{
                        textTransform: "none",
                        width: "100%",
                    }}
                    disableRipple
                    onClick={() => clickListActionHandler(archiveKey)}
                >
                    <archive.Icon style={{ marginRight: "5px" }} />
                    {archive.action}
                </MenuItem>
            );
        case activateAndDeactivateKey:
            if (user?.authorities.length > 1 && user?.id !== id) {
                if (activateStatus) {
                    return (
                        <MenuItem
                            component={Button}
                            key={key}
                            style={{ width: "100%" }}
                            disableRipple
                            onClick={() => clickListActionHandler(deactivateKey)}
                        >
                            <deactivate.Icon style={{ marginRight: "5px" }} />
                            {deactivate.action}
                        </MenuItem>
                    );
                }
                return (
                    <MenuItem
                        component={Button}
                        key={key}
                        style={{ width: "100%" }}
                        disableRipple
                        onClick={() => clickListActionHandler(activateKey)}
                    >
                        <activate.Icon style={{ marginRight: "5px" }} />
                        {activate.action}
                    </MenuItem>
                );
            }
        }
    };

    useEffect(
        () => {
            if (activateAdminIsLoading || deactivateAdminIsLoading) {
                dispatch(showLoadingLoader());

                dispatch({ type: types.ADMINS_REQUEST });
            }

            if (activatePhotographerIsLoading || deactivatePhotographerIsLoading) {
                dispatch(showLoadingLoader());

                dispatch({ type: types.PHOTOGRAPHERS_REQUEST });
            }

            if (deleteEventIsLoading || toggleArchiveEventIsLoading) {
                dispatch(showLoadingLoader());

                dispatch({ type: types.EVENTS_REQUEST });
            }

            if (resetAdminPasswordIsLoading) dispatch({ type: types.ADMINS_REQUEST });

            if (resetPhotographerPasswordIsLoading) dispatch({ type: types.PHOTOGRAPHERS_REQUEST });
        },
        [
            activateAdminIsLoading,
            deactivateAdminIsLoading,
            resetAdminPasswordIsLoading,
            activatePhotographerIsLoading,
            deactivatePhotographerIsLoading,
            resetPhotographerPasswordIsLoading,
            toggleArchiveEventIsLoading,
            deleteEventIsLoading,
            dispatch,
        ],
    );

    return (
        <Box>
            <Tooltip
                placement="right"
                title={moreTooltip}
            >
                <IconButton onClick={(event) => setAnchorEl(event.currentTarget)}><MoreVertIcon /></IconButton>
            </Tooltip>
            <Menu
                anchorEl={anchorEl}
                open={openMenu}
                onClose={() => setAnchorEl(null)}
            >
                {actionsData[moduleName].map(({
                    Icon,
                    action,
                    activate,
                    archive,
                    deactivate,
                    key,
                    unarchive,
                }) => renderActions(
                    Icon,
                    action,
                    activate,
                    deactivate,
                    archive,
                    unarchive,
                    key,
                ))}
            </Menu>
        </Box>
    );
}

export default ListActionsMenu;
