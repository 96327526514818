import Menu from "@mui/material/Menu";
import { alpha } from "@mui/material/styles";
import { styled } from "@mui/styles";

const StyledButtonMenu = styled(Menu)(({ theme }) => ({
    "& .MuiPaper-root": {
        "& .MuiMenu-list": {
            padding: "4px 0",
        },
        "& .MuiMenuItem-root": {
            "& .MuiSvgIcon-root": {
                color: theme.palette.text.secondary,
                fontSize: 18,
                marginRight: theme.spacing(1.5),
            },
            "&:active": {
                backgroundColor: alpha(
                    theme.palette.primary.main,
                    theme.palette.action.selectedOpacity,
                ),
            },
        },
        borderRadius: 6,
        boxShadow:
        `${theme.palette.grey[100]} 0px 0px 0px 0px, ${theme.palette.grey[901]} 0px 0px 0px 1px, ${theme.palette.grey[902]}0px 10px 15px -3px, ${theme.palette.grey[901]} 0px 4px 6px -2px`,
        color:
        theme.palette.mode === "light" ? theme.palette.grey[700] : theme.palette.grey[300],
        marginTop: theme.spacing(1),
        minWidth: 180,
    },
}));

export default StyledButtonMenu;
