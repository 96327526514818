import { globalConstants } from "@constants";
import { Typography } from "@mui/material";

function NoData() {
    const { noData: noDataConstant } = globalConstants;

    return (
        <Typography
            textAlign="center"
            variant="h4"
        >
            {noDataConstant}
        </Typography>
    );
}

export default NoData;
