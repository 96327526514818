const aspectRatiosData = [
    {
        height: 2,
        id: "4",
        key: "3:2",
        ratio: "3:2",
        width: 3,
    },
    {
        height: 9,
        id: "1",
        key: "16:9",
        ratio: "16:9",
        width: 16,
    },
    {
        height: 3,
        id: "2",
        key: "4:3",
        ratio: "4:3",
        width: 4,
    },
    {
        height: 1,
        id: "3",
        key: "1:1",
        ratio: "1:1",
        width: 1,
    },
];

export default aspectRatiosData;
