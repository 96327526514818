import { useQuery } from "react-query";
import {
    exportEventGuestsListData,
    getAdminData,
    getEventData,
    getEventSlideshowListData,
    getEventTemplateData,
    getEventTemplatesListData,
    getPhotographerData,
} from "requests";

export const useGetAdminData = (id) => useQuery(
    ["admin", id],
    () => getAdminData(id),
    {
        cacheTime: 0,
        enabled: false,
    },
);

export const useGetPhotographerData = (id) => useQuery(
    ["photographer", id],
    () => getPhotographerData(id),
    {
        cacheTime: 0,
        refetchOnWindowFocus: false,
    },
);

export const useGetEventData = (id) => useQuery(
    ["event", id],
    () => getEventData(id),
    {
        cacheTime: 0,
        enabled: false,
    },
);

export const useExportEventGuestsListData = (id, fileFilter) => useQuery(
    ["exportEventGuestsList", id, fileFilter],
    () => exportEventGuestsListData(
        id,
        fileFilter,
    ),
    {
        cacheTime: 0,
        enabled: false,
    },
);

export const useGetEventSlideshowListData = ({
    id,
    query,
}) => useQuery(
    ["eventSlideshowList", id, query],
    () => getEventSlideshowListData({
        id,
        query,
    }),
    {
        cacheTime: 0,
        refetchOnWindowFocus: false,
    },
);

export const useGetEventTemplatesListData = (id) => useQuery(
    ["eventTemplatesList", id],
    () => getEventTemplatesListData(id),
    {
        cacheTime: 0,
        refetchOnWindowFocus: false,
    },
);

export const useGetEventTemplateData = (id) => useQuery(
    ["eventTemplate", id],
    () => getEventTemplateData(id),
    {
        cacheTime: 0,
        refetchOnWindowFocus: false,
    },
);
