import { createTheme } from "@mui/material/styles";

export const baseTheme = {
    components: {
        MuiCssBaseline: {
            styleOverrides: `
            @font-face {
                font-display: swap;
                font-family: 'Roboto', 'Oxygen',
                'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue';
                font-style: normal;
                font-weight: 400;
            }
            `,
        },
    },
    direction: "ltr",
    palette: {
        action: {
            activatedOpacity: "12%",
            disabledBackgroundOpacity: "24%",
            disabledOpacity: "48%",
            focusOpacity: "12%",
            hoverOpacity: "8%",
            selectedOpacity: "8%",
        },
        error: {
            dark: "#B72136",
            light: "#FF484229",
            main: "#FF4842",
        },
        grey: {
            100: "#FFF",
            200: "#F4F6F8",
            300: "#DFE3E8",
            400: "#C4CDD5",
            500: "#919EAB",
            600: "#637381",
            700: "#454F5B",
            800: "#212B36",
            900: "#161C24",
            901: "rgba(0, 0, 0, 0.54)",
            902: "rgba(0, 0, 0, 0.1)",
        },
        info: { main: "#264371" },
        success: {
            dark: "#229A16",
            light: "#54D62C29",
            main: "#54D62C",
        },
        warning: { main: "#FFC107" },
    },
    shape: {
        borderRadius: 8,
        boxShadow: "0px 12px 24px -4px rgba(145, 158, 171, 0.12)",
        boxShadow2: "0px 12px 24px -4px rgba(0, 0, 0, 0.4)",
    },
    typography: {
        body1: {
            fontSize: "16px",
            fontWeight: 400,
            lineHeight: "24px",
        },
        body2: {
            fontSize: "14px",
            fontWeight: 400,
            lineHeight: "22px",
        },
        button: {
            large: {
                fontSize: "15px",
                fontWeight: 700,
                lineHeight: "24px",
            },
            medium: {
                fontSize: "14px",
                fontWeight: 700,
                lineHeight: "24px",
            },
            small: {
                fontSize: "13px",
                fontWeight: 700,
                lineHeight: "22px",
            },
        },
        caption: {
            fontSize: "14px",
            fontWeight: 400,
            lineHeight: "18px",
        },
        fontFamily: "'Roboto', sans-serif",
        fontWeightBold: 700,
        fontWeightLight: 400,
        fontWeightMedium: 600,
        fontWeightRegular: 500,
        h1: {
            "@media(max-width: 600px)": {
                fontSize: "40px",
                lineHeight: "50px",
            },
            fontSize: "64px",
            fontWeight: 700,
            lineHeight: "80px",
        },
        h2: {
            "@media(max-width: 600px)": {
                fontSize: "32px",
                lineHeight: "42px",
            },
            fontSize: "48px",
            fontWeight: 700,
            lineHeight: "64px",
        },
        h3: {
            "@media(max-width: 600px)": { fontSize: "24px" },
            fontSize: "32px",
            fontWeight: 700,
            lineHeight: "48px",
        },
        h4: {
            "@media(max-width: 600px)": {
                fontSize: "20px",
                lineHeight: "30px",
            },
            fontSize: "24px",
            fontWeight: 700,
            lineHeight: "36px",
        },
        h5: {
            "@media(max-width: 600px)": {
                fontSize: "18px",
                lineHeight: "27px",
            },
            fontSize: "20px",
            fontWeight: 700,
            lineHeight: "30px",
        },
        h6: {
            "@media(max-width: 600px)": {
                fontSize: "17px",
                lineHeight: "24px",
            },
            fontSize: "18px",
            fontWeight: 700,
            lineHeight: "28px",
        },
        overline: {
            fontSize: "12px",
            fontWeight: 700,
            lineHeight: "18px",
        },
        subtitle1: {
            fontSize: "16px",
            fontWeight: 600,
            lineHeight: "24px",
        },
        subtitle2: {
            fontSize: "14px",
            fontWeight: 600,
            lineHeight: "22px",
        },
    },
};

export const lightTheme = createTheme({
    components: { ...baseTheme.components },
    direction: "ltr",
    palette: {
        ...baseTheme.palette,
        action: { ...baseTheme.palette.action },
        background: { default: baseTheme.palette.grey[200] },
        mode: "light",
        primary: { main: "#C32025" },
        secondary: { main: "#BE2026" },
    },
    shape: { ...baseTheme.shape },
    typography: { ...baseTheme.typography },
});

export const darkTheme = createTheme({
    components: { ...baseTheme.components },
    direction: "ltr",
    palette: {
        ...baseTheme.palette,
        action: { ...baseTheme.palette.action },
        background: {
            default: baseTheme.palette.grey[800],
            paper: baseTheme.palette.grey[900],
        },
        mode: "dark",
        primary: { main: "#ce433a" },
        secondary: { main: "#e7a5a7" },
    },
    shape: { ...baseTheme.shape },
    typography: { ...baseTheme.typography },
});
