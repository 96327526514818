import * as types from "redux/types";

const initialState = { show: false };

const sendingReducer = (state = initialState, action) => {
    const { type } = action;

    switch (type) {
    case types.SHOW_SENDING_LOADER: return { show: true };

    case types.HIDE_SENDING_LOADER: return { show: false };

    default: return state;
    }
};

export default sendingReducer;
