const pagesTitlesConstants = {
    admins: "Admins - Photoflare",
    createAdmin: "Create Admin - Photoflare",
    createEvent: "Create Event - Photoflare",
    createEventTemplate: "Create Event Template - Photoflare",
    createPhotographer: "Create Photographer - Photoflare",
    eventGuestPhotos: "Event Guest Photos - Photoflare",
    eventGuests: "Event Guests - Photoflare",
    eventPhotos: "Event Photos - Photoflare",
    eventSlideshow: "Event Slideshow - Photoflare",
    eventTemplates: "Event Templates - Photoflare",
    events: "Events - Photoflare",
    home: "Photoflare - Dashboard",
    login: "Login - Photoflare",
    photographers: "Photographers - Photoflare",
    setPassword: "Set Password - Photoflare",
    updateAdmin: "Update Admin - Photoflare",
    updateEvent: "Update Event - Photoflare",
    updateEventTemplate: "Update Event Template - Photoflare",
    updatePhotographer: "Update Photographer - Photoflare",
};

export default pagesTitlesConstants;
