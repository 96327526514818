import "./index.css";

import { StyledEngineProvider } from "@mui/styled-engine-sc";
import React from "react";
import ReactDOM from "react-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { persistor, store } from "redux/store";
import { PersistGate } from "redux-persist/integration/react";

import App from "./App";

const queryClient = new QueryClient();

ReactDOM.render(
    <QueryClientProvider client={queryClient}>
        <StyledEngineProvider injectFirst>
            <Provider store={store}>
                <PersistGate
                    loading={null}
                    persistor={persistor}
                >
                    <React.StrictMode>
                        <BrowserRouter><App /></BrowserRouter>
                    </React.StrictMode>
                </PersistGate>
            </Provider>
        </StyledEngineProvider>
        <ReactQueryDevtools
            initialIsOpen={false}
            position="bottom-right"
        />
    </QueryClientProvider>,
    document.getElementById("root"), // eslint-disable-line
);
