import { messagesConstants } from "@constants";
import * as yup from "yup";

const {
    email: emailMsg,
    invalidEmail: invalidEmailMsg,
    password: passwordMsg,
} = messagesConstants.inputsMsgs;

const loginFormSchema = yup.object({
    email: yup.string().email(invalidEmailMsg).required(emailMsg),
    password: yup.string().required(passwordMsg),
});

export default loginFormSchema;
