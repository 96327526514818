import { CircularProgress, Grid } from "@mui/material";

function Loader() {
    return (
        <Grid
            alignContent="center"
            flexDirection="column"
            height="100%"
            justifyContent="center"
            container
        >
            <CircularProgress />
        </Grid>
    );
}

export default Loader;
