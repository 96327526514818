import * as types from "redux/types";

const initialState = {
    message: null,
    open: false,
    status: "",
};

const alertReducer = (state = initialState, action) => {
    const {
        payload,
        type,
    } = action;

    const {
        message,
        status,
    } = payload || {};

    switch (type) {
    case types.OPEN_ALERT:
        return {
            ...state,
            message,
            open: true,
            status,
        };

    case types.CLOSE_ALERT:
        return {
            ...state,
            message: null,
            open: false,
            status: "",
        };

    default: return state;
    }
};

export default alertReducer;
