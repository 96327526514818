const actionsConstants = {
    activate: {
        action: "Activate",
        key: "activate",
    },
    activateAndDeactivate: { key: "activate/deactivate" },
    addAndRemoveSlideshow: { key: "addSlideshow/removeSlideshow" },
    addToSlideshow: {
        action: "Add to Slideshow",
        key: "addToSlideshow",
    },
    archive: {
        action: "Archive",
        key: "archive",
    },
    archiveAndUnarchive: { key: "archive/unarchive" },
    asc: {
        action: "Oldest",
        key: "asc",
    },
    create: {
        action: "Create",
        key: "create",
    },
    createTemplate: {
        action: "Create Template",
        key: "createTemplate",
    },
    createZipFolder: {
        action: "Create Zip Folder",
        key: "createZipFolder",
    },
    deactivate: {
        action: "Deactivate",
        key: "deactivate",
    },
    delete: {
        action: "Delete",
        key: "delete",
    },
    desc: {
        action: "Newest",
        key: "desc",
    },
    downloadOriginal: {
        action: "Download Original",
        key: "downloadOriginal",
    },
    downloadWithOverlay: {
        action: "Download with Overlay",
        key: "downloadWithOverlay",
    },
    eventGuestPhotos: {
        action: "Photos",
        key: "eventGuestPhotos",
    },
    eventGuests: {
        action: "Guests",
        key: "eventGuests",
    },
    eventPhotos: {
        action: "Photos",
        key: "eventPhotos",
    },
    eventSlideshow: {
        action: "Slideshow",
        key: "eventSlideshow",
    },
    removeFromSlideshow: {
        action: "Remove from Slideshow",
        key: "removeFromSlideshow",
    },
    resend: {
        action: "Resend",
        key: "resend",
    },
    resetPassword: {
        action: "Reset Password",
        key: "resetPassword",
    },
    shareGallery: {
        action: "Share Zip Folder Link",
        key: "shareGallery",
    },
    unarchive: {
        action: "Unarchive",
        key: "unarchive",
    },
    update: {
        action: "Update",
        key: "update",
    },
    viewDropboxOverlay: {
        action: "View Dropbox Overlay Image",
        key: "viewDropboxOverlay",
    },
};

export default actionsConstants;
