import { StyledStatusLabel } from "styles";

function StatusLabel({
    label,
    type,
    variant,
}) {
    return (
        <StyledStatusLabel
            type={type}
            variant={variant}
        >
            {label}
        </StyledStatusLabel>
    );
}

export default StatusLabel;
