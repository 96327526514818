import { axios, getCookie } from "utils";

export const getData = async (
    apiUrl,
    id,
    query,
    isFileRequest,
    fileFilter,
) => {
    let fullApiUrl = "";

    if (query && id) fullApiUrl = `${apiUrl}/${id}${query}`;
    else if (id) fullApiUrl = `${apiUrl}/${id}`;
    else if (query) fullApiUrl = `${apiUrl}${query}`;
    else fullApiUrl = apiUrl;

    if (isFileRequest) fullApiUrl += `/export/${fileFilter}/v2`;

    return axios.get(
        fullApiUrl,
        {
            headers: {
                Authorization: `Bearer ${getCookie("token")}`,
                "Content-Type": "application/json",
            },
        },
    );
};

export const postData = async (
    apiUrl,
    id,
    data,
    isFormData,
    isPublicService,
    isLoginAuthRequest,
    subApi,
    query,
) => {
    let fullApiUrl = id ? `${apiUrl}/${id}` : apiUrl;

    if (subApi) fullApiUrl += `/${subApi}`;

    if (query) fullApiUrl += `${query}`;

    return axios.post(
        fullApiUrl,
        data,
        {
            headers: {
                ...!isPublicService && { Authorization: `Bearer ${getCookie("token")}` },
                "Content-Type": isFormData ? "multipart/form-data" : "application/json",
            },
            ...isLoginAuthRequest && {
                auth: {
                    password: window?.__RUNTIME_CONFIG__?.REACT_APP_CLIENT_SECRET, // eslint-disable-line
                    username: window?.__RUNTIME_CONFIG__?.REACT_APP_CLIENT_ID, // eslint-disable-line
                },
            },
        },
    );
};

export const updateData = async (
    apiUrl,
    id,
    data,
    isFormData,
    query,
    subApi,
    isSetPasswordRequest,
    token,
) => {
    let fullApiUrl = "";

    if (id) fullApiUrl = `${apiUrl}/${id}`;
    else if (query) fullApiUrl = `${apiUrl}${query}`;
    else fullApiUrl = apiUrl;

    if (subApi) fullApiUrl += `/${subApi}`;

    return axios.put(
        fullApiUrl,
        data,
        {
            headers: {
                Authorization: `Bearer ${isSetPasswordRequest ? token : getCookie("token")}`,
                "Content-Type": isFormData ? "multipart/form-data" : "application/json",
            },
        },
    );
};

export const deleteData = async (apiUrl, id) => axios["delete"](
    `${apiUrl}/${id}`,
    {
        headers: { Authorization: `Bearer ${getCookie("token")}` },
    },
);
