import { Box } from "@mui/material";
import { styled } from "@mui/styles";

const StyledModal = styled(Box)(({ theme }) => ({
    "& .MuiTypography-root": {
        fontWeight: "bold",
        marginBottom: "24px",
    },
    "@media (max-width: 768px)": {
        "& .MuiButtonBase-root": {
            fontSize: ".8rem",
        },
    },
    background: theme.palette.mode === "dark" ? theme.palette.grey[900] : theme.palette.grey[200],
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shape.boxShadow,
    color: theme.palette.primary.main,
    left: "50%",
    maxWidth: "600px",
    minWidth: "300px",
    padding: "24px",
    position: "absolute",
    top: "50%",
    transform: "translate(-50%, -50%)",
}));

export default StyledModal;
