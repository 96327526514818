import { messagesConstants } from "@constants";
import * as yup from "yup";

const {
    event: eventMsg,
    template: templateMsg,
    title: titleMsg,
} = messagesConstants.inputsMsgs;

const createEventFormSchema = {
    copyEvent: yup.object({
        event: yup.object().test(
            "is-not-empty",
            eventMsg,
            (value) => Object.keys(value).length > 0,
        ).required(eventMsg),
        title: yup.string().required(titleMsg),
    }),
    titleOnly: yup.object({ title: yup.string().required(titleMsg) }),
    useTemplate: yup.object({
        template: yup.object().test(
            "is-not-empty",
            templateMsg,
            (value) => Object.keys(value).length > 0,
        ).required(templateMsg),
        title: yup.string().required(titleMsg),
    }),
};

export default createEventFormSchema;
