import { messagesConstants } from "@constants";
import * as yup from "yup";

import regexData from "../../data/regex";

const {
    email: emailMsg,
    firstName: firstNameMsg,
    invalidEmail: invalidEmailMsg,
    invalidName: invalidNameMsg,
    invalidPhoneNumber: invalidPhoneNumberMsg,
    lastName: lastNameMsg,
    phoneNumber: phoneNumberMsg,
} = messagesConstants.inputsMsgs;

const {
    allDigitsSameNumber: allDigitsSameNumberRegex,
    incrementingNumbers: incrementalNumbersRegex,
    name: nameRegex,
    phoneNumber: phoneNumberRegex,
    repeatingPatternTypeOne: repeatingPatternTypeOneRegex,
    repeatingPatternTypeTwo: repeatingPatternTypeTwoRegex,
} = regexData;

const adminAndPhotographerFormSchema = yup.object({
    email: yup.string().email(invalidEmailMsg).required(emailMsg),
    firstName: yup.string().required(firstNameMsg).matches(
        nameRegex,
        invalidNameMsg,
    ),
    lastName: yup.string().required(lastNameMsg).matches(
        nameRegex,
        invalidNameMsg,
    ),
    phoneNumber: yup.string().required(phoneNumberMsg).matches(
        phoneNumberRegex,
        invalidPhoneNumberMsg,
    ).matches(
        allDigitsSameNumberRegex,
        invalidPhoneNumberMsg,
    ).
        matches(
            incrementalNumbersRegex,
            invalidPhoneNumberMsg,
        ).
        matches(
            repeatingPatternTypeOneRegex,
            invalidPhoneNumberMsg,
        ).
        matches(
            repeatingPatternTypeTwoRegex,
            invalidPhoneNumberMsg,
        ).
        test(
            "Phone Number Length",
            invalidPhoneNumberMsg,
            (value) => value.length <= 18,
        ),
});

export default adminAndPhotographerFormSchema;
