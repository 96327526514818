import { globalConstants } from "@constants";
import { Typography } from "@mui/material";
import { StyledFooter } from "styles";

function Footer() {
    const { copyrights: copyrightsConstant } = globalConstants;

    return (
        <StyledFooter>
            <Typography
                textAlign="center"
                variant="h6"
            >
                {copyrightsConstant}
                {" "}
                &copy;
                {" "}
                {new Date().getFullYear()}
            </Typography>
        </StyledFooter>
    );
}

export default Footer;
