import { inputsLabelsConstants } from "@constants";

const {
    endDate: endDateInputLabel,
    search: searchInputLabel,
    startDate: startDateInputLabel,
} = inputsLabelsConstants;

const photosFiltersArea = [
    {
        col: {
            lg: 3,
            xs: 12,
        },
        label: searchInputLabel,
        name: "search",
        type: "text-field",
    },
    {
        col: {
            lg: 3,
            xs: 12,
        },
        label: startDateInputLabel,
        name: "startDate",
        type: "date",
    },
    {
        col: {
            lg: 3,
            xs: 12,
        },
        label: endDateInputLabel,
        name: "endDate",
        type: "date",
    },
];

export default photosFiltersArea;
