import MuiPagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import * as React from "react";

function Pagination({
    count,
    handleChange,
    page,
}) {
    return (
        <Stack
            margin="auto"
            marginTop={2}
            spacing={2}
            width="fit-content"
        >
            <MuiPagination
                count={count}
                page={page}
                onChange={handleChange}
            />
        </Stack>
    );
}

export default Pagination;
