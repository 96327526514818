import { modulesConstants } from "@constants";

const {
    admins: adminsModule,
    eventTemplates: eventTemplatesModule,
    events: eventsModule,
    photographers: photographersModule,
} = modulesConstants;

const sidebarTabsData = {
    "/admins": {
        id: 1,
        route: adminsModule,
    },
    "/events": {
        id: 2,
        route: eventsModule,
    },
    "/events/templates": {
        id: 3,
        route: eventTemplatesModule,
    },
    "/photographers": {
        id: 4,
        route: photographersModule,
    },
};

export default sidebarTabsData;
