import { sidebarTitlesConstants, tooltipsConstants } from "@constants";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import EventIcon from "@mui/icons-material/Event";
import InventoryIcon from "@mui/icons-material/Inventory";
import { routesUrlsData } from "data";

const {
    admins: { url: adminsRouteUrl },
    events: {
        templates: { url: eventTemplatesRouteUrl },
        url: eventsRouteUrl,
    },
    photographers: { url: photographersRouteUrl },
} = routesUrlsData;

const {
    admins: adminsSidebarTitle,
    eventTemplates: eventTemplatesSidebarTitle,
    events: eventsSidebarTitle,
    photographers: photographersSidebarTitle,
} = sidebarTitlesConstants;

const {
    admins: adminsTooltip,
    eventTemplates: eventTemplatesTooltip,
    events: eventsTooltip,
    photographers: photographersTooltip,
} = tooltipsConstants;

const SidebarData = [
    {
        Icon: AdminPanelSettingsIcon,
        id: 1,
        routeUrl: adminsRouteUrl,
        title: adminsSidebarTitle,
        tooltip: adminsTooltip,
    },
    {
        Icon: EventIcon,
        id: 2,
        routeUrl: eventsRouteUrl,
        title: eventsSidebarTitle,
        tooltip: eventsTooltip,
    },
    {
        Icon: InventoryIcon,
        id: 3,
        routeUrl: eventsRouteUrl + eventTemplatesRouteUrl,
        title: eventTemplatesSidebarTitle,
        tooltip: eventTemplatesTooltip,
    },
    {
        Icon: CameraAltIcon,
        id: 4,
        routeUrl: photographersRouteUrl,
        title: photographersSidebarTitle,
        tooltip: photographersTooltip,
    },
];

export default SidebarData;
