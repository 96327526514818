/* eslint max-lines: 0 */
import { actionsConstants, globalConstants, messagesConstants } from "@constants";
import { saveAs } from "file-saver";
import {
    hideLoadingLoader,
    hideSendingLoader,
    logout,
    openAlert,
} from "redux/actions";
import * as types from "redux/types";
import { getFileNameFromUrl } from "utils";

const {
    addEventDomainMsgs: {
        fail: addEventDomainFailMsg,
        success: addEventDomainSuccessMsg,
    },
    addEventPageActionMsgs: {
        fail: addEventPageActionFailMsg,
        success: addEventPageActionSuccessMsg,
    },
    addToSlideshowMsgs: {
        fail: addToSlideshowFailMsg,
        success: addToSlideshowSuccessMsg,
    },
    archiveMsgs: {
        fail: archiveFailMsg,
        success: archiveSuccessMsg,
    },
    assignMsgs: {
        fail: assignFailMsg,
        success: assignSuccessMsg,
    },
    authMsgs: { unAuthorized: unAuthorizedMsg },
    createMsgs: {
        fail: createFailMsg,
        success: createSuccessMsg,
    },
    deleteMsgs: {
        fail: deleteFailMsg,
        success: deleteSuccessMsg,
    },
    downloadMsgs: { fail: downloadFailMsg },
    exportMsgs: {
        fail: exportFailMsg,
        success: exportSuccessMsg,
    },
    fail: failMsg,
    generateEventZipFolderMsgs: {
        fail: generateEventZipFolderFailMsg,
        success: generateEventZipFolderSuccessMsg,
    },
    removeFromSlideshowMsgs: {
        fail: removeFromSlideshowFailMsg,
        success: removeFromSlideshowSuccessMsg,
    },
    resendEventPhotoMsgs: {
        fail: resendEventPhotoFailMsg,
        success: resendEventPhotoSuccessMsg,
    },
    saveMsgs: {
        fail: saveFailMsg,
        success: saveSuccessMsg,
    },
    shareEventGalleryMsgs: {
        fail: shareEventGalleryFailMsg,
        success: shareEventGallerySuccessMsg,
    },
    success: successMsg,
    unarchiveMsgs: {
        fail: unarchiveFailMsg,
        success: unarchiveSuccessMsg,
    },
    uploadEventBannerImageMsgs: {
        fail: uploadEventBannerImageFailMsg,
        success: uploadEventBannerImageSuccessMsg,
    },
    uploadEventOverlayMsgs: {
        fail: uploadEventOverlayFailMsg,
        success: uploadEventOverlaySuccessMsg,
    },
} = messagesConstants;

const {
    addToSlideshow: { key: addToSlideshowKey },
    archive: { key: archiveKey },
} = actionsConstants;

const { eventGuestsListFileName: eventGuestsListFileNameConstant } = globalConstants;

/* Start Events & Event Details */
export const getEvents = (data, state, error) => async (dispatch) => {
    if (state === successMsg) {
        dispatch({
            payload: { data },
            type: types.GET_EVENTS_SUCCESS,
        });

        dispatch(hideLoadingLoader());
    } else {
        dispatch({
            payload: { error: error.request },
            type: types.EVENTS_FAIL,
        });

        if (error.request.status === 0 || error.request.status === 401) {
            dispatch(logout());

            dispatch(openAlert(
                unAuthorizedMsg,
                failMsg,
            ));
        }

        dispatch(hideLoadingLoader());
    }
};

export const getEvent = (data, state, error) => async (dispatch) => {
    if (state === successMsg) {
        dispatch({
            payload: { data },
            type: types.GET_EVENT_SUCCESS,
        });

        dispatch(hideLoadingLoader());
    } else {
        dispatch({
            payload: { error: error.request },
            type: types.EVENTS_FAIL,
        });

        if (error.request.status === 0 || error.request.status === 401) {
            dispatch(logout());

            dispatch(openAlert(
                unAuthorizedMsg,
                failMsg,
            ));
        }

        dispatch(hideLoadingLoader());
    }
};

export const createEvent = (data, state, error) => async (dispatch) => {
    if (state === successMsg) {
        dispatch({
            payload: { data },
            type: types.CREATE_EVENT_SUCCESS,
        });

        dispatch(openAlert(
            createSuccessMsg,
            successMsg,
        ));

        dispatch(hideSendingLoader());
    } else {
        dispatch({
            payload: { error: error.request },
            type: types.EVENTS_FAIL,
        });

        if (error.request.status === 0 || error.request.status === 401) {
            dispatch(logout());

            dispatch(openAlert(
                unAuthorizedMsg,
                failMsg,
            ));
        } else {
            dispatch(openAlert(
                createFailMsg,
                failMsg,
            ));
        }

        dispatch(hideSendingLoader());
    }
};

export const updateEvent = (data, state, error) => async (dispatch) => {
    if (state === successMsg) {
        dispatch({
            payload: { data },
            type: types.UPDATE_EVENT_SUCCESS,
        });

        dispatch(openAlert(
            saveSuccessMsg,
            successMsg,
        ));

        dispatch(hideSendingLoader());
    } else {
        dispatch({
            payload: { error: error.request },
            type: types.EVENTS_FAIL,
        });

        if (error.request.status === 0 || error.request.status === 401) {
            dispatch(logout());

            dispatch(openAlert(
                unAuthorizedMsg,
                failMsg,
            ));
        } else {
            dispatch(openAlert(
                saveFailMsg,
                failMsg,
            ));
        }

        dispatch(hideSendingLoader());
    }
};

export const deleteEvent = (state, error) => async (dispatch) => {
    if (state === successMsg) {
        dispatch({ type: types.DELETE_EVENT_SUCCESS });

        dispatch(openAlert(
            deleteSuccessMsg,
            successMsg,
        ));
    } else {
        dispatch({
            payload: { error: error.request },
            type: types.EVENTS_FAIL,
        });

        if (error.request.status === 0 || error.request.status === 401) {
            dispatch(logout());

            dispatch(openAlert(
                unAuthorizedMsg,
                failMsg,
            ));
        } else {
            dispatch(openAlert(
                deleteFailMsg,
                failMsg,
            ));
        }

        dispatch(hideLoadingLoader());
    }
};

export const toggleArchiveEvent = (state, error, action) => async (dispatch) => {
    if (state === successMsg) {
        dispatch({ type: action === archiveKey ? types.ARCHIVE_EVENT_SUCCESS : types.UNARCHIVE_EVENT_SUCCESS });

        dispatch(openAlert(
            action === archiveKey ? archiveSuccessMsg : unarchiveSuccessMsg,
            successMsg,
        ));
    } else {
        dispatch({
            payload: { error: error.request },
            type: types.EVENTS_FAIL,
        });

        if (error.request.status === 0 || error.request.status === 401) {
            dispatch(logout());

            dispatch(openAlert(
                unAuthorizedMsg,
                failMsg,
            ));
        } else {
            dispatch(openAlert(
                action === archiveKey ? archiveFailMsg : unarchiveFailMsg,
                failMsg,
            ));
        }

        dispatch(hideLoadingLoader());
    }
};

export const assignEvent = (data, state, error) => async (dispatch) => {
    if (state === successMsg) {
        dispatch({
            payload: { data },
            type: types.ASSIGN_EVENT_SUCCESS,
        });

        dispatch(openAlert(
            assignSuccessMsg,
            successMsg,
        ));
    } else {
        dispatch({
            payload: { error: error.request },
            type: types.EVENTS_FAIL,
        });

        if (error.request.status === 0 || error.request.status === 401) {
            dispatch(logout());

            dispatch(openAlert(
                unAuthorizedMsg,
                failMsg,
            ));
        } else {
            dispatch(openAlert(
                assignFailMsg,
                failMsg,
            ));
        }

        dispatch(hideLoadingLoader());
    }
};

export const uploadEventOverlay = (data, state, error) => async (dispatch) => {
    if (state === successMsg) {
        dispatch({
            payload: { data },
            type: types.UPLOAD_EVENT_OVERLAY_SUCCESS,
        });

        dispatch(openAlert(
            uploadEventOverlaySuccessMsg,
            successMsg,
        ));
    } else {
        dispatch({
            payload: { error: error.request },
            type: types.EVENTS_FAIL,
        });

        if (error.request.status === 0 || error.request.status === 401) {
            dispatch(logout());

            dispatch(openAlert(
                unAuthorizedMsg,
                failMsg,
            ));
        } else {
            dispatch(openAlert(
                uploadEventOverlayFailMsg,
                failMsg,
            ));
        }
    }
};

export const setDefaultEventOverlay = (state, error) => async (dispatch) => {
    if (state === successMsg) {
        dispatch({
            type: types.SET_DEFAULT_EVENT_OVERLAY_SUCCESS,
        });
    } else {
        dispatch({
            payload: { error: error.request },
            type: types.EVENTS_FAIL,
        });

        if (error.request.status === 0 || error.request.status === 401) {
            dispatch(logout());

            dispatch(openAlert(
                unAuthorizedMsg,
                failMsg,
            ));
        }
    }
};

export const deleteEventOverlay = (state, error) => async (dispatch) => {
    if (state === successMsg) {
        dispatch({
            type: types.DELETE_EVENT_OVERLAY_SUCCESS,
        });

        dispatch(openAlert(
            deleteSuccessMsg,
            successMsg,
        ));
    } else {
        dispatch({
            payload: { error: error.request },
            type: types.EVENTS_FAIL,
        });

        if (error.request.status === 0 || error.request.status === 401) {
            dispatch(logout());

            dispatch(openAlert(
                unAuthorizedMsg,
                failMsg,
            ));
        } else {
            dispatch(openAlert(
                deleteFailMsg,
                failMsg,
            ));
        }
    }
};

export const addEventDomain = (data, state, error) => async (dispatch) => {
    if (state === successMsg) {
        dispatch({
            payload: { data },
            type: types.ADD_EVENT_DOMAIN_SUCCESS,
        });

        dispatch(openAlert(
            addEventDomainSuccessMsg,
            successMsg,
        ));
    } else {
        dispatch({
            payload: { error: error.request },
            type: types.EVENTS_FAIL,
        });

        if (error.request.status === 0 || error.request.status === 401) {
            dispatch(logout());

            dispatch(openAlert(
                unAuthorizedMsg,
                failMsg,
            ));
        } else {
            dispatch(openAlert(
                addEventDomainFailMsg,
                failMsg,
            ));
        }
    }
};

export const deleteEventDomain = (state, error) => async (dispatch) => {
    if (state === successMsg) {
        dispatch({
            type: types.DELETE_EVENT_DOMAIN_SUCCESS,
        });

        dispatch(openAlert(
            deleteSuccessMsg,
            successMsg,
        ));
    } else {
        dispatch({
            payload: { error: error.request },
            type: types.EVENTS_FAIL,
        });

        if (error.request.status === 0 || error.request.status === 401) {
            dispatch(logout());

            dispatch(openAlert(
                unAuthorizedMsg,
                failMsg,
            ));
        } else {
            dispatch(openAlert(
                deleteFailMsg,
                failMsg,
            ));
        }
    }
};

export const addEventPageAction = (data, state, error) => async (dispatch) => {
    if (state === successMsg) {
        dispatch({
            payload: { data },
            type: types.ADD_EVENT_PAGE_ACTION_SUCCESS,
        });

        dispatch(openAlert(
            addEventPageActionSuccessMsg,
            successMsg,
        ));
    } else {
        dispatch({
            payload: { error: error.request },
            type: types.EVENTS_FAIL,
        });

        if (error.request.status === 0 || error.request.status === 401) {
            dispatch(logout());

            dispatch(openAlert(
                unAuthorizedMsg,
                failMsg,
            ));
        } else {
            dispatch(openAlert(
                addEventPageActionFailMsg,
                failMsg,
            ));
        }
    }
};

export const deleteEventPageAction = (state, error) => async (dispatch) => {
    if (state === successMsg) {
        dispatch({ type: types.DELETE_EVENT_PAGE_ACTION_SUCCESS });

        dispatch(openAlert(
            deleteSuccessMsg,
            successMsg,
        ));
    } else {
        dispatch({
            payload: { error: error.request },
            type: types.EVENTS_FAIL,
        });

        if (error.request.status === 0 || error.request.status === 401) {
            dispatch(logout());

            dispatch(openAlert(
                unAuthorizedMsg,
                failMsg,
            ));
        } else {
            dispatch(openAlert(
                deleteFailMsg,
                failMsg,
            ));
        }
    }
};

export const uploadEventBannerImage = (data, state, error) => async (dispatch) => {
    if (state === successMsg) {
        dispatch({
            payload: { data },
            type: types.UPLOAD_EVENT_BANNER_IMAGE_SUCCESS,
        });

        dispatch(openAlert(
            uploadEventBannerImageSuccessMsg,
            successMsg,
        ));
    } else {
        dispatch({
            payload: { error: error.request },
            type: types.EVENTS_FAIL,
        });

        if (error.request.status === 0 || error.request.status === 401) {
            dispatch(logout());

            dispatch(openAlert(
                unAuthorizedMsg,
                failMsg,
            ));
        } else {
            dispatch(openAlert(
                uploadEventBannerImageFailMsg,
                failMsg,
            ));
        }
    }
};

export const deleteEventBannerImage = (state, error) => async (dispatch) => {
    if (state === successMsg) {
        dispatch({ type: types.DELETE_EVENT_BANNER_IMAGE_SUCCESS });

        dispatch(openAlert(
            deleteSuccessMsg,
            successMsg,
        ));
    } else {
        dispatch({
            payload: { error: error.request },
            type: types.EVENTS_FAIL,
        });

        if (error.request.status === 0 || error.request.status === 401) {
            dispatch(logout());

            dispatch(openAlert(
                unAuthorizedMsg,
                failMsg,
            ));
        } else {
            dispatch(openAlert(
                deleteFailMsg,
                failMsg,
            ));
        }
    }
};
/* End Events & Event Details */

/* Start Event Guests */
export const getEventGuests = (data, state, error) => async (dispatch) => {
    if (state === successMsg) {
        dispatch({
            payload: { data },
            type: types.GET_EVENT_GUESTS_SUCCESS,
        });

        dispatch(hideLoadingLoader());
    } else {
        dispatch({
            payload: { error: error.request },
            type: types.EVENTS_FAIL,
        });

        if (error.request.status === 0 || error.request.status === 401) {
            dispatch(logout());

            dispatch(openAlert(
                unAuthorizedMsg,
                failMsg,
            ));
        }

        dispatch(hideLoadingLoader());
    }
};

export const exportEventGuests = (data, state, error) => async (dispatch) => {
    if (state === successMsg) {
        dispatch({
            payload: { data },
            type: types.EXPORT_EVENT_GUESTS_SUCCESS,
        });

        dispatch(openAlert(
            exportSuccessMsg,
            successMsg,
        ));

        saveAs(
            data,
            eventGuestsListFileNameConstant,
        );
    } else {
        dispatch({
            payload: { error: error.request },
            type: types.EVENTS_FAIL,
        });

        if (error.request.status === 0 || error.request.status === 401) {
            dispatch(logout());

            dispatch(openAlert(
                unAuthorizedMsg,
                failMsg,
            ));
        } else {
            dispatch(openAlert(
                exportFailMsg,
                failMsg,
            ));
        }
    }
};
/* End Event Guests */

/* Start Event Photos */
export const getEventPhotos = (data, state, error) => async (dispatch) => {
    if (state === successMsg) {
        dispatch({
            payload: { data },
            type: types.GET_EVENT_PHOTOS_SUCCESS,
        });

        dispatch(hideLoadingLoader());
    } else {
        dispatch({
            payload: { error: error.request },
            type: types.EVENTS_FAIL,
        });

        if (error.request.status === 0 || error.request.status === 401) {
            dispatch(logout());

            dispatch(openAlert(
                unAuthorizedMsg,
                failMsg,
            ));
        }

        dispatch(hideLoadingLoader());
    }
};

export const resendEventPhoto = (data, state, error) => async (dispatch) => {
    if (state === successMsg) {
        dispatch({
            payload: { data },
            type: types.RESEND_EVENT_PHOTO_SUCCESS,
        });

        dispatch(openAlert(
            resendEventPhotoSuccessMsg,
            successMsg,
        ));
    } else {
        dispatch({
            payload: { error: error.request },
            type: types.EVENTS_FAIL,
        });

        if (error.request.status === 0 || error.request.status === 401) {
            dispatch(logout());

            dispatch(openAlert(
                unAuthorizedMsg,
                failMsg,
            ));
        } else {
            dispatch(openAlert(
                resendEventPhotoFailMsg,
                failMsg,
            ));
        }
    }
};

export const deleteEventPhoto = (state, error) => async (dispatch) => {
    if (state === successMsg) {
        dispatch({ type: types.DELETE_EVENT_PHOTO_SUCCESS });

        dispatch(openAlert(
            deleteSuccessMsg,
            successMsg,
        ));
    } else {
        dispatch({
            payload: { error: error.request },
            type: types.EVENTS_FAIL,
        });

        if (error.request.status === 0 || error.request.status === 401) {
            dispatch(logout());

            dispatch(openAlert(
                unAuthorizedMsg,
                failMsg,
            ));
        } else {
            dispatch(openAlert(
                deleteFailMsg,
                failMsg,
            ));
        }

        dispatch(hideLoadingLoader());
    }
};

export const downloadEventPhoto = (data) => async (dispatch) => {
    try {
        saveAs(
            data,
            getFileNameFromUrl(data),
        );
    } catch (e) {
        dispatch(openAlert(
            downloadFailMsg,
            failMsg,
        ));
    }
};

export const shareEventGallery = (state, error) => async (dispatch) => {
    if (state === successMsg) {
        dispatch({ type: types.SHARE_EVENT_GALLERY_SUCCESS });

        dispatch(openAlert(
            shareEventGallerySuccessMsg,
            successMsg,
        ));
    } else {
        dispatch({
            payload: { error: error.request },
            type: types.EVENTS_FAIL,
        });

        if (error.request.status === 0 || error.request.status === 401) {
            dispatch(logout());

            dispatch(openAlert(
                unAuthorizedMsg,
                failMsg,
            ));
        } else {
            dispatch(openAlert(
                shareEventGalleryFailMsg,
                failMsg,
            ));
        }
    }
};

export const generateEventZipFolder = (state, error) => async (dispatch) => {
    if (state === successMsg) {
        dispatch({ type: types.GENERATE_EVENT_ARCHIVE_FILE_SUCCESS });

        dispatch(openAlert(
            generateEventZipFolderSuccessMsg,
            successMsg,
        ));
    } else {
        dispatch({
            payload: { error: error.request },
            type: types.EVENTS_FAIL,
        });

        if (error.request.status === 0 || error.request.status === 401) {
            dispatch(logout());

            dispatch(openAlert(
                unAuthorizedMsg,
                failMsg,
            ));
        } else {
            dispatch(openAlert(
                generateEventZipFolderFailMsg,
                failMsg,
            ));
        }
    }
};
/* End Event Photos */

/* Start Event Guest Photos */
export const getEventGuestPhotos = (data, state, error) => async (dispatch) => {
    if (state === successMsg) {
        dispatch({
            payload: { data },
            type: types.GET_EVENT_GUEST_PHOTOS_SUCCESS,
        });

        dispatch(hideLoadingLoader());
    } else {
        dispatch({
            payload: { error: error.request },
            type: types.EVENTS_FAIL,
        });

        if (error.request.status === 0 || error.request.status === 401) {
            dispatch(logout());

            dispatch(openAlert(
                unAuthorizedMsg,
                failMsg,
            ));
        }

        dispatch(hideLoadingLoader());
    }
};
/* End Event Guest Photos */

/* Start Event Slideshow */
export const getEventSlideshow = (data, state, error) => async (dispatch) => {
    if (state === successMsg) {
        dispatch({
            payload: { data },
            type: types.GET_EVENT_SLIDESHOW_SUCCESS,
        });

        dispatch(hideLoadingLoader());
    } else {
        dispatch({
            payload: { error: error.request },
            type: types.EVENTS_FAIL,
        });

        if (error.request.status === 0 || error.request.status === 401) {
            dispatch(logout());

            dispatch(openAlert(
                unAuthorizedMsg,
                failMsg,
            ));
        }

        dispatch(hideLoadingLoader());
    }
};

export const toggleEventSlideshowPhoto = (state, error, action) => async (dispatch) => {
    if (state === successMsg) {
        dispatch({ type: action === addToSlideshowKey ? types.ADD_EVENT_PHOTO_TO_SLIDESHOW_SUCCESS : types.REMOVE_EVENT_PHOTO_FROM_SLIDESHOW_SUCCESS });

        dispatch(openAlert(
            action === addToSlideshowKey ? addToSlideshowSuccessMsg : removeFromSlideshowSuccessMsg,
            successMsg,
        ));
    } else {
        dispatch({
            payload: { error: error.request },
            type: types.EVENTS_FAIL,
        });

        if (error.request.status === 0 || error.request.status === 401) {
            dispatch(logout());

            dispatch(openAlert(
                unAuthorizedMsg,
                failMsg,
            ));
        } else {
            dispatch(openAlert(
                action === addToSlideshowKey ? addToSlideshowFailMsg : removeFromSlideshowFailMsg,
                failMsg,
            ));
        }

        dispatch(hideLoadingLoader());
    }
};
/* End Event Slideshow */

/* Start Event Templates */
export const getEventTemplates = (data, state, error) => async (dispatch) => {
    if (state === successMsg) {
        dispatch({
            payload: { data },
            type: types.GET_EVENT_TEMPLATES_SUCCESS,
        });

        dispatch(hideLoadingLoader());
    } else {
        dispatch({
            payload: { error: error.request },
            type: types.EVENTS_FAIL,
        });

        if (error.request.status === 0 || error.request.status === 401) {
            dispatch(logout());

            dispatch(openAlert(
                unAuthorizedMsg,
                failMsg,
            ));
        }

        dispatch(hideLoadingLoader());
    }
};

export const getEventTemplate = (data, state, error) => async (dispatch) => {
    if (state === successMsg) {
        dispatch({
            payload: { data },
            type: types.GET_EVENT_TEMPLATE_SUCCESS,
        });

        dispatch(hideLoadingLoader());
    } else {
        dispatch({
            payload: { error: error.request },
            type: types.EVENTS_FAIL,
        });

        if (error.request.status === 0 || error.request.status === 401) {
            dispatch(logout());

            dispatch(openAlert(
                unAuthorizedMsg,
                failMsg,
            ));
        }

        dispatch(hideLoadingLoader());
    }
};

export const createEventTemplate = (data, state, error) => async (dispatch) => {
    if (state === successMsg) {
        dispatch({
            payload: { data },
            type: types.CREATE_EVENT_TEMPLATE_SUCCESS,
        });

        dispatch(openAlert(
            createSuccessMsg,
            successMsg,
        ));

        dispatch(hideSendingLoader());
    } else {
        dispatch({
            payload: { error: error.request },
            type: types.EVENTS_FAIL,
        });

        if (error.request.status === 0 || error.request.status === 401) {
            dispatch(logout());

            dispatch(openAlert(
                unAuthorizedMsg,
                failMsg,
            ));
        } else {
            dispatch(openAlert(
                createFailMsg,
                failMsg,
            ));
        }

        dispatch(hideSendingLoader());
    }
};

export const updateEventTemplate = (data, state, error) => async (dispatch) => {
    if (state === successMsg) {
        dispatch({
            payload: { data },
            type: types.UPDATE_EVENT_TEMPLATE_SUCCESS,
        });

        dispatch(openAlert(
            saveSuccessMsg,
            successMsg,
        ));

        dispatch(hideSendingLoader());
    } else {
        dispatch({
            payload: { error: error.request },
            type: types.EVENTS_FAIL,
        });

        if (error.request.status === 0 || error.request.status === 401) {
            dispatch(logout());

            dispatch(openAlert(
                unAuthorizedMsg,
                failMsg,
            ));
        } else {
            dispatch(openAlert(
                saveFailMsg,
                failMsg,
            ));
        }

        dispatch(hideSendingLoader());
    }
};

export const deleteEventTemplate = (state, error) => async (dispatch) => {
    if (state === successMsg) {
        dispatch({ type: types.DELETE_EVENT_TEMPLATE_SUCCESS });

        dispatch(openAlert(
            deleteSuccessMsg,
            successMsg,
        ));
    } else {
        dispatch({
            payload: { error: error.request },
            type: types.EVENTS_FAIL,
        });

        if (error.request.status === 0 || error.request.status === 401) {
            dispatch(logout());

            dispatch(openAlert(
                unAuthorizedMsg,
                failMsg,
            ));
        } else {
            dispatch(openAlert(
                deleteFailMsg,
                failMsg,
            ));
        }

        dispatch(hideLoadingLoader());
    }
};
/* End Event Templates */
