import "react-toastify/dist/ReactToastify.css";

import createCache from "@emotion/cache";
import { CacheProvider } from "@emotion/react";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import { Snackbar } from "atoms";
import { routesUrlsData, sidebarTabsData } from "data";
import { AuthProvider } from "providers";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { setSidebarActiveTab, switchTheme } from "redux/actions";
import { Routes } from "routes";
import { prefixer } from "stylis";
import { darkTheme, lightTheme } from "theme";

const cacheLtR = createCache({
    key: "photoflare-dashboard-mui",
    stylisPlugins: [prefixer],
});

function App() {
    const dispatch = useDispatch();

    const theme = useSelector((state) => state.themeReducer.theme);

    const location = useLocation();

    const {
        events: { url: eventsRouteUrl },
    } = routesUrlsData;

    useEffect(() => {
        const activeTab = sidebarTabsData[location.pathname];

        dispatch(setSidebarActiveTab(activeTab));
    }, [dispatch, location]); // eslint-disable-line

    useEffect(() => {
        if (location.pathname === "/") window.location.pathname = eventsRouteUrl; // eslint-disable-line
    }, [location]); // eslint-disable-line

    useEffect(() => {
        if (localStorage.getItem("theme") && localStorage.getItem("theme") === "dark") { // eslint-disable-line
            dispatch(switchTheme());

            localStorage.setItem("theme", localStorage.getItem("theme")); // eslint-disable-line
        } else localStorage.setItem("theme", theme); // eslint-disable-line
    }, []); // eslint-disable-line

    return (
        <CacheProvider value={cacheLtR}>
            <ThemeProvider theme={theme === "dark" ? darkTheme : lightTheme}>
                <CssBaseline />
                <AuthProvider>
                    <Snackbar />
                    <Routes />
                    <ToastContainer
                        closeButton={false}
                        closeOnClick={false}
                        position="bottom-left"
                        theme={theme}
                        hideProgressBar
                    />
                </AuthProvider>
            </ThemeProvider>
        </CacheProvider>
    );
}

export default App;
