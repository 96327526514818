const sortsData = {
    admins: [
        "first_name",
        "last_name",
        "email",
        "phone_number",
    ],
    eventGuestPhotos: ["time_stamp"],
    eventGuests: ["email", "phone_number"],
    eventPhotos: ["time_stamp"],
    events: ["title"],
    photographers: [
        "first_name",
        "last_name",
        "email",
        "phone_number",
    ],
};

export default sortsData;
