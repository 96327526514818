import { apiClient, apisUrls } from "apis";

const {
    photographers: {
        activate: activatePhotographerApiUrl,
        create: createPhotographerApiUrl,
        deactivate: deactivatePhotographerApiUrl,
        getOne: getOnePhotographerApiUrl,
        resetPassword: resetPhotographerPasswordApiUrl,
        update: updatePhotographerApiUrl,
        url: photographersListApiUrl,
    },
} = apisUrls;

export const getPhotographersListData = (data) => apiClient.post(
    photographersListApiUrl,
    null,
    data,
);

export const getPhotographerData = (id) => apiClient.get(
    getOnePhotographerApiUrl,
    id,
);

export const createPhotographerData = (data) => apiClient.post(
    createPhotographerApiUrl,
    null,
    data,
);

export const updatePhotographerData = (data) => apiClient.update(
    updatePhotographerApiUrl,
    null,
    data,
);

export const activatePhotographerData = (id) => apiClient.update(
    activatePhotographerApiUrl,
    id,
    {},
);

export const deactivatePhotographerData = (id) => apiClient.update(
    deactivatePhotographerApiUrl,
    id,
    {},
);

export const resetPhotographerPasswordData = (id) => apiClient.update(
    resetPhotographerPasswordApiUrl,
    id,
    {},
);
