import { headerTitlesConstants } from "@constants";

import routesUrlsData from "./routes-urls";

const {
    admins: { url: adminsRouteUrl },
    events: {
        guests: eventGuestsRouteUrl,
        templates: { url: eventTemplatesRouteUrl },
        url: eventsRouteUrl,
    },
    photographers: { url: photographersRouteUrl },
} = routesUrlsData;

const {
    admins: adminsHeaderTitle,
    createAdmin: createAdminHeaderTitle,
    createEvent: createEventHeaderTitle,
    createEventTemplate: createEventTemplateHeaderTitle,
    createPhotographer: createPhotographerHeaderTitle,
    eventGuestPhotos: eventGuestPhotosHeaderTitle,
    eventGuests: eventGuestsHeaderTitle,
    eventPhotos: eventPhotosHeaderTitle,
    eventSlideshow: eventSlideshowHeaderTitle,
    eventTemplates: eventTemplateHeaderTitle,
    events: eventsHeaderTitle,
    photographers: photographersHeaderTitle,
    updateAdmin: updateAdminHeaderTitle,
    updateEvent: updateEventHeaderTitle,
    updateEventTemplate: updateEventTemplateHeaderTitle,
    updatePhotographer: updatePhotographerHeaderTitle,
} = headerTitlesConstants;

const headerData = {
    admins: {
        backBtnRouteUrl: adminsRouteUrl,
        create: createAdminHeaderTitle,
        title: adminsHeaderTitle,
        update: updateAdminHeaderTitle,
    },
    eventGuestPhotos: {
        backBtnRouteUrl: eventGuestsRouteUrl,
        title: eventGuestPhotosHeaderTitle,
    },
    eventGuests: {
        backBtnRouteUrl: eventsRouteUrl,
        title: eventGuestsHeaderTitle,
    },
    eventPhotos: {
        backBtnRouteUrl: eventsRouteUrl,
        title: eventPhotosHeaderTitle,
    },
    eventSlideshow: {
        backBtnRouteUrl: eventsRouteUrl,
        title: eventSlideshowHeaderTitle,
    },
    eventTemplates: {
        backBtnRouteUrl: eventsRouteUrl + eventTemplatesRouteUrl,
        create: createEventTemplateHeaderTitle,
        title: eventTemplateHeaderTitle,
        update: updateEventTemplateHeaderTitle,
    },
    events: {
        backBtnRouteUrl: eventsRouteUrl,
        create: createEventHeaderTitle,
        title: eventsHeaderTitle,
        update: updateEventHeaderTitle,
    },
    photographers: {
        backBtnRouteUrl: photographersRouteUrl,
        create: createPhotographerHeaderTitle,
        title: photographersHeaderTitle,
        update: updatePhotographerHeaderTitle,
    },
};

export default headerData;
