import { makeStyles } from "@mui/styles";

const globalStyles = makeStyles((theme) => ({
    linkColor: {
        "&.primary": { color: theme.palette.primary.main },
        "&.white": { color: theme.palette.grey[200] },
        "&.whiteGrey": { color: theme.palette.grey[theme.palette.mode === "dark" ? 200 : 600] },
    },
    withoutTextDecoration: { textDecoration: "none" },
}));

export default globalStyles;
