const tooltipsConstants = {
    admins: "Admins",
    assign: "Assign",
    back: "Back",
    backToStepOne: "Back To Step One",
    close: "Close",
    darkMode: "Dark Mode",
    delete: "Delete",
    eventTemplates: "Event Templates",
    events: "Events",
    firstPage: "First Page",
    hidePassword: "Hide Password",
    lastPage: "Last Page",
    lightMode: "Light Mode",
    logout: "Logout",
    more: "More",
    nextPage: "Next Page",
    photographers: "Photographers",
    previousPage: "Previous Page",
    settings: "Settings",
    showPassword: "Show Password",
    sidebar: "Sidebar",
    update: "Update",
};

export default tooltipsConstants;
