import { messagesConstants } from "@constants";
import * as yup from "yup";

const {
    date: dateMsg,
    email: emailMsg,
    invalidEmail: invalidEmailMsg,
    title: titleMsg,
} = messagesConstants.inputsMsgs;

const updateEventDetailsFormSchema = yup.object({
    date: yup.date().typeError(dateMsg).required(),
    ownerEmail: yup.string().email(invalidEmailMsg).required(emailMsg),
    title: yup.string().required(titleMsg),
});

export default updateEventDetailsFormSchema;
