import { messagesConstants } from "@constants";
import { hideSendingLoader, openAlert } from "redux/actions";
import * as types from "redux/types";
import { getCookie, removeCookie, setCookie } from "utils";

const {
    authMsgs: {
        login: { fail: loginFailMsg },
        unAuthorized: unAuthorizedMsg,
    },
    fail: failMsg,
    success: successMsg,
} = messagesConstants;

export const getUserInfo = () => async (dispatch) => {
    dispatch({ type: types.AUTH_REQUEST });

    if (getCookie("token") && getCookie("user")) {
        dispatch({
            payload: {
                data: {
                    token: getCookie("token"),
                    user: JSON.parse(getCookie("user")),
                },
            },
            type: types.GET_USER_INFO_SUCCESS,
        });
    } else {
        dispatch({
            payload: { error: unAuthorizedMsg },
            type: types.AUTH_FAIL,
        });

        removeCookie("token");

        removeCookie("user");
    }
};

export const login = (data, state, error) => async (dispatch) => {
    if (state === successMsg) {
        dispatch({
            payload: {
                data: {
                    token: data.access_token,
                    user: data.user,
                },
            },
            type: types.USER_LOG_IN_SUCCESS,
        });

        setCookie(
            "token",
            data.access_token,
        );

        setCookie(
            "user",
            JSON.stringify(data.user),
        );

        dispatch(hideSendingLoader());
    } else {
        dispatch({
            payload: { error: error.request },
            type: types.AUTH_FAIL,
        });

        dispatch(openAlert(
            loginFailMsg,
            failMsg,
        ));

        dispatch(hideSendingLoader());
    }
};

export const logout = () => (dispatch) => {
    dispatch({ type: types.USER_LOG_OUT_SUCCESS });

    removeCookie("token");

    removeCookie("user");
};
