import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { hideLoadingLoader, logout as logoutAction, showLoadingLoader } from "redux/actions";
import * as types from "redux/types";
import { getCookie } from "utils";

import { useLogoutData } from "./use-mutation-data";

function useLogout() {
    const dispatch = useDispatch();

    const {
        isError,
        isLoading,
        isSuccess,
        mutate,
    } = useLogoutData();

    const logout = () => {
        if (getCookie("token")) mutate();

        else dispatch(logoutAction());
    };

    useEffect(() => {
        if (isLoading) {
            dispatch(showLoadingLoader());

            dispatch({ type: types.USER_LOGGING_OUT });
        }
    }, [isLoading]); // eslint-disable-line

    useEffect(() => {
        if (isSuccess) {
            dispatch(logoutAction());

            dispatch(hideLoadingLoader());
        }
    }, [isSuccess]); // eslint-disable-line

    useEffect(() => {
        if (isError) dispatch(hideLoadingLoader());
    }, [isError]); // eslint-disable-line

    return { logout };
}

export default useLogout;
