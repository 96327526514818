import { globalConstants } from "@constants";
import PauseIcon from "@mui/icons-material/Pause";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import { Masonry } from "@mui/lab";
import { Box, Button } from "@mui/material";
import { Lightbox, Modal, Photo } from "atoms";
import { useEffect, useState } from "react";
import { baseTheme as theme } from "theme";

function Photos({
    list,
    moduleName,
}) {
    const [isAutoplay, setIsAutoplay] = useState(false);

    const [lightboxData, setLightboxData] = useState({
        images: [],
        open: false,
        startIndex: 0,
    });

    const [modalData, setModalData] = useState({
        btnColor: null,
        btnName: null,
        content: null,
        eventId: null,
        photoId: null,
        show: false,
        submitModalActionHandler: () => {},
        title: null,
    });

    const { by: byConstant } = globalConstants;

    const lightboxImages = list?.map(({
        overlayImage,
        photographer,
    }) => ({
        title: `${byConstant}${photographer.firstName}${" "}${photographer.lastName}`,
        url: overlayImage,
    }));

    const clickLightboxNavigationHandler = (direction) => {
        if (direction === "next") {
            setLightboxData((prev) => ({
                ...prev,
                startIndex: (prev.startIndex + 1) % lightboxData.images.length,
            }));
        } else if (direction === "prev") {
            setLightboxData((prev) => ({
                ...prev,
                startIndex: (prev.startIndex - 1 + lightboxData.images.length) % lightboxData.images.length,
            }));
        }
    };

    useEffect(
        () => {
            let autoPlay;

            if (lightboxData.open && isAutoplay) {
                autoPlay = setInterval(
                    () => {
                        setLightboxData((prev) => ({
                            ...prev,
                            startIndex: (prev.startIndex + 1) % lightboxData.images.length,
                        }));
                    },
                    3000,
                );
            }

            if (!lightboxData.open) setIsAutoplay(false);

            return () => clearInterval(autoPlay);
        },
        [lightboxData.open, isAutoplay, lightboxData.images.length],
    );

    useEffect(
        () => {
            if (lightboxData.open) {
                const nextButton = document.querySelector(".lb-icon-arrow.next"); // eslint-disable-line

                const prevButton = document.querySelector(".lb-icon-arrow.prev"); // eslint-disable-line

                if (nextButton && prevButton) {
                    nextButton.addEventListener(
                        "click",
                        () => clickLightboxNavigationHandler("next"),
                    );

                    prevButton.addEventListener(
                        "click",
                        () => clickLightboxNavigationHandler("prev"),
                    );
                }

                return () => {
                    if (nextButton && prevButton) {
                        nextButton.removeEventListener(
                            "click",
                            () => clickLightboxNavigationHandler("next"),
                        );

                        prevButton.removeEventListener(
                            "click",
                            () => clickLightboxNavigationHandler("prev"),
                        );
                    }
                };
            }
        },
        [lightboxData.open, lightboxData.startIndex, lightboxData.images.length], // eslint-disable-line
    );

    return (
        <>
            <Masonry
                spacing={2}
                style={{ margin: "initial" }}
                columns={{
                    lg: 3,
                    sm: 2,
                    xs: 1,
                }}
            >
                {list.map((photo, index) => (
                    <Photo
                        data={photo}
                        index={index}
                        lightboxImages={lightboxImages}
                        modalData={modalData}
                        moduleName={moduleName}
                        setLightboxData={setLightboxData}
                        setModalData={setModalData}
                    />
                ))}
            </Masonry>
            {lightboxData.open && (
                <Box>
                    <Lightbox
                        lightboxData={lightboxData}
                        setLightboxData={setLightboxData}
                    />
                    <Button
                        sx={{
                            background: theme.palette.error.main,
                            border: "none",
                            borderRadius: "5px",
                            bottom: "10px",
                            color: theme.palette.grey[100],
                            cursor: "pointer",
                            padding: "10px 20px",
                            position: "fixed",
                            right: "10px",
                            zIndex: 100000,
                        }}
                        onClick={() => setIsAutoplay(!isAutoplay)}
                    >
                        {isAutoplay ? <PauseIcon /> : <PlayArrowIcon />}
                    </Button>
                </Box>
            )}
            <Modal
                btnColor={modalData.btnColor}
                btnName={modalData.btnName}
                content={modalData.content}
                showModal={modalData.show}
                title={modalData.title}
                onSubmitModalAction={modalData.submitModalActionHandler}
                onCloseModal={() => {
                    setModalData({
                        ...modalData,
                        show: false,
                    });
                }}
            />
        </>
    );
}

export default Photos;
