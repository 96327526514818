import Box from "@mui/material/AppBar";
import { styled } from "@mui/styles";

const sidebarWidth = 240;

const StyledContent = styled(
    Box,
    { shouldForwardProp: (prop) => prop !== "open" },
)(({
    open,
    theme,
}) => ({
    transition: theme.transitions.create(
        ["width", "margin"],
        {
            duration: theme.transitions.duration.leavingScreen,
            easing: theme.transitions.easing.sharp,
        },
    ),
    ...open && {
        marginLeft: sidebarWidth,
        transition: theme.transitions.create(
            ["width", "margin"],
            {
                duration: theme.transitions.duration.enteringScreen,
                easing: theme.transitions.easing.sharp,
            },
        ),
        width: `calc(100% - ${sidebarWidth}px)`,
    },
    flexGrow: 1,
    marginTop: 65,
    width: "85%",
}));

export default StyledContent;
