import { tooltipsConstants } from "@constants";
import NightlightIcon from "@mui/icons-material/Nightlight";
import WbSunnyIcon from "@mui/icons-material/WbSunny";
import { IconButton, Tooltip } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { switchTheme } from "redux/actions";

function ThemeSwitcher() {
    const dispatch = useDispatch();

    const theme = useSelector((state) => state.themeReducer.theme);

    const {
        darkMode: darkModeTooltip,
        lightMode: lightModeTooltip,
    } = tooltipsConstants;

    const switchThemeHandler = () => {
        dispatch(switchTheme());

        localStorage.setItem("theme", theme === "dark" ? "light" : "dark"); // eslint-disable-line
    };

    return (
        <Tooltip title={theme === "dark" ? lightModeTooltip : darkModeTooltip}>
            <IconButton onClick={switchThemeHandler}>{theme === "dark" ? <WbSunnyIcon /> : <NightlightIcon />}</IconButton>
        </Tooltip>
    );
}

export default ThemeSwitcher;
