import { inputsLabelsConstants } from "@constants";

const {
    email: emailInputLabel,
    phoneNumber: phoneNumberInputLabel,
} = inputsLabelsConstants;

const resendEventPhotoFormData = [
    {
        col: { xs: 12 },
        label: emailInputLabel,
        name: "email",
        type: "text-field",
    },
    {
        col: { xs: 12 },
        label: phoneNumberInputLabel,
        name: "phoneNumber",
        type: "text-field",
    },
];

export default resendEventPhotoFormData;
