import { Container } from "@mui/material";
import { Footer } from "components";
import { StyledContent } from "styles";

function Content({
    children,
    open,
}) {
    return (
        <StyledContent
            component="main"
            open={open}
        >
            <Container sx={{ minHeight: "90vh" }}>{children}</Container>
            <Footer />
        </StyledContent>
    );
}

export default Content;
