const globalData = {
    calendarViews: ["year", "month", "day", "hours", "minutes"],
    eventCreationTypes: {
        copyEvent: "copyEvent",
        titleOnly: "titleOnly",
        useTemplate: "useTemplate",
    },
};

export default globalData;
