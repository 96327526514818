const updateEventTabsConstants = {
    consent: {
        key: "consent",
        tab: "Consent",
    },
    eventDetails: {
        key: "eventDetails",
        tab: "Event Details",
    },
    publicPage: {
        key: "publicPage",
        tab: "Public Page",
    },
    shareDetails: {
        key: "shareDetails",
        tab: "Share Details",
    },
    socialSharing: {
        key: "socialSharing",
        tab: "Social Sharing",
    },
};

export default updateEventTabsConstants;
