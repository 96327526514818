import { viewsConstants } from "@constants";
import {
    Box,
    Button,
    Grid,
    Typography,
} from "@mui/material";
import { routesUrlsData } from "data";
import { Link } from "react-router-dom";

function NotFoundView() {
    const {
        events: { url: eventsRouteUrl },
    } = routesUrlsData;

    const {
        404: fourZeroFourConstant,
        goToDashboard: goToDashboardConstant,
        pageNotFound: pageNotFoundConstant,
    } = viewsConstants.notFound;

    return (
        <Grid
            alignItems="center"
            flexDirection="column"
            height="100vh"
            justifyContent="center"
            margin="auto"
            textAlign="center"
            width="90%"
            container
        >
            <Box>
                <Typography
                    color="primary"
                    component="h1"
                    variant="h2"
                >
                    {fourZeroFourConstant}
                </Typography>
                <Typography
                    marginBottom="24px"
                    variant="body1"
                >
                    {pageNotFoundConstant}
                </Typography>
                <Button
                    component={Link}
                    size="large"
                    to={eventsRouteUrl}
                    variant="contained"
                >
                    {goToDashboardConstant}
                </Button>
            </Box>
        </Grid>
    );
}

export default NotFoundView;
