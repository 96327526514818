import { globalConstants, tooltipsConstants } from "@constants";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import LogoutIcon from "@mui/icons-material/Logout";
import {
    Divider,
    IconButton,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Tooltip,
    Typography,
} from "@mui/material";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import logo from "assets/images/logo.svg";
import { Media, ThemeSwitcher } from "atoms";
import { Content, Navbar } from "components";
import { sidebarData } from "data";
import { useLogout } from "hooks";
import { useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { StyledSidebar, StyledSidebarHeader } from "styles";

function Sidebar({ children }) {
    const sidebarActiveTab = useSelector((state) => state.sidebarActiveTabReducer.activeTab);

    const user = useSelector((state) => state.authReducer.user);

    const [open, setOpen] = useState(false);

    const { logout } = useLogout();

    const {
        close: closeTooltip,
        logout: logoutTooltip,
    } = tooltipsConstants;

    const { version: versionConstant } = globalConstants;

    const drawerOpenedHandler = () => setOpen(true);

    const drawerClosedHandler = () => setOpen(false);

    const customizedSidebarData = user?.authorities?.length === 1 ? sidebarData.slice(1) : sidebarData;

    return (
        <Box sx={{ display: "flex" }}>
            <CssBaseline />
            <Navbar
                open={open}
                onOpenSidebar={drawerOpenedHandler}
            />
            <StyledSidebar
                open={open}
                variant="permanent"
            >
                <StyledSidebarHeader>
                    <Box paddingInline={2}>
                        <Media
                            alternativeText="logo"
                            height={80}
                            src={logo}
                            type="image"
                            width={120}
                        />
                    </Box>
                    <IconButton onClick={drawerClosedHandler}>
                        <Tooltip title={closeTooltip}><ChevronLeftIcon /></Tooltip>
                    </IconButton>
                </StyledSidebarHeader>
                <Divider />
                <List>
                    {customizedSidebarData.map(({
                        Icon,
                        id,
                        routeUrl,
                        title,
                        tooltip,
                    }) => (
                        <ListItem
                            key={id}
                            sx={{ display: "block" }}
                            disablePadding
                        >
                            <ListItemButton
                                className={sidebarActiveTab.id === id ? "active" : ""}
                                component={Link}
                                to={routeUrl}
                                sx={{
                                    justifyContent: open ? "initial" : "center",
                                    minHeight: 48,
                                    px: 2.5,
                                }}
                            >
                                <ListItemIcon
                                    sx={{
                                        justifyContent: "center",
                                        minWidth: 0,
                                        mr: open ? 3 : "auto",
                                    }}
                                >
                                    <Tooltip
                                        placement="right"
                                        title={tooltip}
                                    >
                                        <Icon color={sidebarActiveTab.id === id ? "primary" : ""} />
                                    </Tooltip>
                                </ListItemIcon>
                                <ListItemText
                                    primary={title}
                                    sx={{ opacity: open ? 1 : 0 }}
                                />
                            </ListItemButton>
                        </ListItem>
                    ))}
                </List>
                <Divider />
                <Box
                    alignItems="center"
                    flexDirection="column"
                    display={{
                        md: "none",
                        xs: "flex",
                    }}
                >
                    <ThemeSwitcher />
                    <Box>
                        <IconButton
                            edge="end"
                            onClick={logout}
                        >
                            <Tooltip title={logoutTooltip}><LogoutIcon /></Tooltip>
                        </IconButton>
                    </Box>
                </Box>
                <Typography
                    mt={2}
                    sx={{ opacity: ".6" }}
                    textAlign="center"
                    variant="caption"
                >
                    {versionConstant}
                </Typography>
            </StyledSidebar>
            <Content open={open}>{children}</Content>
        </Box>
    );
}

export default Sidebar;
