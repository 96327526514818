const apisUrls = {
    admins: {
        activate: "users/admin/active",
        create: "users/admin/create",
        deactivate: "users/admin/active",
        getOne: "users/one",
        resetPassword: "email/admin/reset-pass-mail",
        update: "users/admin/update",
        url: "users/admin/all",
    },
    auth: {
        login: "users/login/",
        logout: "users/logout/",
        setPassword: "users/pass/reset",
    },
    clone: "clone",
    deletePhoto: "photos/delete",
    events: {
        assign: "events/assign/photographers/event",
        create: "events/create",
        createCopyEvent: "events",
        createUseTemplate: "events/templates",
        delete: "events/delete",
        deleteEventBannerImage: "events/banner",
        deleteEventDomain: "event-settings/domains",
        deleteEventOverlay: "overlays/remove",
        deleteEventPageAction: "public-page/actions",
        eventSettings: "event-settings",
        export: "guests/all/event",
        generateEventZipFolder: "event-file",
        getOne: "events/one",
        guestPhotos: "events/all/guest",
        guests: "guests/all/event",
        photos: "photos/all/event",
        publicPage: "public-page",
        resendEventPhoto: "photos/resend/guests/photo/",
        setDefaultEventOverlay: "events/add/default/overlay",
        shareEventGallery: "email/guest/public-gallery-mail",
        slideshow: "events/slideshow",
        templates: "events/templates",
        toggleArchive: "events/archive",
        toggleEventSlideshowPhoto: "photos/slideshow",
        update: "events/update",
        uploadEventOverlay: "events/add/overlay/event",
        url: "events/all",
    },
    photographers: {
        activate: "users/photographer/active",
        create: "users/photographer/create",
        deactivate: "users/photographer/active",
        getOne: "users/one",
        resetPassword: "email/photographer/reset-pass-mail",
        update: "users/photographer/update",
        url: "users/photographer/all",
    },
    uploadPhoto: "photos/upload",
};

export default apisUrls;
