import { globalConstants, tooltipsConstants } from "@constants";
import LogoutIcon from "@mui/icons-material/Logout";
import MenuIcon from "@mui/icons-material/Menu";
import SearchIcon from "@mui/icons-material/Search";
import {
    Box,
    IconButton,
    TextField,
    Toolbar,
    Tooltip,
    Typography,
} from "@mui/material";
import { ThemeSwitcher } from "atoms";
import { routesUrlsData } from "data";
import { useLogout } from "hooks";
import { useCallback } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { StyledNavbar } from "styles";
import {
    debounce,
    getQuery,
    getSearchParams,
} from "utils";

function Navbar({
    onOpenSidebar,
    open,
}) {
    const navigate = useNavigate();

    const location = useLocation();

    const user = useSelector((state) => state.authReducer.user);

    const [searchParams] = useSearchParams();

    const { logout } = useLogout();

    const {
        logout: logoutTooltip,
        sidebar: sidebarTooltip,
    } = tooltipsConstants;

    const {
        hi: hiConstant,
        photoflareDashboard: photoflareDashboardConstant,
    } = globalConstants;

    const { url: eventsRouteUrl } = routesUrlsData.events;

    const { filter: filterSearchParam } = getSearchParams(searchParams);

    const changeSearchFieldValueHandler = useCallback(
        debounce(
            (e) => {
                const { value } = e.target;

                const query = getQuery(
                    0,
                    10,
                    filterSearchParam,
                    value,
                );

                if (location.pathname === eventsRouteUrl) navigate(`${eventsRouteUrl}?${query}`);
                else if (value.length > 0) navigate(`${eventsRouteUrl}?${query}`);
            },
            1200,
        ),
        [filterSearchParam, location.pathname],
    ); /* eslint react-hooks/exhaustive-deps: "off" */

    return (
        <StyledNavbar
            open={open}
            position="fixed"
        >
            <Toolbar sx={{ justifyContent: "space-between" }}>
                <Box
                    alignItems="center"
                    display="flex"
                >
                    <IconButton
                        color="inherit"
                        edge="start"
                        sx={{
                            marginRight: {
                                md: 5,
                                xs: 0,

                            },
                            ...open && { display: "none" },
                        }}
                        onClick={onOpenSidebar}
                    >
                        <Tooltip title={sidebarTooltip}><MenuIcon /></Tooltip>
                    </IconButton>
                    <Typography
                        variant="h6"
                        sx={{
                            display: {
                                md: "block",
                                xs: "none",
                            },
                        }}
                        noWrap
                    >
                        {photoflareDashboardConstant}
                    </Typography>
                </Box>
                <Box
                    marginRight={{
                        md: 0,
                        xs: 2,
                    }}
                >
                    <TextField
                        className="search-input"
                        placeholder={globalConstants.searchEvents}
                        variant="outlined"
                        InputProps={{
                            startAdornment: <SearchIcon sx={{ marginRight: 2 }} />,
                        }}
                        onChange={changeSearchFieldValueHandler}
                    />
                </Box>
                <Box
                    alignItems="center"
                    display="flex"
                >
                    {user && (
                        <Typography
                            variant="caption"
                            noWrap
                        >
                            {hiConstant}
                            {" "}
                            {user?.firstName}
                            {" "}
                            {user?.lastName}
                        </Typography>
                    )}
                    <Box
                        display={{
                            md: "block",
                            xs: "none",

                        }}
                    >
                        <ThemeSwitcher />
                    </Box>
                    <Box
                        display={{
                            md: "block",
                            xs: "none",

                        }}
                    >
                        <IconButton
                            color="inherit"
                            edge="end"
                            onClick={logout}
                        >
                            <Tooltip title={logoutTooltip}><LogoutIcon /></Tooltip>
                        </IconButton>
                    </Box>
                </Box>
            </Toolbar>
        </StyledNavbar>
    );
}

export default Navbar;
