const headerTitlesConstants = {
    admins: "Admins",
    auth: {
        login: "Login | Dashboard",
        setPassword: "Set Password | Dashboard",
    },
    createAdmin: "Create Admin",
    createEvent: "Create Event",
    createEventTemplate: "Create Event Template",
    createPhotographer: "Create Photographer",
    eventGuestPhotos: "Event Guest Photos",
    eventGuests: "Event Guests",
    eventPhotos: "Event Photos",
    eventSlideshow: "Event Slideshow",
    eventTemplates: "Event Templates",
    events: "Events",
    photographers: "Photographers",
    updateAdmin: "Update Admin",
    updateEvent: "Update Event",
    updateEventTemplate: "Update Event Template",
    updatePhotographer: "Update Photographer",
};

export default headerTitlesConstants;
