import { apiClient, apisUrls } from "apis";

const {
    admins: {
        activate: activateAdminApiUrl,
        create: createAdminApiUrl,
        deactivate: deactivateAdminApiUrl,
        getOne: getOneAdminApiUrl,
        resetPassword: resetAdminPasswordApiUrl,
        update: updateAdminApiUrl,
        url: adminsListApiUrl,
    },
} = apisUrls;

export const getAdminsListData = (data) => apiClient.post(
    adminsListApiUrl,
    null,
    data,
);

export const getAdminData = (id) => apiClient.get(
    getOneAdminApiUrl,
    id,
);

export const createAdminData = (data) => apiClient.post(
    createAdminApiUrl,
    null,
    data,
);

export const updateAdminData = (data) => apiClient.update(
    updateAdminApiUrl,
    null,
    data,
);

export const activateAdminData = (id) => apiClient.update(
    activateAdminApiUrl,
    id,
    {},
);

export const deactivateAdminData = (id) => apiClient.update(
    deactivateAdminApiUrl,
    id,
    {},
);

export const resetAdminPasswordData = (id) => apiClient.update(
    resetAdminPasswordApiUrl,
    id,
    {},
);
