const tableColumnsNamesConstants = {
    authorities: "Authorities",
    createdAt: "Created At",
    email: "Email",
    isDefault: "Default",
    name: "Name",
    phoneNumber: "Phone Number",
    photographers: "Photographers",
    status: "Status",
    title: "Title",
};

export default tableColumnsNamesConstants;
