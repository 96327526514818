import { messagesConstants } from "@constants";
import * as yup from "yup";

const {
    notMatchingPassword: notMatchingPasswordMsg,
    password: passwordMsg,
} = messagesConstants.inputsMsgs;

const setPasswordFormSchema = yup.object({
    confirmPassword: yup.string().required(passwordMsg).oneOf(
        [yup.ref("password"), null],
        notMatchingPasswordMsg,
    ),
    password: yup.string().required(passwordMsg),
});

export default setPasswordFormSchema;
