import { globalConstants, messagesConstants } from "@constants";
import { yupResolver } from "@hookform/resolvers/yup";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import {
    Autocomplete,
    Box,
    Checkbox,
    TextField,
} from "@mui/material";
import { createEventFormData } from "data";
import { FormLayout } from "layouts";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { getEvents, getEventTemplates } from "redux/actions";
import * as types from "redux/types";
import { createEventFormSchema } from "schemas";
import { getConvertedListData, renderFormController } from "utils";

function CreateEventForm({
    action,
    eventFormData,
    onSubmitForm,
    values,
}) {
    const dispatch = useDispatch();

    const eventsStoredData = useSelector((state) => state.eventsReducer.data);

    const eventTemplatesStoredData = useSelector((state) => state.eventsReducer.templates);

    const formMethods = useForm({
        defaultValues: values,
        mode: "onChange",
        resolver: yupResolver(createEventFormSchema?.[eventFormData?.creationType]),
    });

    const {
        eventsData: {
            data: eventsListData,
            error: eventsListError,
            isError: eventsListIsError,
            isLoading: eventsListIsLoading,
            isSuccess: eventsListIsSuccess,
        },
        templatesData: {
            data: eventTemplatesListData,
            error: eventTemplatesListError,
            isError: eventTemplatesListIsError,
            isLoading: eventTemplatesListIsLoading,
            isSuccess: eventTemplatesListIsSuccess,
        },
    } = eventFormData;

    const {
        fail: failMsg,
        success: successMsg,
    } = messagesConstants;

    const {
        control,
        formState: { errors },
        reset,
    } = formMethods;

    const { data: eventsListStoredData } = eventsStoredData;

    const { data: eventTemplatesListStoredData } = eventTemplatesStoredData;

    const filteredCreateEventFormData = {
        copyEvent: [createEventFormData[0], createEventFormData[1]],
        titleOnly: [createEventFormData[0]],
        useTemplate: [createEventFormData[0], createEventFormData[2]],
    };

    const createEventHandler = ({
        event,
        template,
        title,
    }) => {
        onSubmitForm(
            {
                event,
                template,
                title,
            },
            reset,
        );
    };

    const renderFormControllerChildren = (field, label, name, type) => {
        if (type === "text-field") {
            return (
                <TextField
                    {...field}
                    error={errors[name]}
                    helperText={errors[name] && errors[name]?.message}
                    label={label}
                    variant="outlined"
                    fullWidth
                />
            );
        }

        return (name === "event" || name === "template") && (
            <Autocomplete
                getOptionLabel={(option) => (option?.title ? option?.title : "")}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                options={name === "event" ? eventsListStoredData : eventTemplatesListStoredData}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        disabled={eventsListIsLoading || eventTemplatesListIsLoading}
                        error={errors[name]}
                        helperText={errors[name] && errors[name]?.message}
                        label={label}
                        placeholder={label}
                        variant="outlined"
                    />
                )}
                renderOption={(props, option, { selected }) => (
                    <Box
                        sx={{ display: "block" }}
                        {...props}
                    >
                        <Checkbox
                            checked={selected}
                            checkedIcon={<CheckBoxIcon color="primary" />}
                            icon={<CheckBoxOutlineBlankIcon />}
                            style={{ marginRight: 8 }}
                        />
                        {option?.title}
                    </Box>
                )}
                onChange={(_, value) => field.onChange(value)}
            />
        );
    };

    useEffect(() => {
        if (eventsListIsLoading || eventTemplatesListIsLoading) dispatch({ type: types.EVENTS_REQUEST });
    }, [eventsListIsLoading, eventTemplatesListIsLoading]); // eslint-disable-line

    useEffect(() => {
        if (eventsListIsSuccess) {
            dispatch(getEvents(
                getConvertedListData(eventsListData.data),
                successMsg,
            ));
        }

        if (eventTemplatesListIsSuccess) {
            dispatch(getEventTemplates(
                getConvertedListData(eventTemplatesListData.data),
                successMsg,
            ));
        }
    }, [eventsListIsSuccess, eventTemplatesListIsSuccess]); // eslint-disable-line

    useEffect(() => {
        if (eventsListIsError) {
            dispatch(getEvents(
                null,
                failMsg,
                eventsListError,
            ));
        }

        if (eventTemplatesListIsError) {
            dispatch(getEventTemplates(
                null,
                failMsg,
                eventTemplatesListError,
            ));
        }
    }, [eventsListIsError, eventTemplatesListIsError]); // eslint-disable-line

    return (
        <FormLayout
            action={action}
            formMethods={formMethods}
            data={renderFormController(
                control,
                filteredCreateEventFormData?.[eventFormData?.creationType],
                renderFormControllerChildren,
            )}
            stepBtnData={{
                action: () => eventFormData.setStep(1),
                icon: <ArrowBackIosIcon />,
                text: globalConstants.previous,
            }}
            hasSteps
            onSubmitForm={createEventHandler}
        />
    );
}

export default CreateEventForm;
