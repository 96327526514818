import { inputsLabelsConstants } from "@constants";

const {
    email: emailInputLabel,
    firstName: firstNameInputLabel,
    lastName: lastNameInputLabel,
    phoneNumber: phoneNumberInputLabel,
} = inputsLabelsConstants;

const photographerFormData = [
    {
        col: { xs: 12 },
        label: firstNameInputLabel,
        name: "firstName",
        type: "text-field",
    },
    {
        col: { xs: 12 },
        label: lastNameInputLabel,
        name: "lastName",
        type: "text-field",
    },
    {
        col: { xs: 12 },
        label: emailInputLabel,
        name: "email",
        type: "text-field",
    },
    {
        col: { xs: 12 },
        label: phoneNumberInputLabel,
        name: "phoneNumber",
        type: "text-field",
    },
    {
        col: { xs: 12 },
        label: "isDefault",
        name: "isDefault",
        type: "checkbox",
    },
];

export default photographerFormData;
