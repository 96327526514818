import {
    deleteData,
    getData,
    postData,
    updateData,
} from "services";

const apiClient = {
    delete: (apiUrl, id) => deleteData(
        apiUrl,
        id,
    ),
    get: (
        apiUrl,
        id,
        query,
        isFileRequest,
        fileFilter,
    ) => getData(
        apiUrl,
        id,
        query,
        isFileRequest,
        fileFilter,
    ),
    post: (
        apiUrl,
        id,
        data,
        isFormData,
        isPublicService,
        isLoginAuthRequest,
        subApi,
        query,
    ) => postData(
        apiUrl,
        id,
        data,
        isFormData,
        isPublicService,
        isLoginAuthRequest,
        subApi,
        query,
    ),
    update: (
        apiUrl,
        id,
        data,
        isFormData,
        query,
        subApi,
        isSetPasswordRequest,
        token,
    ) => updateData(
        apiUrl,
        id,
        data,
        isFormData,
        query,
        subApi,
        isSetPasswordRequest,
        token,
    ),
};

export default apiClient;
