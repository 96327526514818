const modulesConstants = {
    admins: "admins",
    eventGuestPhotos: "eventGuestPhotos",
    eventGuests: "eventGuests",
    eventPhotos: "eventPhotos",
    eventSlideshow: "eventSlideshow",
    eventTemplates: "eventTemplates",
    events: "events",
    photographers: "photographers",
};

export default modulesConstants;
