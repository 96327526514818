import { authContext } from "contexts";
import { routesUrlsData } from "data";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { getUserInfo } from "redux/actions";

function AuthProvider({ children }) {
    const dispatch = useDispatch();

    const user = useSelector((state) => state.authReducer.user);

    const isLoggedOut = useSelector((state) => state.authReducer.isLoggedOut);

    const loading = useSelector((state) => state.authReducer.loader);

    const location = useLocation();

    const navigate = useNavigate();

    const {
        login: loginRouteUrl,
        setPassword: setPasswordRouteUrl,
    } = routesUrlsData.auth;

    useEffect(() => {
        if (isLoggedOut && !loading && location.pathname !== setPasswordRouteUrl) {
            navigate(
                loginRouteUrl,
                {
                    replace: true,
                    state: { from: location },
                },
            );
        }
    }, [isLoggedOut, loading]); // eslint-disable-line

    useEffect(() => {
        dispatch(getUserInfo());
    }, []); // eslint-disable-line

    return <authContext.Provider value={user}>{children}</authContext.Provider>;
}

export default AuthProvider;
