import * as types from "redux/types";

export const openAlert = (message, status) => ({
    payload: {
        message,
        status,
    },
    type: types.OPEN_ALERT,
});

export const closeAlert = () => ({ type: types.CLOSE_ALERT });
