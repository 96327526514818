import { globalConstants, inputsLabelsConstants } from "@constants";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import {
    Box,
    Button,
    ButtonGroup,
    CardContent,
} from "@mui/material";
import { globalData } from "data";
import { StyledCard } from "styles";

function EventStepOne({ eventFormData }) {
    const {
        eventCreationTypes: {
            copyEvent: eventCreationCopyEventType,
            titleOnly: eventCreationTitleOnlyType,
            useTemplate: eventCreationUseTemplateType,
        },
    } = globalData;

    const {
        copyEvent: copyEventInputLabel,
        titleOnly: titleOnlyInputLabel,
        useTemplate: useTemplateInputLabel,
    } = inputsLabelsConstants;

    return (
        <>
            <StyledCard>
                <CardContent sx={{ paddingBottom: "0!important" }}>
                    <Box
                        alignItems="center"
                        display="flex"
                        justifyContent="center"
                    >
                        <ButtonGroup size="large">
                            <Button
                                variant={eventFormData.creationType === eventCreationTitleOnlyType ? "contained" : "outlined"}
                                onClick={() => eventFormData.setCreationType(eventCreationTitleOnlyType)}
                            >
                                {titleOnlyInputLabel}
                            </Button>
                            <Button
                                variant={eventFormData.creationType === eventCreationCopyEventType ? "contained" : "outlined"}
                                onClick={() => eventFormData.setCreationType(eventCreationCopyEventType)}
                            >
                                {copyEventInputLabel}
                            </Button>
                            <Button
                                variant={eventFormData.creationType === eventCreationUseTemplateType ? "contained" : "outlined"}
                                onClick={() => eventFormData.setCreationType(eventCreationUseTemplateType)}
                            >
                                {useTemplateInputLabel}
                            </Button>
                        </ButtonGroup>
                    </Box>
                </CardContent>
            </StyledCard>
            <Box
                display="flex"
                justifyContent="flex-end"
            >
                <Button
                    endIcon={<NavigateNextIcon />}
                    size="large"
                    variant="outlined"
                    onClick={() => eventFormData.setStep(2)}
                >
                    {globalConstants.next}
                </Button>
            </Box>
        </>
    );
}

export default EventStepOne;
