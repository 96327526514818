export const GET_PHOTOGRAPHERS_SUCCESS = "GET_PHOTOGRAPHERS_SUCCESS";

export const GET_PHOTOGRAPHER_SUCCESS = "GET_PHOTOGRAPHER_SUCCESS";

export const CREATE_PHOTOGRAPHER_SUCCESS = "CREATE_PHOTOGRAPHER_SUCCESS";

export const CREATE_PHOTOGRAPHER_LANG_SUCCESS = "CREATE_PHOTOGRAPHER_LANG_SUCCESS";

export const UPDATE_PHOTOGRAPHER_SUCCESS = "UPDATE_PHOTOGRAPHER_SUCCESS";

export const DEACTIVATE_PHOTOGRAPHER_SUCCESS = "DEACTIVATE_PHOTOGRAPHER_SUCCESS";

export const ACTIVATE_PHOTOGRAPHER_SUCCESS = "ACTIVATE_PHOTOGRAPHER_SUCCESS";

export const RESET_PHOTOGRAPHER_PASSWORD_SUCCESS = "RESET_PHOTOGRAPHER_PASSWORD_SUCCESS";

export const PHOTOGRAPHERS_REQUEST = "PHOTOGRAPHERS_REQUEST";

export const PHOTOGRAPHERS_FAIL = "PHOTOGRAPHERS_FAIL";
