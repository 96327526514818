export const GET_ADMINS_SUCCESS = "GET_ADMINS_SUCCESS";

export const GET_ADMIN_SUCCESS = "GET_ADMIN_SUCCESS";

export const CREATE_ADMIN_SUCCESS = "CREATE_ADMIN_SUCCESS";

export const CREATE_ADMIN_LANG_SUCCESS = "CREATE_ADMIN_LANG_SUCCESS";

export const UPDATE_ADMIN_SUCCESS = "UPDATE_ADMIN_SUCCESS";

export const DEACTIVATE_ADMIN_SUCCESS = "DEACTIVATE_ADMIN_SUCCESS";

export const ACTIVATE_ADMIN_SUCCESS = "ACTIVATE_ADMIN_SUCCESS";

export const RESET_ADMIN_PASSWORD_SUCCESS = "RESET_ADMIN_PASSWORD_SUCCESS";

export const ADMINS_REQUEST = "ADMINS_REQUEST";

export const ADMINS_FAIL = "ADMINS_FAIL";
