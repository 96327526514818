import {
    btnsNamesConstants,
    inputsLabelsConstants,
    messagesConstants,
    tooltipsConstants,
    viewsConstants,
} from "@constants";
import DeleteIcon from "@mui/icons-material/Delete";
import {
    Box,
    Button,
    IconButton,
    ImageListItem,
    ImageListItemBar,
    Link,
    List,
    ListItem,
    ListItemText,
    Switch,
    TextField,
    Tooltip,
    Typography,
} from "@mui/material";
import { regexData, updateEventFormData } from "data";
import {
    useAddEventPageActionData,
    useDeleteEventBannerImageData,
    useDeleteEventPageActionData,
    useUploadEventBannerImageData,
} from "hooks";
import { FormLayout } from "layouts";
import { useCallback, useEffect, useState } from "react";
import { FileUploader } from "react-drag-drop-files";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
    addEventPageAction,
    deleteEventBannerImage,
    deleteEventPageAction,
    uploadEventBannerImage,
} from "redux/actions";
import * as types from "redux/types";
import { globalStyles, StyledFileUploader } from "styles";
import { baseTheme } from "theme";
import { debounce, getFormData, renderFormController } from "utils";

function UpdateEventPublicPageForm({
    action,
    onSubmitForm,
    tabValue,
    values,
}) {
    const dispatch = useDispatch();

    const sending = useSelector((state) => state.sendingReducer.show);

    const lastAddedEventPageAction = useSelector((state) => state.eventsReducer.lastAddedEventPageAction);

    const lastUploadedEventBannerImage = useSelector((state) => state.eventsReducer.lastUploadedEventBannerImage);

    const deleted = useSelector((state) => state.eventsReducer.deleted);

    const [uploadEventBannerImageTypeError, setUploadEventBannerImageTypeError] = useState(false);

    const [pageActionsData, setPageActionsData] = useState({
        deletedPageActionId: null,
        errors: {
            labelInput: null,
            urlInput: null,
        },
        list: [],
        values: {
            labelInput: "",
            urlInput: "",
        },
    });

    const [bannersImagesData, setBannersImagesData] = useState({
        deletedBannerImageId: null,
        errors: { linkInput: null },
        list: [],
        values: { linkInput: "" },
    });

    const [switches, setSwitches] = useState({
        enableDownload: false,
        enablePublic: true,
        enableShare: false,
    });

    const formMethods = useForm({
        defaultValues: values,
        mode: "onChange",
    });

    const {
        data: addEventPageActionData,
        error: addEventPageActionError,
        isError: addEventPageActionIsError,
        isLoading: addEventPageActionIsLoading,
        isSuccess: addEventPageActionIsSuccess,
        mutate: addEventPageActionMutate,
    } = useAddEventPageActionData();

    const {
        error: deleteEventPageActionError,
        isError: deleteEventPageActionIsError,
        isLoading: deleteEventPageActionIsLoading,
        isSuccess: deleteEventPageActionIsSuccess,
        mutate: deleteEventPageActionMutate,
    } = useDeleteEventPageActionData();

    const {
        data: uploadEventBannerImageData,
        error: uploadEventBannerImageError,
        isError: uploadEventBannerImageIsError,
        isLoading: uploadEventBannerImageIsLoading,
        isSuccess: uploadEventBannerImageIsSuccess,
        mutate: uploadEventBannerImageMutate,
    } = useUploadEventBannerImageData();

    const {
        error: deleteEventBannerImageError,
        isError: deleteEventBannerImageIsError,
        isLoading: deleteEventBannerImageIsLoading,
        isSuccess: deleteEventBannerImageIsSuccess,
        mutate: deleteEventBannerImageMutate,
    } = useDeleteEventBannerImageData();

    const { linkColor } = globalStyles();

    const { add: addBtnName } = btnsNamesConstants;

    const {
        addEventPageActionMsgs: { pending: addEventPageActionPendingMsg },
        deleteMsgs: { pending: deletePendingMsg },
        fail: failMsg,
        inputsMsgs: {
            invalidUrl: invalidUrlMsg,
            label: labelMsg,
            url: urlMsg,
        },
        success: successMsg,
        uploadEventBannerImageMsgs: {
            disabled: uploadEventBannerImageDisabledMsg,
            pending: uploadEventBannerImagePendingMsg,
        },
    } = messagesConstants;

    const {
        bannerImageFileUploaderHelper: bannerImageFileUploaderInputHelperLabel,
        bannerImageLink: bannerImageLinkInputLabel,
        pageActionLabel: pageActionLabelInputLabel,
        pageActionUrl: pageActionUrlInputLabel,
        pageActionUrlHelper: pageActionUrlInputHelperLabel,
    } = inputsLabelsConstants;

    const { delete: deleteTooltip } = tooltipsConstants;

    const {
        updateEvent: {
            publicPage: {
                bannersImagesTitle: bannersImagesTitleConstant,
                pageActionsTitle: pageActionsTitleConstant,
            },
        },
    } = viewsConstants;

    const { url: urlRegex } = regexData;

    const {
        control,
        formState: { errors },
        setValue,
    } = formMethods;

    const formData = updateEventFormData.forms.publicPage;

    const changePageActionInputHandler = (e, inputName) => {
        const { value } = e.target;

        if (inputName === "label") {
            if (value) {
                setPageActionsData({
                    ...pageActionsData,
                    errors: {
                        ...pageActionsData.errors,
                        labelInput: null,
                    },
                    values: {
                        ...pageActionsData.values,
                        labelInput: value,
                    },
                });
            } else {
                setPageActionsData({
                    ...pageActionsData,
                    errors: {
                        ...pageActionsData.errors,
                        labelInput: labelMsg,
                    },
                    values: {
                        ...pageActionsData.values,
                        labelInput: value,
                    },
                });
            }
        } else if (!value) {
            setPageActionsData({
                ...pageActionsData,
                errors: {
                    ...pageActionsData.errors,
                    urlInput: urlMsg,
                },
                values: {
                    ...pageActionsData.values,
                    urlInput: value,
                },
            });
        } else if (!value.match(urlRegex)) {
            setPageActionsData({
                ...pageActionsData,
                errors: {
                    ...pageActionsData.errors,
                    urlInput: invalidUrlMsg,
                },
                values: {
                    ...pageActionsData.values,
                    urlInput: value,
                },
            });
        } else {
            setPageActionsData({
                ...pageActionsData,
                errors: {
                    ...pageActionsData.errors,
                    urlInput: null,
                },
                values: {
                    ...pageActionsData.values,
                    urlInput: value,
                },
            });
        }
    };

    const changeBannerImageLinkInputHandler = (e) => {
        const { value } = e.target;

        if (!value) {
            setBannersImagesData({
                ...bannersImagesData,
                errors: {
                    ...bannersImagesData.errors,
                    linkInput: urlMsg,
                },
                values: {
                    ...bannersImagesData.values,
                    linkInput: value,
                },
            });
        } else if (!value.match(urlRegex)) {
            setBannersImagesData({
                ...bannersImagesData,
                errors: {
                    ...bannersImagesData.errors,
                    linkInput: invalidUrlMsg,
                },
                values: {
                    ...bannersImagesData.values,
                    linkInput: value,
                },
            });
        } else {
            setBannersImagesData({
                ...bannersImagesData,
                errors: {
                    ...bannersImagesData.errors,
                    linkInput: null,
                },
                values: {
                    ...bannersImagesData.values,
                    linkInput: value,
                },
            });
        }
    };

    const addEventPageActionHandler = () => {
        toast.loading(addEventPageActionPendingMsg);

        addEventPageActionMutate({
            data: {
                actionTitle: pageActionsData.values.labelInput,
                actionUrl: pageActionsData.values.urlInput,
            },
            id: values.publicPageId,
            subApi: "actions",
        });
    };

    const deleteEventPageActionHandler = (id) => {
        toast.loading(deletePendingMsg);

        setPageActionsData({
            ...pageActionsData,
            deletedPageActionId: id,
        });

        deleteEventPageActionMutate(id);
    };

    const uploadEventBannerImageHandler = (file) => {
        setUploadEventBannerImageTypeError(false);

        toast.loading(uploadEventBannerImagePendingMsg);

        uploadEventBannerImageMutate(getFormData({ photo: file }));
    };

    const deleteEventBannerImageHandler = (id) => {
        toast.loading(deletePendingMsg);

        setBannersImagesData({
            ...bannersImagesData,
            deletedBannerImageId: id,
        });

        deleteEventBannerImageMutate(id);
    };

    const changeTextFieldHandler = useCallback( // eslint-disable-line
        debounce(
            (value, requestName) => {
                dispatch({
                    payload: {
                        data: {
                            name: requestName,
                            value,
                        },
                    },
                    type: types.CHANGE_EVENT_UPDATE_FORM_FIELD,
                });
            },
            500,
        ),
        [],
    );

    const changeSwitchHandler = (e, switchName, requestName) => {
        const { checked } = e.target;

        setSwitches({
            ...switches,
            [switchName]: checked,
        });

        setValue(
            switchName,
            checked,
            { shouldDirty: true },
        );

        dispatch({
            payload: {
                data: {
                    name: requestName,
                    value: requestName === "isPrivate" ? !checked : checked,
                },
            },
            type: types.CHANGE_EVENT_UPDATE_FORM_FIELD,
        });
    };

    const changeColorPickerHandler = (e, field, requestName) => {
        const { value } = e.target;

        field.onChange(value);

        dispatch({
            payload: {
                data: {
                    name: requestName,
                    value,
                },
            },
            type: types.CHANGE_EVENT_UPDATE_FORM_FIELD,
        });
    };

    const renderPageActionsAndBannersImagesFields = () => (
        <>
            <Box
                ml={2}
                mt={2}
                sx={{ width: "100%" }}
            >
                <Typography
                    color="primary"
                    component="h4"
                    fontWeight="bold"
                    mb={2}
                >
                    {pageActionsTitleConstant}
                </Typography>
                <Box>
                    <Box
                        display="flex"
                        flexDirection="column"
                    >
                        <Box mb={2}>
                            <TextField
                                error={pageActionsData.errors.labelInput}
                                helperText={pageActionsData.errors.labelInput}
                                label={pageActionLabelInputLabel}
                                type="text-field"
                                variant="outlined"
                                fullWidth
                                onChange={(e) => changePageActionInputHandler(
                                    e,
                                    "label",
                                )}
                            />
                        </Box>
                        <Box>
                            <TextField
                                error={pageActionsData.errors.urlInput}
                                helperText={pageActionsData.errors.urlInput || pageActionUrlInputHelperLabel}
                                label={pageActionUrlInputLabel}
                                type="text-field"
                                variant="outlined"
                                fullWidth
                                onChange={(e) => changePageActionInputHandler(
                                    e,
                                    "url",
                                )}
                            />
                        </Box>
                    </Box>
                    <Box mt={2}>
                        <Button
                            variant="contained"
                            disabled={
                                addEventPageActionIsLoading
                            || sending
                            || pageActionsData.errors.urlInput
                            || pageActionsData.errors.labelInput
                            || !pageActionsData.values.urlInput
                            || !pageActionsData.values.labelInput
                            || pageActionsData?.list?.length === 3
                            }
                            onClick={addEventPageActionHandler}
                        >
                            {addBtnName}
                        </Button>
                    </Box>
                </Box>
                {pageActionsData?.list?.length > 0 && (
                    <Box>
                        <List>
                            {pageActionsData?.list?.map(({
                                actionTitle,
                                actionUrl,
                                id,
                            }) => (
                                <ListItem
                                    key={id}
                                    secondaryAction={(
                                        <Tooltip title={deleteTooltip}>
                                            <IconButton
                                                disabled={deleteEventPageActionIsLoading || sending}
                                                onClick={() => deleteEventPageActionHandler(id)}
                                            >
                                                <DeleteIcon />
                                            </IconButton>
                                        </Tooltip>
                                    )}
                                >
                                    <Link
                                        href={actionUrl}
                                        underline="always"
                                    >
                                        <ListItemText primary={actionTitle} />
                                    </Link>
                                </ListItem>
                            ))}
                        </List>
                    </Box>
                )}
            </Box>
            <Box
                ml={2}
                mt={2}
                width={{
                    md: "580px",
                    xs: "95%",
                }}
            >
                <Typography
                    color="primary"
                    component="h4"
                    fontWeight="bold"
                    mb={2}
                >
                    {bannersImagesTitleConstant}
                </Typography>
                <Box mb={2}>
                    <TextField
                        disabled={bannersImagesData.list.length === 3}
                        error={bannersImagesData.errors.linkInput}
                        helperText={bannersImagesData.errors.linkInput}
                        label={bannerImageLinkInputLabel}
                        type="text-field"
                        variant="outlined"
                        fullWidth
                        onChange={(e) => changeBannerImageLinkInputHandler(e)}
                    />
                </Box>
                <StyledFileUploader error={uploadEventBannerImageError || uploadEventBannerImageTypeError}>
                    <FileUploader
                        disabled={uploadEventBannerImageIsLoading || sending || bannersImagesData.errors.linkInput || !bannersImagesData.values.linkInput || bannersImagesData.list.length === 3}
                        handleChange={(file) => uploadEventBannerImageHandler(file)}
                        name="file"
                        types={["png", "jpeg", "jpg", "webp"]}
                        onTypeError={() => setUploadEventBannerImageTypeError(true)}
                    />
                </StyledFileUploader>
                <Box mt={1}>
                    <Typography
                        color="GrayText"
                        marginLeft={2}
                        variant="span"
                    >
                        {bannerImageFileUploaderInputHelperLabel}
                    </Typography>
                </Box>
                {(bannersImagesData.errors.linkInput || !bannersImagesData.values.linkInput) && bannersImagesData.list.length < 3 && (
                    <Box>
                        <Typography
                            color="error"
                            marginLeft={2}
                            variant="span"
                        >
                            {uploadEventBannerImageDisabledMsg}
                        </Typography>
                    </Box>
                )}
                {bannersImagesData.list.length > 0 && (
                    <Box
                        display="flex"
                        flexWrap="wrap"
                        gap={2}
                    >
                        {bannersImagesData.list.map(({
                            bannerUrl,
                            hyperLink,
                            id,
                        }) => (
                            <Box
                                alignItems="center"
                                display="flex"
                                flexDirection="column"
                                mt={2}
                            >
                                <ImageListItem>
                                    <img
                                        alt="banner_img"
                                        loading="lazy"
                                        src={bannerUrl}
                                        style={{
                                            height: "100px",
                                            marginTop: "15px",
                                            width: "140px",
                                        }}
                                    />
                                    <ImageListItemBar
                                        actionPosition="left"
                                        position="top"
                                        actionIcon={(
                                            <Tooltip title={deleteTooltip}>
                                                <IconButton onClick={() => deleteEventBannerImageHandler(id)}>
                                                    <DeleteIcon style={{ color: baseTheme.palette.grey[100] }} />
                                                </IconButton>
                                            </Tooltip>
                                        )}
                                    />
                                </ImageListItem>
                                {hyperLink && (
                                    <Link
                                        className={[linkColor, "whiteGrey"].join(" ")}
                                        href={hyperLink}
                                        sx={{ textDecorationColor: baseTheme.palette.grey[600] }}
                                        target="_blank"
                                    >
                                        {`${hyperLink?.slice(
                                            0,
                                            10,
                                        )}`}
                                    </Link>
                                )}
                            </Box>
                        ))}
                    </Box>
                )}
            </Box>
        </>
    );

    const renderFormControllerChildren = (
        field,
        label,
        name,
        type,
        requestName,
    ) => {
        switch (type) {
        case "color-picker":
            return (
                <Box
                    display="flex"
                    flexDirection="column"
                >
                    <Typography
                        color="primary"
                        mb={2}
                        variant="caption"
                    >
                        {label}
                    </Typography>
                    <input
                        {...field}
                        type="color"
                        onChange={(e) => {
                            changeColorPickerHandler(
                                e,
                                field,
                                requestName,
                            );
                        }}
                    />
                </Box>
            );
        case "switch":
            return (
                <>
                    <Typography
                        mb={2}
                        variant="caption"
                    >
                        {label}
                    </Typography>
                    <Switch
                        checked={switches[name]}
                        onChange={(e) => {
                            changeSwitchHandler(
                                e,
                                name,
                                requestName,
                            );
                        }}
                    />
                </>
            );
        default:
            return (
                <TextField
                    {...field}
                    error={errors[name]}
                    helperText={errors[name] && errors[name]?.message}
                    label={label}
                    maxRows={8}
                    minRows={4}
                    multiline={type === "text-area"}
                    variant="outlined"
                    fullWidth
                    onInput={(e) => {
                        changeTextFieldHandler(
                            e.target.value,
                            requestName,
                        );
                    }}
                />
            );
        }
    };

    useEffect(() => {
        if (addEventPageActionIsLoading || deleteEventPageActionIsLoading || uploadEventBannerImageIsLoading || deleteEventBannerImageIsLoading) dispatch({ type: types.EVENTS_REQUEST });
    }, [addEventPageActionIsLoading, deleteEventPageActionIsLoading, uploadEventBannerImageIsLoading, deleteEventBannerImageIsLoading]); // eslint-disable-line

    useEffect(() => {
        if (addEventPageActionIsSuccess) {
            dispatch(addEventPageAction(
                addEventPageActionData.data,
                successMsg,
            ));

            toast.dismiss();
        }
    }, [addEventPageActionIsSuccess]); // eslint-disable-line

    useEffect(() => {
        if (deleteEventPageActionIsSuccess) {
            dispatch(deleteEventPageAction(successMsg));

            toast.dismiss();
        }
    }, [deleteEventPageActionIsSuccess]); // eslint-disable-line

    useEffect(() => {
        if (addEventPageActionIsError) {
            dispatch(addEventPageAction(
                null,
                failMsg,
                addEventPageActionError,
            ));

            toast.dismiss();
        }
    }, [addEventPageActionIsError]); // eslint-disable-line

    useEffect(() => {
        if (deleteEventPageActionIsError) {
            dispatch(deleteEventPageAction(
                failMsg,
                deleteEventPageActionError,
            ));

            toast.dismiss();
        }
    }, [deleteEventPageActionIsError]); // eslint-disable-line

    useEffect(() => {
        if (uploadEventBannerImageIsSuccess) {
            dispatch(uploadEventBannerImage(
                uploadEventBannerImageData.data,
                successMsg,
            ));

            toast.dismiss();
        }
    }, [uploadEventBannerImageIsSuccess]); // eslint-disable-line

    useEffect(() => {
        if (deleteEventBannerImageIsSuccess) {
            dispatch(deleteEventBannerImage(successMsg));

            toast.dismiss();
        }
    }, [deleteEventBannerImageIsSuccess]); // eslint-disable-line

    useEffect(() => {
        if (uploadEventBannerImageIsError) {
            dispatch(uploadEventBannerImage(
                null,
                failMsg,
                uploadEventBannerImageError,
            ));

            toast.dismiss();
        }
    }, [uploadEventBannerImageIsError]); // eslint-disable-line

    useEffect(() => {
        if (deleteEventBannerImageIsError) {
            dispatch(deleteEventPageAction(
                failMsg,
                deleteEventBannerImageError,
            ));

            toast.dismiss();
        }
    }, [deleteEventBannerImageIsError]); // eslint-disable-line

    useEffect(() => {
        const clonedEventPageActions = pageActionsData?.list ? [...pageActionsData.list] : [];

        if (Object.keys(lastAddedEventPageAction).length > 0) {
            clonedEventPageActions.push(lastAddedEventPageAction);

            setPageActionsData({
                ...pageActionsData,
                list: clonedEventPageActions,
            });
        }
    }, [lastAddedEventPageAction]); // eslint-disable-line

    useEffect(() => {
        if (Object.keys(lastUploadedEventBannerImage).length > 0) {
            setValue(
                "banners",
                [
                    ...bannersImagesData.list,
                    {
                        bannerUrl: lastUploadedEventBannerImage.url,
                        hyperLink: bannersImagesData.values.linkInput,
                    },
                ],
                { shouldDirty: true },
            );

            dispatch({
                payload: {
                    data: {
                        name: "banners",
                        value: [
                            ...bannersImagesData.list,
                            {
                                bannerUrl: lastUploadedEventBannerImage.url,
                                hyperLink: bannersImagesData.values.linkInput,
                            },
                        ],
                    },
                },
                type: types.CHANGE_EVENT_UPDATE_FORM_FIELD,
            });

            setBannersImagesData({
                ...bannersImagesData,
                list: [
                    ...bannersImagesData.list,
                    {
                        bannerUrl: lastUploadedEventBannerImage.url,
                        hyperLink: bannersImagesData.values.linkInput,
                    },
                ],
            });
        }
    }, [lastUploadedEventBannerImage]); // eslint-disable-line

    useEffect(() => {
        const clonedEventPageActions = pageActionsData?.list ? [...pageActionsData.list] : [];

        const clonedEventBannersImages = bannersImagesData?.list ? [...bannersImagesData.list] : [];

        if (deleted) {
            const filteredEventPageActions = clonedEventPageActions.filter((pageAction) => pageAction.id !== pageActionsData.deletedPageActionId);

            setPageActionsData({
                ...pageActionsData,
                list: filteredEventPageActions,
            });

            if (bannersImagesData.deletedBannerImageId) {
                const filteredEventBannersImages = clonedEventBannersImages.filter((bannerImage) => bannerImage.id !== bannersImagesData.deletedBannerImageId);

                setValue(
                    "banners",
                    filteredEventBannersImages,
                    { shouldDirty: true },
                );

                dispatch({
                    payload: {
                        data: {
                            name: "banners",
                            value: filteredEventBannersImages,
                        },
                    },
                    type: types.CHANGE_EVENT_UPDATE_FORM_FIELD,
                });

                setBannersImagesData({
                    ...bannersImagesData,
                    deletedBannerImageId: null,
                    list: filteredEventBannersImages,
                });
            }
        }
    }, [deleted, bannersImagesData.deletedBannerImageId]); // eslint-disable-line

    useEffect(() => {
        setValue(
            "pageActions",
            pageActionsData.list,
        );
    }, [pageActionsData.list]); // eslint-disable-line

    useEffect(() => {
        Object.keys(values).forEach((key) => {
            setValue(
                key,
                values?.[key],
            );
        });

        setPageActionsData({
            ...pageActionsData,
            list: values.pageActions,
        });

        setSwitches({
            enableDownload: values.enableDownload,
            enablePublic: values.enablePublic,
            enableShare: values.enableShare,
        });
    }, [tabValue]); // eslint-disable-line

    useEffect(() => {
        if (values?.banners?.length > 0) {
            setBannersImagesData({
                ...bannersImagesData,
                list: values.banners,
            });
        }
    }, [values?.banners]); // eslint-disable-line

    return (
        <FormLayout
            action={action}
            formMethods={formMethods}
            otherFields={renderPageActionsAndBannersImagesFields()}
            data={renderFormController(
                control,
                formData,
                renderFormControllerChildren,
            )}
            onSubmitForm={onSubmitForm}
        />
    );
}

export default UpdateEventPublicPageForm;
