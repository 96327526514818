import { inputsLabelsConstants } from "@constants";

const { title: titleInputLabel } = inputsLabelsConstants;

const createEventTemplateFormData = [
    {
        col: {
            md: 6,
            xs: 12,
        },
        label: titleInputLabel,
        name: "title",
        type: "text-field",
    },
];

export default createEventTemplateFormData;
