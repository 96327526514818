const filtersConstants = {
    all: "all",
    archived: "archived",
    asc: "asc",
    desc: "desc",
    email: "email",
    endDate: "endDate",
    phone: "phone",
    search: "search",
    startDate: "startDate",
    unarchived: "unarchived",
};

export default filtersConstants;
