import { messagesConstants } from "@constants";
import * as yup from "yup";

const {
    text: textMsg,
    title: titleMsg,
} = messagesConstants.inputsMsgs;

const updateEventSocialSharingFormSchema = yup.object({
    facebookText: yup.string().required(textMsg),
    facebookTitle: yup.string().required(titleMsg),
    twitterText: yup.string().required(textMsg),
});

export default updateEventSocialSharingFormSchema;
