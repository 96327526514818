import { actionsConstants } from "@constants";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import ArchiveIcon from "@mui/icons-material/Archive";
import ArrowCircleDownIcon from "@mui/icons-material/ArrowCircleDown";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import CheckIcon from "@mui/icons-material/Check";
import CropOriginalIcon from "@mui/icons-material/CropOriginal";
import DangerousIcon from "@mui/icons-material/Dangerous";
import DeleteIcon from "@mui/icons-material/Delete";
import InventoryIcon from "@mui/icons-material/Inventory";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import PeopleIcon from "@mui/icons-material/People";
import PlaylistRemoveIcon from "@mui/icons-material/PlaylistRemove";
import ReplyIcon from "@mui/icons-material/Reply";
import SendIcon from "@mui/icons-material/Send";
import SlideshowIcon from "@mui/icons-material/Slideshow";
import UnarchiveIcon from "@mui/icons-material/Unarchive";
import VisibilityIcon from "@mui/icons-material/Visibility";

const {
    activate: {
        action: activateAction,
        key: activateKey,
    },
    activateAndDeactivate: { key: activateAndDeactivateKey },
    addAndRemoveSlideshow: { key: addAndRemoveSlideshowKey },
    addToSlideshow: {
        action: addToSlideshowAction,
        key: addToSlideshowKey,
    },
    archive: {
        action: archiveAction,
        key: archiveKey,
    },
    archiveAndUnarchive: { key: archiveAndUnarchiveKey },
    asc: {
        action: ascAction,
        key: ascKey,
    },
    createTemplate: {
        action: createTemplateAction,
        key: createTemplateKey,
    },
    createZipFolder: {
        action: createZipFolderAction,
        key: createZipFolderKey,
    },
    deactivate: {
        action: deactivateAction,
        key: deactivateKey,
    },
    delete: {
        action: deleteAction,
        key: deleteKey,
    },
    desc: {
        action: descAction,
        key: descKey,
    },
    downloadOriginal: {
        action: downloadOriginalAction,
        key: downloadOriginalKey,
    },
    downloadWithOverlay: {
        action: downloadWithOverlayAction,
        key: downloadWithOverlayKey,
    },
    eventGuestPhotos: {
        action: eventGuestPhotosAction,
        key: eventGuestPhotosKey,
    },
    eventGuests: {
        action: eventGuestsAction,
        key: eventGuestsKey,
    },
    eventPhotos: {
        action: eventPhotosAction,
        key: eventPhotosKey,
    },
    eventSlideshow: {
        action: eventSlideshowAction,
        key: eventSlideshowKey,
    },
    removeFromSlideshow: {
        action: removeFromSlideshowAction,
        key: removeFromSlideshowKey,
    },
    resend: {
        action: resendAction,
        key: resendKey,
    },
    resetPassword: {
        action: resetPasswordAction,
        key: resetPasswordKey,
    },
    shareGallery: {
        action: shareGalleryAction,
        key: shareGalleryKey,
    },
    unarchive: {
        action: unarchiveAction,
        key: unarchiveKey,
    },
    update: {
        action: updateAction,
        key: updateKey,
    },
    viewDropboxOverlay: {
        action: viewDropboxOverlayAction,
        key: viewDropboxOverlayKey,
    },
} = actionsConstants;

const actionsData = {
    admins: [
        {
            Icon: ModeEditIcon,
            action: updateAction,
            key: updateKey,
        },
        {
            Icon: SendIcon,
            action: resetPasswordAction,
            key: resetPasswordKey,
        },
        {
            activate: {
                Icon: CheckIcon,
                action: activateAction,
                key: activateKey,
            },
            deactivate: {
                Icon: DangerousIcon,
                action: deactivateAction,
                key: deactivateKey,
            },
            key: activateAndDeactivateKey,
        },
    ],
    eventGuestPhotos: {
        photo: [
            {
                Icon: ArrowDownwardIcon,
                action: downloadOriginalAction,
                key: downloadOriginalKey,
            },
            {
                Icon: ArrowCircleDownIcon,
                action: downloadWithOverlayAction,
                key: downloadWithOverlayKey,
            },
            {
                Icon: VisibilityIcon,
                action: viewDropboxOverlayAction,
                key: viewDropboxOverlayKey,
            },
            {
                Icon: ReplyIcon,
                action: resendAction,
                key: resendKey,
            },
            {
                addToSlideshow: {
                    Icon: AddPhotoAlternateIcon,
                    action: addToSlideshowAction,
                    key: addToSlideshowKey,
                },
                key: addAndRemoveSlideshowKey,
                removeFromSlideshow: {
                    Icon: PlaylistRemoveIcon,
                    action: removeFromSlideshowAction,
                    key: removeFromSlideshowKey,
                },
            },
            {
                Icon: DeleteIcon,
                action: deleteAction,
                key: deleteKey,
            },
        ],
    },
    eventGuests: [
        {
            Icon: CropOriginalIcon,
            action: eventGuestPhotosAction,
            key: eventGuestPhotosKey,
        },
    ],
    eventPhotos: {
        gallery: [
            {
                Icon: AddCircleIcon,
                action: createZipFolderAction,
                key: createZipFolderKey,
            },
            {
                Icon: SendIcon,
                action: shareGalleryAction,
                key: shareGalleryKey,
            },
        ],
        photo: [
            {
                Icon: ArrowDownwardIcon,
                action: downloadOriginalAction,
                key: downloadOriginalKey,
            },
            {
                Icon: ArrowCircleDownIcon,
                action: downloadWithOverlayAction,
                key: downloadWithOverlayKey,
            },
            {
                Icon: VisibilityIcon,
                action: viewDropboxOverlayAction,
                key: viewDropboxOverlayKey,
            },
            {
                Icon: ReplyIcon,
                action: resendAction,
                key: resendKey,
            },
            {
                addToSlideshow: {
                    Icon: AddPhotoAlternateIcon,
                    action: addToSlideshowAction,
                    key: addToSlideshowKey,
                },
                key: addAndRemoveSlideshowKey,
                removeFromSlideshow: {
                    Icon: PlaylistRemoveIcon,
                    action: removeFromSlideshowAction,
                    key: removeFromSlideshowKey,
                },
            },
            {
                Icon: DeleteIcon,
                action: deleteAction,
                key: deleteKey,
            },
        ],
    },
    eventSlideshow: {
        photo: [
            {
                Icon: ArrowDownwardIcon,
                action: downloadOriginalAction,
                key: downloadOriginalKey,
            },
            {
                Icon: ArrowCircleDownIcon,
                action: downloadWithOverlayAction,
                key: downloadWithOverlayKey,
            },
            {
                Icon: VisibilityIcon,
                action: viewDropboxOverlayAction,
                key: viewDropboxOverlayKey,
            },
            {
                Icon: ReplyIcon,
                action: resendAction,
                key: resendKey,
            },
            {
                addToSlideshow: {
                    Icon: AddPhotoAlternateIcon,
                    action: addToSlideshowAction,
                    key: addToSlideshowKey,
                },
                key: addAndRemoveSlideshowKey,
                removeFromSlideshow: {
                    Icon: PlaylistRemoveIcon,
                    action: removeFromSlideshowAction,
                    key: removeFromSlideshowKey,
                },
            },
            {
                Icon: DeleteIcon,
                action: deleteAction,
                key: deleteKey,
            },
        ],
        sort: [
            {
                Icon: ArrowUpwardIcon,
                action: ascAction,
                key: ascKey,
            },
            {
                Icon: ArrowDownwardIcon,
                action: descAction,
                key: descKey,
            },
        ],
    },
    eventTemplates: [
        {
            Icon: ModeEditIcon,
            action: updateAction,
            key: updateKey,
        },
        {
            Icon: DeleteIcon,
            action: deleteAction,
            key: deleteKey,
        },
    ],
    events: [
        {
            Icon: ModeEditIcon,
            action: updateAction,
            key: updateKey,
        },
        {
            Icon: DeleteIcon,
            action: deleteAction,
            key: deleteKey,
        },
        {
            Icon: PeopleIcon,
            action: eventGuestsAction,
            key: eventGuestsKey,
        },
        {
            Icon: CropOriginalIcon,
            action: eventPhotosAction,
            key: eventPhotosKey,
        },
        {
            Icon: SlideshowIcon,
            action: eventSlideshowAction,
            key: eventSlideshowKey,
        },
        {
            archive: {
                Icon: ArchiveIcon,
                action: archiveAction,
                key: archiveKey,
            },
            key: archiveAndUnarchiveKey,
            unarchive: {
                Icon: UnarchiveIcon,
                action: unarchiveAction,
                key: unarchiveKey,
            },
        },
        {
            Icon: InventoryIcon,
            action: createTemplateAction,
            key: createTemplateKey,
        },
    ],
    photographers: [
        {
            Icon: ModeEditIcon,
            action: updateAction,
            key: updateKey,
        },
        {
            Icon: SendIcon,
            action: resetPasswordAction,
            key: resetPasswordKey,
        },
        {
            activate: {
                Icon: CheckIcon,
                action: activateAction,
                key: activateKey,
            },
            deactivate: {
                Icon: DangerousIcon,
                action: deactivateAction,
                key: deactivateKey,
            },
            key: activateAndDeactivateKey,
        },
    ],
};

export default actionsData;
