import { messagesConstants } from "@constants";
import * as yup from "yup";

const {
    action: actionMsg,
    name: nameMsg,
    subject: subjectMsg,
    text: textMsg,
} = messagesConstants.inputsMsgs;

const updateEventShareDetailsFormSchema = yup.object({
    emailBottomBodyText: yup.string().required(textMsg),
    emailCallToAction: yup.string().required(actionMsg),
    emailFromName: yup.string().required(nameMsg),
    emailSubject: yup.string().required(subjectMsg),
    emailTopBodyText: yup.string().required(textMsg),
    smsText: yup.string().required(textMsg),
});

export default updateEventShareDetailsFormSchema;
