import { btnsNamesConstants } from "@constants";
import {
    Box,
    Button,
    Modal as MuiModal,
    Stack,
    Typography,
} from "@mui/material";
import { useSelector } from "react-redux";
import { StyledModal } from "styles";
import { baseTheme } from "theme";

function Modal({
    btnColor,
    btnName,
    content,
    onClickSecondBtnAction,
    onCloseModal,
    onSubmitModalAction,
    secondBtnColor,
    secondBtnName,
    showModal,
    title,
    withSecondBtn,
}) {
    const theme = useSelector((state) => state.themeReducer.theme);

    const { cancel: cancelBtnName } = btnsNamesConstants;

    return (
        <Box>
            <MuiModal
                open={showModal}
                onClose={onCloseModal}
            >
                <StyledModal>
                    <Typography
                        color={baseTheme.palette.grey[theme === "dark" ? 300 : 901]}
                        component="h4"
                    >
                        {title}
                    </Typography>
                    {content && <Box mb={2}>{content}</Box>}
                    <Stack
                        direction="row"
                        flexWrap="wrap"
                        gap={2}
                    >
                        {btnName && (
                            <Button
                                color={btnColor}
                                size="large"
                                variant="contained"
                                onClick={onSubmitModalAction}
                            >
                                {btnName}
                            </Button>
                        )}
                        {withSecondBtn && (
                            <Button
                                color={secondBtnColor}
                                size="large"
                                variant="contained"
                                onClick={onClickSecondBtnAction}
                            >
                                {secondBtnName}
                            </Button>
                        )}
                        <Button
                            color="primary"
                            size="large"
                            variant="outlined"
                            onClick={onCloseModal}
                        >
                            {cancelBtnName}
                        </Button>
                    </Stack>
                </StyledModal>
            </MuiModal>
        </Box>
    );
}

export default Modal;
