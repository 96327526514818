import { Box } from "@mui/material";
import { styled } from "@mui/styles";

const StyledMedia = styled(Box)(({ theme }) => ({
    "& img, video": {
        borderRadius: theme.shape.borderRadius,
        boxShadow: theme.shape.boxShadow,
    },
    "& video": {
        maxHeight: "300px",
    },
}));

export default StyledMedia;
