import * as types from "redux/types";

const initialState = {
    added: false,
    addedToSlideshow: false,
    archived: false,
    assigned: false,
    created: false,
    data: {
        data: [],
        noData: false,
        pagination: {},
    },
    deleted: false,
    details: {},
    error: null,
    eventTemplateDetails: {},
    exportedEventGuestsListFileUrl: null,
    formChanges: {},
    formValidation: true,
    generated: false,
    guestPhotos: {
        data: [],
        noData: false,
        pagination: {},
    },
    guests: {
        data: [],
        noData: false,
        pagination: {},
    },
    lastAddedEventDomain: {},
    lastAddedEventPageAction: {},
    lastAssigned: {},
    lastCreated: {},
    lastCreatedEventTemplate: {},
    lastSendedEventPhoto: {},
    lastUpdated: {},
    lastUpdatedEventTemplate: {},
    lastUploadedEventBannerImage: {},
    lastUploadedEventOverlay: {},
    photos: {
        data: [],
        noData: false,
        pagination: {},
    },
    removedFromSlideshow: false,
    resended: false,
    setDefaultOverlay: false,
    shared: false,
    slideshow: {
        data: [],
        noData: false,
    },
    templates: {
        data: [],
        noData: false,
    },
    unarchived: false,
    updated: false,
    uploaded: false,
};

const eventsReducer = (state = initialState, action) => {
    const {
        payload,
        type,
    } = action;

    const {
        data,
        error,
    } = payload || {};

    switch (type) {
    case types.GET_EVENTS_SUCCESS:
        return {
            ...state,
            data,
        };

    case types.GET_EVENT_SUCCESS:
        return {
            ...state,
            details: data,
        };

    case types.CREATE_EVENT_SUCCESS:
        return {
            ...state,
            created: true,
            lastCreated: data,
        };

    case types.UPDATE_EVENT_SUCCESS:
        return {
            ...state,
            lastUpdated: data,
            updated: true,
        };

    case types.DELETE_EVENT_SUCCESS:
    case types.DELETE_EVENT_OVERLAY_SUCCESS:
    case types.DELETE_EVENT_DOMAIN_SUCCESS:
    case types.DELETE_EVENT_PAGE_ACTION_SUCCESS:
    case types.DELETE_EVENT_BANNER_IMAGE_SUCCESS:
    case types.DELETE_EVENT_PHOTO_SUCCESS:
    case types.DELETE_EVENT_TEMPLATE_SUCCESS:
        return {
            ...state,
            deleted: true,
        };

    case types.ARCHIVE_EVENT_SUCCESS:
        return {
            ...state,
            archived: true,
        };

    case types.UNARCHIVE_EVENT_SUCCESS:
        return {
            ...state,
            unarchived: true,
        };

    case types.ASSIGN_EVENT_SUCCESS:
        return {
            ...state,
            assigned: true,
            lastAssigned: data,
        };

    case types.UPLOAD_EVENT_OVERLAY_SUCCESS:
        return {
            ...state,
            lastUploadedEventOverlay: data,
            uploaded: true,
        };

    case types.SET_DEFAULT_EVENT_OVERLAY_SUCCESS:
        return {
            ...state,
            setDefaultOverlay: true,
        };

    case types.ADD_EVENT_DOMAIN_SUCCESS:
        return {
            ...state,
            added: true,
            lastAddedEventDomain: data,
        };

    case types.ADD_EVENT_PAGE_ACTION_SUCCESS:
        return {
            ...state,
            added: true,
            lastAddedEventPageAction: data,
        };

    case types.UPLOAD_EVENT_BANNER_IMAGE_SUCCESS:
        return {
            ...state,
            lastUploadedEventBannerImage: data,
            uploaded: true,
        };

    case types.CHANGE_EVENT_UPDATE_FORM_FIELD:
        return {
            ...state,
            formChanges: data,
        };

    case types.CHANGE_EVENT_UPDATE_FORM_VALIDATION:
        return {
            ...state,
            formChanges: {},
            formValidation: data,
        };

    case types.GET_EVENT_GUESTS_SUCCESS:
        return {
            ...state,
            guests: data,
        };

    case types.EXPORT_EVENT_GUESTS_SUCCESS:
        return {
            ...state,
            exported: true,
            exportedEventGuestsListFileUrl: data,
        };

    case types.GET_EVENT_PHOTOS_SUCCESS:
        return {
            ...state,
            photos: data,
        };

    case types.RESEND_EVENT_PHOTO_SUCCESS:
        return {
            ...state,
            lastSendedEventPhoto: data,
            resended: true,
        };

    case types.SHARE_EVENT_GALLERY_SUCCESS:
        return {
            ...state,
            shared: true,
        };

    case types.GENERATE_EVENT_ARCHIVE_FILE_SUCCESS:
        return {
            ...state,
            generated: true,
        };

    case types.GET_EVENT_GUEST_PHOTOS_SUCCESS:
        return {
            ...state,
            guestPhotos: data,
        };

    case types.GET_EVENT_SLIDESHOW_SUCCESS:
        return {
            ...state,
            slideshow: data,
        };

    case types.ADD_EVENT_PHOTO_TO_SLIDESHOW_SUCCESS:
        return {
            ...state,
            addedToSlideshow: true,
        };

    case types.REMOVE_EVENT_PHOTO_FROM_SLIDESHOW_SUCCESS:
        return {
            ...state,
            removedFromSlideshow: true,
        };

    case types.GET_EVENT_TEMPLATES_SUCCESS:
        return {
            ...state,
            templates: data,
        };

    case types.GET_EVENT_TEMPLATE_SUCCESS:
        return {
            ...state,
            eventTemplateDetails: data,
        };

    case types.CREATE_EVENT_TEMPLATE_SUCCESS:
        return {
            ...state,
            created: true,
            lastCreatedEventTemplate: data,
        };

    case types.UPDATE_EVENT_TEMPLATE_SUCCESS:
        return {
            ...state,
            lastUpdatedEventTemplate: data,
            updated: true,
        };

    case types.EVENTS_REQUEST:
        return {
            ...state,
            added: false,
            addedToSlideshow: false,
            archived: false,
            assigned: false,
            created: false,
            deleted: false,
            error: null,
            exported: false,
            generated: false,
            removedFromSlideshow: false,
            resended: false,
            setDefaultOverlay: false,
            shared: false,
            unarchived: false,
            updated: false,
            uploaded: false,
        };

    case types.EVENTS_FAIL:
        return {
            ...state,
            error,
        };

    default: return state;
    }
};

export default eventsReducer;
