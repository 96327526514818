import AppBar from "@mui/material/AppBar";
import { styled } from "@mui/styles";

const sidebarWidth = 240;

const StyledNavbar = styled(
    AppBar,
    { shouldForwardProp: (prop) => prop !== "open" },
)(({
    open,
    theme,
}) => ({
    transition: theme.transitions.create(
        ["width", "margin"],
        {
            duration: theme.transitions.duration.leavingScreen,
            easing: theme.transitions.easing.sharp,
        },
    ),
    zIndex: theme.zIndex.drawer + 1,
    ...open && {
        marginLeft: sidebarWidth,
        transition: theme.transitions.create(
            ["width", "margin"],
            {
                duration: theme.transitions.duration.enteringScreen,
                easing: theme.transitions.easing.sharp,
            },
        ),
        width: `calc(100% - ${sidebarWidth}px)`,
    },
    "& .search-input": {
        "& .MuiInputBase-root": {
            border: "1px solid #fff",
            color: "#fff",
            outline: "none",
        },
        "& input": {
            "@media (max-width: 768px)": {
                width: 130,
            },
            paddingBlock: "8px",
            width: 300,
        },
        width: "100%",
    },
}));

export default StyledNavbar;
