import { Box } from "@mui/material";
import { Loader } from "atoms";
import { routesUrlsData } from "data";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { logout } from "redux/actions";
import { getCookie } from "utils";

function ProtectedRoute({ children }) {
    const dispatch = useDispatch();

    const loading = useSelector((state) => state.authReducer.loader);

    const location = useLocation();

    const {
        events: { url: eventsRouteUrl },
    } = routesUrlsData;

    useEffect(() => {
        if (!getCookie("user") || !getCookie("token")) dispatch(logout());
        else {
            return (
                <Navigate
                    state={{ from: location }}
                    to={eventsRouteUrl}
                    replace
                />
            );
        }
    }, [getCookie("user"), getCookie("token")]); // eslint-disable-line

    if (loading) return <Box height="100vh"><Loader /></Box>;
    return children || <Outlet />;
}

export default ProtectedRoute;
