import { tooltipsConstants } from "@constants";
import AddIcon from "@mui/icons-material/Add";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import {
    Button,
    Grid,
    IconButton,
    Tooltip,
    Typography,
} from "@mui/material";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { StyledHeader } from "styles";

function Header({
    backRouteUrl,
    createBtnName,
    createRouteUrl,
    hasBackBtn,
    hasCreateBtn,
    hasHeaderBtn,
    headerBtnData,
    title,
}) {
    const navigate = useNavigate();

    const location = useLocation();

    const { back: backTooltip } = tooltipsConstants;

    const clickBackBtnHandler = () => {
        if (window.history?.state && window.history?.state.idx > 0) navigate(-1); // eslint-disable-line
        else if (location.state?.from) navigate(location.state?.from);
        else navigate(backRouteUrl);
    };

    return (
        <StyledHeader>
            <Grid
                alignItems="center"
                justifyContent="space-between"
                spacing={2}
                container
            >
                <Grid
                    sm={!hasCreateBtn && !hasHeaderBtn ? 12 : 8}
                    xs={12}
                    item
                >
                    <Grid
                        alignItems="center"
                        flexWrap="nowrap"
                        container
                    >
                        {hasBackBtn && (
                            <Tooltip title={backTooltip}>
                                <IconButton
                                    color="inherit"
                                    size="large"
                                    onClick={clickBackBtnHandler}
                                >
                                    <ArrowBackIosIcon />
                                </IconButton>
                            </Tooltip>
                        )}
                        <Typography
                            color="inherit"
                            component="h1"
                            variant="h4"
                        >
                            {title}
                        </Typography>
                    </Grid>
                </Grid>
                {hasCreateBtn && (
                    <Grid
                        sm={4}
                        xs={12}
                        item
                    >
                        <Grid
                            gap={2}
                            justifyContent="flex-end"
                            flexDirection={{
                                md: "row",
                                xs: "column",
                            }}
                            container
                        >
                            <Button
                                component={Link}
                                size="large"
                                startIcon={<AddIcon />}
                                state={{ from: location.pathname + location.search }}
                                to={createRouteUrl}
                                variant="outlined"
                            >
                                {createBtnName}
                            </Button>
                        </Grid>
                    </Grid>
                )}
                {hasHeaderBtn && (
                    <Grid
                        sm={4}
                        xs={12}
                        item
                    >
                        <Grid
                            gap={2}
                            justifyContent="flex-end"
                            flexDirection={{
                                md: "row",
                                xs: "column",
                            }}
                            container
                        >
                            <Button
                                component={headerBtnData?.component ? headerBtnData?.component : Button}
                                size="large"
                                startIcon={headerBtnData?.icon}
                                state={{ from: location.pathname + location.search }}
                                to={headerBtnData?.action}
                                variant="outlined"
                            >
                                {headerBtnData?.text}
                            </Button>
                        </Grid>
                    </Grid>
                )}
            </Grid>
        </StyledHeader>
    );
}

export default Header;
