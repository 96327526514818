import {
    btnsNamesConstants,
    inputsLabelsConstants,
    messagesConstants,
    tooltipsConstants,
    viewsConstants,
} from "@constants";
import { yupResolver } from "@hookform/resolvers/yup";
import DeleteIcon from "@mui/icons-material/Delete";
import {
    Box,
    Button,
    FormControl,
    IconButton,
    InputLabel,
    List,
    ListItem,
    ListItemText,
    MenuItem,
    Select,
    TextField,
    Tooltip,
    Typography,
} from "@mui/material";
import { regexData, updateEventFormData } from "data";
import { useAddEventDomainData, useDeleteEventDomainData } from "hooks";
import { FormLayout } from "layouts";
import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { addEventDomain, deleteEventDomain } from "redux/actions";
import * as types from "redux/types";
import { updateEventShareDetailsFormSchema } from "schemas";
import { debounce, renderFormController } from "utils";

function UpdateEventShareDetailsForm({
    action,
    onSubmitForm,
    tabValue,
    values,
}) {
    const dispatch = useDispatch();

    const sending = useSelector((state) => state.sendingReducer.show);

    const lastAddedEventDomain = useSelector((state) => state.eventsReducer.lastAddedEventDomain);

    const deleted = useSelector((state) => state.eventsReducer.deleted);

    const [domainData, setDomainData] = useState({
        deletedDomainId: null,
        error: null,
        list: [],
        value: "",
    });

    const formMethods = useForm({
        defaultValues: values,
        mode: "onChange",
        resolver: yupResolver(updateEventShareDetailsFormSchema),
    });

    const {
        data: addEventDomainData,
        error: addEventDomainError,
        isError: addEventDomainIsError,
        isLoading: addEventDomainIsLoading,
        isSuccess: addEventDomainIsSuccess,
        mutate: addEventDomainMutate,
    } = useAddEventDomainData();

    const {
        error: deleteEventDomainError,
        isError: deleteEventDomainIsError,
        isLoading: deleteEventDomainIsLoading,
        isSuccess: deleteEventDomainIsSuccess,
        mutate: deleteEventDomainMutate,
    } = useDeleteEventDomainData();

    const { add: addBtnName } = btnsNamesConstants;

    const {
        addEventDomainMsgs: { pending: addEventDomainPendingMsg },
        deleteMsgs: { pending: deletePendingMsg },
        fail: failMsg,
        inputsMsgs: {
            domain: domainMsg,
            invalidDomain: invalidDomainMsg,
        },
        success: successMsg,
    } = messagesConstants;

    const {
        domain: domainInputLabel,
        domainHelper: domainInputHelperLabel,
        twillio: twillioInputLabel,
    } = inputsLabelsConstants;

    const { delete: deleteTooltip } = tooltipsConstants;

    const {
        updateEvent: {
            shareDetails: { domainTitle: domainTitleConstant },
        },
    } = viewsConstants;

    const { domain: domainRegex } = regexData;

    const {
        control,
        formState: { errors },
        setValue,
    } = formMethods;

    const formData = updateEventFormData.forms.shareDetails;

    const changeDomainInputHandler = (e) => {
        const { value } = e.target;

        if (!value) {
            setDomainData({
                ...domainData,
                error: domainMsg,
                value,
            });
        } else if (!value.match(domainRegex)) {
            setDomainData({
                ...domainData,
                error: invalidDomainMsg,
                value,
            });
        } else {
            setDomainData({
                ...domainData,
                error: null,
                value,
            });
        }
    };

    const addEventDomainHandler = () => {
        toast.loading(addEventDomainPendingMsg);

        addEventDomainMutate({
            data: { domain: domainData.value },
            id: values.eventSettingsId,
            subApi: "domains",
        });
    };

    const deleteEventDomainHandler = (id) => {
        toast.loading(deletePendingMsg);

        setDomainData({
            ...domainData,
            deletedDomainId: id,
        });

        deleteEventDomainMutate(id);
    };

    const changeTextFieldHandler = useCallback( // eslint-disable-line
        debounce(
            (value, requestName) => {
                dispatch({
                    payload: {
                        data: {
                            name: requestName,
                            value,
                        },
                    },
                    type: types.CHANGE_EVENT_UPDATE_FORM_FIELD,
                });
            },
            500,
        ),
        [],
    );

    const changeSmsProviderFieldHandler = (e, field, requestName) => {
        const { value } = e.target;

        field.onChange(value);

        dispatch({
            payload: {
                data: {
                    name: requestName,
                    value: {
                        id: "1",
                        name: value,
                    },
                },
            },
            type: types.CHANGE_EVENT_UPDATE_FORM_FIELD,
        });
    };

    const renderDomainField = () => (
        <Box
            ml={2}
            mt={2}
            sx={{ width: "100%" }}
        >
            <Typography
                color="primary"
                component="h4"
                fontWeight="bold"
                mb={2}
            >
                {domainTitleConstant}
            </Typography>
            <Box>
                <Box>
                    <TextField
                        error={domainData.error}
                        helperText={domainData.error || domainInputHelperLabel}
                        label={domainInputLabel}
                        type="text"
                        variant="outlined"
                        fullWidth
                        onChange={changeDomainInputHandler}
                    />
                </Box>
                <Box mt={2}>
                    <Button
                        disabled={addEventDomainIsLoading || sending || domainData.error}
                        variant="contained"
                        onClick={addEventDomainHandler}
                    >
                        {addBtnName}
                    </Button>
                </Box>
            </Box>
            {domainData?.list?.length > 0 && (
                <Box>
                    <List>
                        {domainData?.list?.map(({
                            domain,
                            id,
                        }) => (
                            <ListItem
                                key={id}
                                secondaryAction={(
                                    <Tooltip title={deleteTooltip}>
                                        <IconButton
                                            disabled={deleteEventDomainIsLoading || sending}
                                            onClick={() => deleteEventDomainHandler(id)}
                                        >
                                            <DeleteIcon />
                                        </IconButton>
                                    </Tooltip>
                                )}
                            >
                                <ListItemText primary={domain} />
                            </ListItem>
                        ))}
                    </List>
                </Box>
            )}
        </Box>
    );

    const renderFormControllerChildren = (
        field,
        label,
        name,
        type,
        requestName,
    ) => {
        switch (type) {
        case "select":
            return (
                <FormControl fullWidth>
                    <InputLabel>{label}</InputLabel>
                    <Select
                        {...field}
                        label={label}
                        value={twillioInputLabel.toUpperCase()}
                        onChange={(e) => {
                            changeSmsProviderFieldHandler(
                                e,
                                field,
                                requestName,
                            );
                        }}
                    >
                        <MenuItem value={twillioInputLabel.toUpperCase()}>{twillioInputLabel}</MenuItem>
                    </Select>
                </FormControl>
            );
        default:
            return (
                <TextField
                    {...field}
                    error={errors[name]}
                    helperText={errors[name] && errors[name]?.message}
                    label={label}
                    maxRows={8}
                    minRows={4}
                    multiline={type === "text-area"}
                    variant="outlined"
                    fullWidth
                    onInput={(e) => {
                        changeTextFieldHandler(
                            e.target.value,
                            requestName,
                        );
                    }}
                />
            );
        }
    };

    useEffect(() => {
        if (addEventDomainIsLoading || deleteEventDomainIsLoading) dispatch({ type: types.EVENTS_REQUEST });
    }, [addEventDomainIsLoading, deleteEventDomainIsLoading]); // eslint-disable-line

    useEffect(() => {
        if (addEventDomainIsSuccess) {
            dispatch(addEventDomain(
                addEventDomainData.data,
                successMsg,
            ));

            toast.dismiss();
        }
    }, [addEventDomainIsSuccess]); // eslint-disable-line

    useEffect(() => {
        if (deleteEventDomainIsSuccess) {
            dispatch(deleteEventDomain(successMsg));

            toast.dismiss();
        }
    }, [deleteEventDomainIsSuccess]); // eslint-disable-line

    useEffect(() => {
        if (addEventDomainIsError) {
            dispatch(addEventDomain(
                null,
                failMsg,
                addEventDomainError,
            ));

            toast.dismiss();
        }
    }, [addEventDomainIsError]); // eslint-disable-line

    useEffect(() => {
        if (deleteEventDomainIsError) {
            dispatch(deleteEventDomain(
                failMsg,
                deleteEventDomainError,
            ));

            toast.dismiss();
        }
    }, [deleteEventDomainIsError]); // eslint-disable-line

    useEffect(() => {
        const clonedEventDomains = domainData?.list ? [...domainData.list] : [];

        if (Object.keys(lastAddedEventDomain).length > 0) {
            clonedEventDomains.push(lastAddedEventDomain);

            setDomainData({
                ...domainData,
                list: clonedEventDomains,
            });
        }
    }, [lastAddedEventDomain]); // eslint-disable-line

    useEffect(() => {
        const clonedEventDomains = domainData?.list ? [...domainData.list] : [];

        if (deleted) {
            const filteredEventDomains = clonedEventDomains.filter((domain) => domain.id !== domainData.deletedDomainId);

            setDomainData({
                ...domainData,
                list: filteredEventDomains,
            });
        }
    }, [deleted]); // eslint-disable-line

    useEffect(() => {
        setValue(
            "domains",
            domainData.list,
        );
    }, [domainData.list]); // eslint-disable-line

    useEffect(() => {
        Object.keys(values).forEach((key) => {
            setValue(
                key,
                values?.[key],
            );
        });

        setDomainData({
            ...domainData,
            list: values.domains,
        });
    }, [tabValue]); // eslint-disable-line

    return (
        <FormLayout
            action={action}
            formMethods={formMethods}
            otherFields={renderDomainField()}
            data={renderFormController(
                control,
                formData,
                renderFormControllerChildren,
            )}
            onSubmitForm={onSubmitForm}
        />
    );
}

export default UpdateEventShareDetailsForm;
