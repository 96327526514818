import { Grid } from "@mui/material";
import { ThemeSwitcher } from "atoms";
import { Footer } from "components";

function AuthLayout({ children }) {
    return (
        <>
            <ThemeSwitcher />
            <Grid
                alignItems="center"
                flexDirection="column"
                height="100vh"
                container
            >
                {children}
            </Grid>
            <Footer />
        </>
    );
}

export default AuthLayout;
