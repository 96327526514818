import { Box } from "@mui/material";
import { styled } from "@mui/styles";

export const StyledTable = styled(Box)(({ theme }) => ({
    "& .MuiPaper-root": { boxShadow: "none" },
    "& .MuiTableHead-root": {
        background: theme.palette.grey[theme.palette.mode === "dark" ? 900 : 200],
        borderRadius: theme.shape.borderRadius,
        fontWeight: "bolder",
    },
    "& .MuiTableRow-root": {
        "& .MuiTableCell-body": {
            "& a": {
                display: "block",
                height: "100%",
                width: "100%",
            },
            "&:first-child :first-child": { fontWeight: "bold" },
            borderBottom: `1px solid ${theme.palette.grey[300]}`,
            fontSize: "14px",
            padding: "8px 16px",
        },
        "& .MuiTableCell-head": {
            color: theme.palette.grey[600],
            fontWeight: "bold",
        },
        "& .MuiTypography-root": {
            "& .MuiTypography-caption": {
                color: theme.palette.grey[600],
                fontSize: "12px",
                fontWeight: "lighter",
                marginInline: "8px",
                textDecoration: "underline",
            },
        },
    },
    borderRadius: theme.shape.borderRadius,
}));

export const StyledTablePagination = styled(Box)({
    "& .MuiInputBase-root": { marginLeft: "8px" },
});
