const globalConstants = {
    by: "by: ",
    copyrights: "Powered by Extreme Solution",
    eventGuestsListFileName: "EventGuestsList.xlsx",
    from: "From",
    hi: "Hi,",
    inProgress: "IN PROGRESS",
    maxRowsPerPage: "Max Rows Per Page",
    next: "Next",
    noData: "No Data to Show",
    ownerEmail: "support@selfieXmedia.com",
    photoflareDashboard: "Photoflare - Dashboard",
    previous: " Previous",
    searchEvents: "Search Events",
    version: "v2.0",
    you: " (YOU)",
};

export default globalConstants;
