import { inputsLabelsConstants, updateEventTabsConstants } from "@constants";
import {
    UpdateEventConsentForm,
    UpdateEventDetailsForm,
    UpdateEventPublicPageForm,
    UpdateEventShareDetailsForm,
    UpdateEventSocialSharingForm,
} from "components";

const {
    aspectRatio: aspectRatioInputLabel,
    backgroundColor: backgroundColorInputLabel,
    buttonsTextColor: buttonsTextColorInputLabel,
    consentText: consentTextInputLabel,
    date: dateInputLabel,
    emailBottomBodyText: emailBottomBodyTextInputLabel,
    emailCallToAction: emailCallToActionInputLabel,
    emailFromName: emailFromNameInputLabel,
    emailSubject: emailSubjectInputLabel,
    emailTopBodyText: emailTopBodyTextInputLabel,
    enableDownload: enableDownloadInputLabel,
    enablePublic: enablePublicInputLabel,
    enableShare: enableShareInputLabel,
    eventNameColor: eventNameColorInputLabel,
    facebookText: facebookTextInputLabel,
    facebookTitle: facebookTitleInputLabel,
    foregroundColor: foregroundColorInputLabel,
    ownerEmail: ownerEmailInputLabel,
    smsProvider: smsProviderInputLabel,
    smsText: smsTextInputLabel,
    textColor: textColorInputLabel,
    title: titleInputLabel,
    topBodyText: topBodyTextInputLabel,
    twitterText: twitterTextInputLabel,
} = inputsLabelsConstants;

const {
    consent: {
        key: consentKey,
        tab: consentTab,
    },
    eventDetails: {
        key: eventDetailsKey,
        tab: eventDetailsTab,
    },
    publicPage: {
        key: publicPageKey,
        tab: publicPageTab,
    },
    shareDetails: {
        key: shareDetailsKey,
        tab: shareDetailsTab,
    },
    socialSharing: {
        key: socialSharingKey,
        tab: socialSharingTab,
    },
} = updateEventTabsConstants;

const updateEventFormData = {
    forms: {
        consent: [
            {
                col: { xs: 12 },
                label: consentTextInputLabel,
                name: "consentText",
                requestName: "consentBody",
                type: "text-area",
            },
        ],
        eventDetails: [
            {
                col: {
                    md: 6,
                    xs: 12,
                },
                label: titleInputLabel,
                name: "title",
                requestName: "title",
                type: "text-field",
            },
            {
                col: {
                    md: 6,
                    xs: 12,
                },
                label: ownerEmailInputLabel,
                name: "ownerEmail",
                requestName: "owner",
                type: "text-field",
            },
            {
                col: {
                    md: 6,
                    xs: 12,
                },
                label: dateInputLabel,
                name: "date",
                requestName: "date",
                type: "date",
            },
            {
                col: {
                    md: 6,
                    xs: 12,
                },
                label: aspectRatioInputLabel,
                name: "aspectRatio",
                requestName: "aspectRatio",
                type: "select",
            },
        ],
        publicPage: [
            {
                col: { xs: 12 },
                label: titleInputLabel,
                name: "pageTitle",
                requestName: "title",
                type: "text-field",
            },
            {
                col: { xs: 12 },
                label: topBodyTextInputLabel,
                name: "pageTopBodyText",
                requestName: "text",
                type: "text-area",
            },
            {
                col: {
                    lg: 2,
                    md: 4,
                    sm: 6,
                    xs: 12,
                },
                label: backgroundColorInputLabel,
                name: "backgroundColor",
                requestName: "backgroundColor",
                type: "color-picker",
            },
            {
                col: {
                    lg: 2,
                    md: 4,
                    sm: 6,
                    xs: 12,
                },
                label: textColorInputLabel,
                name: "textColor",
                requestName: "textColor",
                type: "color-picker",
            },
            {
                col: {
                    lg: 2,
                    md: 4,
                    sm: 6,
                    xs: 12,
                },
                label: foregroundColorInputLabel,
                name: "foregroundColor",
                requestName: "foregroundColor",
                type: "color-picker",
            },
            {
                col: {
                    lg: 2,
                    md: 4,
                    sm: 6,
                    xs: 12,
                },
                label: buttonsTextColorInputLabel,
                name: "buttonsTextColor",
                requestName: "buttonsColor",
                type: "color-picker",
            },
            {
                col: {
                    lg: 2,
                    md: 4,
                    sm: 6,
                    xs: 12,
                },
                label: eventNameColorInputLabel,
                name: "eventNameColor",
                requestName: "headerColor",
                type: "color-picker",
            },
            {
                col: { xs: 12 },
                label: enableDownloadInputLabel,
                name: "enableDownload",
                requestName: "download",
                type: "switch",
            },
            {
                col: { xs: 12 },
                label: enableShareInputLabel,
                name: "enableShare",
                requestName: "share",
                type: "switch",
            },
            {
                col: { xs: 12 },
                label: enablePublicInputLabel,
                name: "enablePublic",
                requestName: "isPrivate",
                type: "switch",
            },
        ],
        shareDetails: [
            {
                col: {
                    md: 6,
                    xs: 12,
                },
                label: smsTextInputLabel,
                name: "smsText",
                requestName: "smsText",
                type: "text-field",
            },
            {
                col: {
                    md: 6,
                    xs: 12,
                },
                label: smsProviderInputLabel,
                name: "smsProvider",
                requestName: "smsProvider",
                type: "select",
            },
            {
                col: {
                    md: 6,
                    xs: 12,
                },
                label: emailFromNameInputLabel,
                name: "emailFromName",
                requestName: "emailFromName",
                type: "text-field",
            },
            {
                col: {
                    md: 6,
                    xs: 12,
                },
                label: emailSubjectInputLabel,
                name: "emailSubject",
                requestName: "emailSubject",
                type: "text-field",
            },
            {
                col: {
                    md: 6,
                    xs: 12,
                },
                label: emailTopBodyTextInputLabel,
                name: "emailTopBodyText",
                requestName: "emailTopBody",
                type: "text-area",
            },
            {
                col: {
                    md: 6,
                    xs: 12,
                },
                label: emailBottomBodyTextInputLabel,
                name: "emailBottomBodyText",
                requestName: "emailBottomBody",
                type: "text-area",
            },
            {
                col: {
                    md: 6,
                    xs: 12,
                },
                label: emailCallToActionInputLabel,
                name: "emailCallToAction",
                requestName: "emailCallToAction",
                type: "text-field",
            },
        ],
        socialSharing: [
            {
                col: { xs: 12 },
                label: facebookTitleInputLabel,
                name: "facebookTitle",
                requestName: "facebookTitle",
                type: "text-field",
            },
            {
                col: { xs: 12 },
                label: facebookTextInputLabel,
                name: "facebookText",
                requestName: "facebookText",
                type: "text-area",
            },
            {
                col: { xs: 12 },
                label: twitterTextInputLabel,
                name: "twitterText",
                requestName: "twitterText",
                type: "text-area",
            },
        ],
    },
    tabs: [
        {
            FormComponent: UpdateEventDetailsForm,
            key: eventDetailsKey,
            tab: eventDetailsTab,
        },
        {
            FormComponent: UpdateEventSocialSharingForm,
            key: socialSharingKey,
            tab: socialSharingTab,
        },
        {
            FormComponent: UpdateEventShareDetailsForm,
            key: shareDetailsKey,
            tab: shareDetailsTab,
        },
        {
            FormComponent: UpdateEventPublicPageForm,
            key: publicPageKey,
            tab: publicPageTab,
        },
        {
            FormComponent: UpdateEventConsentForm,
            key: consentKey,
            tab: consentTab,
        },
    ],
};

export default updateEventFormData;
