import { Typography } from "@mui/material";
import { styled } from "@mui/styles";

const StyledStatusLabel = styled(Typography)(({
    theme,
    type,
}) => ({
    background: type ? theme.palette.success.light : theme.palette.error.light,
    borderRadius: theme.shape.borderRadius,
    color: type ? theme.palette.success.dark : theme.palette.error.dark,
    display: "inline-block",
    fontWeight: "bold",
    height: "32px",
    minWidth: "37px",
    padding: "8px 16px",
    width: "fit-content",
}));

export default StyledStatusLabel;
