import { Box } from "@mui/material";
import { styled } from "@mui/styles";

const StyledFileUploader = styled(Box)(({
    error,
    theme,
}) => ({
    "& .file-types": { display: "none" },
    "& label": {
        border: "none",
        cursor: "pointer",
        display: "flex",
        gap: 5,
        maxWidth: "100%",
        minHeight: "150px",
        width: "100%",
    },
    "& span": {
        color: theme.palette.grey[theme.palette.mode === "dark" ? 200 : 900],
        fontSize: "15px",
    },
    "& svg path": { fill: theme.palette.primary.main },
    "&:hover": {
        opacity: ".8",
        transition: ".3s all",
    },
    background: theme.palette.grey[theme.palette.mode === "dark" ? 900 : 200],
    border: `1px dotted ${error ? theme.palette.error.main : theme.palette.grey[400]}`,
    borderRadius: theme.shape.borderRadius,
    minHeight: "150px",
    padding: "8px",
    width: "100%",
}));

export default StyledFileUploader;
