export const GET_USER_INFO_SUCCESS = "GET_USER_INFO_SUCCESS";

export const USER_LOG_IN_SUCCESS = "USER_LOG_IN_SUCCESS";

export const USER_LOG_OUT_SUCCESS = "USER_LOG_OUT_SUCCESS";

export const USER_LOGGING_OUT = "USER_LOGGING_OUT";

export const AUTH_REQUEST = "AUTH_REQUEST";

export const AUTH_FAIL = "AUTH_FAIL";
