import { apiClient, apisUrls } from "apis";
import { getCookie } from "utils";

const {
    auth: {
        login: loginApiUrl,
        logout: logoutApiUrl,
        setPassword: setPasswordApiUrl,
    },
} = apisUrls;

export const postLoginData = (data) => apiClient.post(
    loginApiUrl,
    null,
    data,
    true,
    true,
    true,
);

export const postLogoutData = () => apiClient.post(
    logoutApiUrl,
    null,
    { token: getCookie("token") },
);

export const postSetPasswordData = ({
    data,
    token,
}) => apiClient.update(
    setPasswordApiUrl,
    null,
    data,
    false,
    null,
    null,
    true,
    token,
);
