import { Card } from "@mui/material";
import { styled } from "@mui/styles";

export const StyledCard = styled(Card)(({ theme }) => ({
    "& .MuiCardContent-root": { padding: 0 },
    borderRadius: "16px",
    boxShadow: theme.shape.boxShadow,
    marginBlock: "16px",
    padding: "16px",
    position: "relative",
}));

export const StyledCardWithActions = styled(Card)(({ theme }) => ({
    "& .MuiCardActions-root": {
        "& a": { height: "fit-content" },
        "& button": {
            height: "fit-content",
            padding: "6px",
        },
        alignItems: "initial",
        padding: 0,
    },
    "& .MuiCardContent-root": { padding: 0 },
    "& a": {
        color: theme.palette.grey[theme.palette.mode === "dark" ? 100 : 600],
        marginLeft: "5px",
        marginTop: "5px",
        textDecoration: "none",
    },
    "&:hover span": { opacity: 1 },
    backgroundColor: theme.palette.grey[theme.palette.mode === "dark" ? 800 : 100],
    borderRadius: "16px",
    boxShadow: theme.shape.boxShadow2,
    minHeight: "200px",
    padding: "16px",
    position: "relative",
    width: "100%",
}));
