import { inputsLabelsConstants } from "@constants";

const {
    copyEvent: copyEventInputLabel,
    title: titleInputLabel,
    useTemplate: useTemplateInputLabel,
} = inputsLabelsConstants;

const createEventFormData = [
    {
        col: {
            md: 6,
            xs: 12,
        },
        label: titleInputLabel,
        name: "title",
        type: "text-field",
    },
    {
        col: {
            md: 6,
            xs: 12,
        },
        label: copyEventInputLabel,
        name: "event",
        type: "auto-complete",
    },
    {
        col: {
            md: 6,
            xs: 12,
        },
        label: useTemplateInputLabel,
        name: "template",
        type: "auto-complete",
    },
];

export default createEventFormData;
