import Drawer from "@mui/material/Drawer";
import { styled } from "@mui/styles";

const sidebarWidth = 240;

const openedMixin = (theme) => ({
    overflowX: "hidden",
    transition: theme.transitions.create(
        "width",
        {
            duration: theme.transitions.duration.enteringScreen,
            easing: theme.transitions.easing.sharp,
        },
    ),
    width: `${sidebarWidth}px!important`,
});

const closedMixin = (theme) => ({
    overflowX: "hidden",
    transition: theme.transitions.create(
        "width",
        {
            duration: theme.transitions.duration.leavingScreen,
            easing: theme.transitions.easing.sharp,
        },
    ),
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up("sm")]: { width: `calc(${theme.spacing(8)} + 1px)` },
});

export const StyledSidebarHeader = styled("div")(({ theme }) => ({
    alignItems: "center",
    display: "flex",
    justifyContent: "space-between",
    padding: theme.spacing(
        0,
        1,
    ),
    ...theme.mixins.toolbar,
}));

export const StyledSidebar = styled(
    Drawer,
    { shouldForwardProp: (prop) => prop !== "open" },
)(({
    open,
    theme,
}) => ({
    boxSizing: "border-box",
    flexShrink: 0,
    whiteSpace: "nowrap",
    width: sidebarWidth,
    ...open && {
        ...openedMixin(theme),
        "& .MuiDrawer-paper": openedMixin(theme),
    },
    ...!open && {
        ...closedMixin(theme),
        "& .MuiDrawer-paper": closedMixin(theme),
    },
    "& .MuiButtonBase-root.active": { color: theme.palette.primary.main },
}));
