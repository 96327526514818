import {
    btnsNamesConstants,
    filtersConstants,
    messagesConstants,
    modulesConstants,
} from "@constants";
import ArchiveIcon from "@mui/icons-material/Archive";
import DownloadIcon from "@mui/icons-material/Download";
import EmailIcon from "@mui/icons-material/Email";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import UnarchiveIcon from "@mui/icons-material/Unarchive";
import {
    Box,
    Button,
    CardContent,
    Grid,
} from "@mui/material";
import { Loader, Table } from "atoms";
import { Header, NoData } from "components";
import { useExportEventGuestsListData, useGetEventData } from "hooks";
import { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { exportEventGuests, showLoadingLoader } from "redux/actions";
import { StyledCard } from "styles";
import { getQuery, getSearchParams } from "utils";
import { ServerErrorView } from "views";

function ListLayout({
    backRouteUrl,
    createRouteUrl,
    customizedList,
    data,
    directionArray,
    error,
    getListAction,
    hasCreateBtn,
    headerTitle,
    list,
    moduleName,
    moduleRouteUrl,
    pageTitle,
    paginated = true,
    sortArray,
    tableColumnsNames,
    tableFields,
}) {
    const dispatch = useDispatch();

    const loggingOut = useSelector((state) => state.authReducer.loggingOut);

    const loading = useSelector((state) => state.loadingReducer.show);

    const navigate = useNavigate();

    const [searchParams] = useSearchParams();

    const {
        archived: archivedBtnName,
        create: createBtnName,
        email: emailBtnName,
        export: exportBtnName,
        phone: phoneBtnName,
        unarchived: unarchivedBtnName,
    } = btnsNamesConstants;

    const {
        exportMsgs: { pending: exportPendingMsg },
        fail: failMsg,
        success: successMsg,
    } = messagesConstants;

    const {
        eventGuests: eventGuestsModule,
        events: eventsModule,
    } = modulesConstants;

    const {
        archived: archivedFilter,
        email: emailFilter,
        phone: phoneFilter,
        unarchived: unarchivedFilter,
    } = filtersConstants;

    const { id: idParam } = useParams();

    const {
        filter: filterSearchParam,
        search: searchSearchParam,
    } = getSearchParams(searchParams);

    const {
        data: getEventData,
        isSuccess: getEventIsSuccess,
        refetch: refetchGetEventData,
    } = useGetEventData(idParam);

    const {
        data: exportEventGuestsListData,
        error: exportEventGuestsListError,
        isError,
        isSuccess,
        refetch,
    } = useExportEventGuestsListData(
        idParam,
        filterSearchParam || phoneFilter,
    );

    const exportEventGuestsHandler = () => {
        toast.loading(exportPendingMsg);

        refetch();
    };

    const changeFilterHandler = (filter, module) => {
        const query = getQuery(
            0,
            10,
            filter,
            searchSearchParam,
        );

        navigate({ search: query });

        if (module === eventGuestsModule) {
            getListAction({
                data: {
                    count: 10,
                    direction: directionArray,
                    page: 0,
                    sort: sortArray,
                },
                id: idParam,
                subApi: filter,
            });
        } else {
            getListAction({
                count: 10,
                direction: directionArray,
                isArchived: filter === archivedFilter,
                ...searchSearchParam && { search: searchSearchParam },
                page: 0,
                sort: sortArray,
            });
        }
    };

    const renderList = () => {
        if (loading) return <Loader />;

        if (list?.length === 0 && data?.noData) return <NoData />;

        return (
            <Table
                columnsNames={tableColumnsNames}
                directionArray={directionArray}
                fields={tableFields}
                getListAction={getListAction}
                moduleName={moduleName}
                moduleRouteUrl={moduleRouteUrl}
                paginated={paginated}
                sortArray={sortArray}
                data={{
                    ...data,
                    data: [...customizedList],
                }}
            />
        );
    };

    useEffect(() => {
        dispatch(showLoadingLoader());
    }, []); // eslint-disable-line

    useEffect(() => {
        if (isSuccess && moduleName === eventGuestsModule) {
            dispatch(exportEventGuests(
                exportEventGuestsListData.data.url,
                successMsg,
            ));

            toast.dismiss();
        }
    }, [isSuccess]); // eslint-disable-line

    useEffect(() => {
        if (isError && moduleName === eventGuestsModule) {
            dispatch(exportEventGuests(
                null,
                failMsg,
                exportEventGuestsListError,
            ));

            toast.dismiss();
        }
    }, [isError]); // eslint-disable-line

    useEffect(() => {
        if (moduleName === eventGuestsModule) refetchGetEventData();
    }, [moduleName]); // eslint-disable-line

    if (error?.status === 500) return <ServerErrorView />;

    return loggingOut ? (
        <>
            <Helmet>
                <title>{pageTitle}</title>
            </Helmet>
            <Grid
                alignItems="center"
                minHeight="100vh"
                spacing={2}
                container
            >
                <Loader />
            </Grid>
        </>
    ) : (
        <>
            <Helmet>
                <title>{pageTitle}</title>
            </Helmet>
            <Header
                backRouteUrl={backRouteUrl}
                createBtnName={createBtnName}
                createRouteUrl={createRouteUrl}
                hasBackBtn={!!backRouteUrl}
                hasCreateBtn={hasCreateBtn}
                title={moduleName === eventGuestsModule && getEventData?.data?.title && getEventIsSuccess ? `${getEventData.data.title} ${headerTitle}` : headerTitle}
            />
            {(moduleName === eventGuestsModule) || (moduleName === eventsModule) ? (
                <Box
                    display="flex"
                    flexWrap="wrap"
                    justifyContent="space-between"
                >
                    <Box mb={2}>
                        <Button
                            disabled={((moduleName === eventGuestsModule) && (filterSearchParam === phoneFilter || filterSearchParam !== emailFilter)) || (moduleName === eventsModule && (filterSearchParam === unarchivedFilter || filterSearchParam !== archivedFilter))}
                            size="large"
                            sx={{ marginRight: 2 }}
                            variant="contained"
                            onClick={() => {
                                changeFilterHandler(
                                    moduleName === eventGuestsModule ? phoneFilter : unarchivedFilter,
                                    moduleName,
                                );
                            }}
                        >
                            {moduleName === eventGuestsModule ? <LocalPhoneIcon style={{ marginRight: "5px" }} /> : <UnarchiveIcon style={{ marginRight: "5px" }} />}
                            {moduleName === eventGuestsModule ? phoneBtnName : unarchivedBtnName}
                        </Button>
                        <Button
                            disabled={(moduleName === eventGuestsModule && filterSearchParam === emailFilter) || (moduleName === eventsModule && filterSearchParam === archivedFilter)}
                            size="large"
                            variant="contained"
                            onClick={() => {
                                changeFilterHandler(
                                    moduleName === eventGuestsModule ? emailFilter : archivedFilter,
                                    moduleName,
                                );
                            }}
                        >
                            {moduleName === eventGuestsModule ? <EmailIcon style={{ marginRight: "5px" }} /> : <ArchiveIcon style={{ marginRight: "5px" }} />}
                            {moduleName === eventGuestsModule ? emailBtnName : archivedBtnName}
                        </Button>
                    </Box>
                    {moduleName === eventGuestsModule && (
                        <Box mb={2}>
                            <Button
                                size="large"
                                variant="outlined"
                                onClick={exportEventGuestsHandler}
                            >
                                <DownloadIcon style={{ marginRight: "5px" }} />
                                {exportBtnName}
                            </Button>
                        </Box>
                    )}
                </Box>
            ) : null}
            <StyledCard>
                <CardContent>{renderList()}</CardContent>
            </StyledCard>
        </>
    );
}

export default ListLayout;
