import { messagesConstants } from "@constants";
import {
    hideLoadingLoader,
    hideSendingLoader,
    logout,
    openAlert,
} from "redux/actions";
import * as types from "redux/types";

const {
    activateMsgs: {
        fail: activateFailMsg,
        success: activateSuccessMsg,
    },
    authMsgs: { unAuthorized: unAuthorizedMsg },
    createMsgs: {
        fail: createFailMsg,
        success: createSuccessMsg,
    },
    deactivateMsgs: {
        fail: deactivateFailMsg,
        success: deactivateSuccessMsg,
    },
    fail: failMsg,
    resetPasswordMsgs: {
        fail: resetPasswordFailMsg,
        success: resetPasswordSuccessMsg,
    },
    saveMsgs: {
        fail: saveFailMsg,
        success: saveSuccessMsg,
    },
    success: successMsg,
} = messagesConstants;

export const getAdmins = (data, state, error) => async (dispatch) => {
    if (state === successMsg) {
        dispatch({
            payload: { data },
            type: types.GET_ADMINS_SUCCESS,
        });

        dispatch(hideLoadingLoader());
    } else {
        dispatch({
            payload: { error: error.request },
            type: types.ADMINS_FAIL,
        });

        if (error.request.status === 0 || error.request.status === 401) {
            dispatch(logout());

            dispatch(openAlert(
                unAuthorizedMsg,
                failMsg,
            ));
        }

        dispatch(hideLoadingLoader());
    }
};

export const getAdmin = (data, state, error) => async (dispatch) => {
    if (state === successMsg) {
        dispatch({
            payload: { data },
            type: types.GET_ADMIN_SUCCESS,
        });

        dispatch(hideLoadingLoader());
    } else {
        dispatch({
            payload: { error: error.request },
            type: types.ADMINS_FAIL,
        });

        if (error.request.status === 0 || error.request.status === 401) {
            dispatch(logout());

            dispatch(openAlert(
                unAuthorizedMsg,
                failMsg,
            ));
        }

        dispatch(hideLoadingLoader());
    }
};

export const createAdmin = (data, state, error) => async (dispatch) => {
    if (state === successMsg) {
        dispatch({
            payload: { data },
            type: types.CREATE_ADMIN_SUCCESS,
        });

        dispatch(openAlert(
            createSuccessMsg,
            successMsg,
        ));

        dispatch(hideSendingLoader());
    } else {
        dispatch({
            payload: { error: error.request },
            type: types.ADMINS_FAIL,
        });

        if (error.request.status === 0 || error.request.status === 401) {
            dispatch(logout());

            dispatch(openAlert(
                unAuthorizedMsg,
                failMsg,
            ));
        } else {
            dispatch(openAlert(
                createFailMsg,
                failMsg,
            ));
        }

        dispatch(hideSendingLoader());
    }
};

export const updateAdmin = (data, state, error) => async (dispatch) => {
    if (state === successMsg) {
        dispatch({
            payload: { data },
            type: types.UPDATE_ADMIN_SUCCESS,
        });

        dispatch(openAlert(
            saveSuccessMsg,
            successMsg,
        ));

        dispatch(hideSendingLoader());
    } else {
        dispatch({
            payload: { error: error.request },
            type: types.ADMINS_FAIL,
        });

        if (error.request.status === 0 || error.request.status === 401) {
            dispatch(logout());

            dispatch(openAlert(
                unAuthorizedMsg,
                failMsg,
            ));
        } else {
            dispatch(openAlert(
                saveFailMsg,
                failMsg,
            ));
        }

        dispatch(hideSendingLoader());
    }
};

export const activateAdmin = (data, state, error) => async (dispatch) => {
    if (state === successMsg) {
        dispatch({
            payload: { data },
            type: types.ACTIVATE_ADMIN_SUCCESS,
        });

        dispatch(openAlert(
            activateSuccessMsg,
            successMsg,
        ));
    } else {
        dispatch({
            payload: { error: error.request },
            type: types.ADMINS_FAIL,
        });

        if (error.request.status === 0 || error.request.status === 401) {
            dispatch(logout());

            dispatch(openAlert(
                unAuthorizedMsg,
                failMsg,
            ));
        } else {
            dispatch(openAlert(
                activateFailMsg,
                failMsg,
            ));
        }

        dispatch(hideLoadingLoader());
    }
};

export const deactivateAdmin = (data, state, error) => async (dispatch) => {
    if (state === successMsg) {
        dispatch({
            payload: { data },
            type: types.DEACTIVATE_ADMIN_SUCCESS,
        });

        dispatch(openAlert(
            deactivateSuccessMsg,
            successMsg,
        ));
    } else {
        dispatch({
            payload: { error: error.request },
            type: types.ADMINS_FAIL,
        });

        if (error.request.status === 0 || error.request.status === 401) {
            dispatch(logout());

            dispatch(openAlert(
                unAuthorizedMsg,
                failMsg,
            ));
        } else {
            dispatch(openAlert(
                deactivateFailMsg,
                failMsg,
            ));
        }

        dispatch(hideLoadingLoader());
    }
};

export const resetAdminPassword = (data, state, error) => async (dispatch) => {
    if (state === successMsg) {
        dispatch({
            payload: { data },
            type: types.RESET_ADMIN_PASSWORD_SUCCESS,
        });

        dispatch(openAlert(
            resetPasswordSuccessMsg,
            successMsg,
        ));
    } else {
        dispatch({
            payload: { error: error.request },
            type: types.ADMINS_FAIL,
        });

        if (error.request.status === 0 || error.request.status === 401) {
            dispatch(logout());

            dispatch(openAlert(
                unAuthorizedMsg,
                failMsg,
            ));
        } else {
            dispatch(openAlert(
                resetPasswordFailMsg,
                failMsg,
            ));
        }
    }
};
