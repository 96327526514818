import { StyledMedia } from "styles";

function Media({
    alternativeText,
    height,
    src,
    style,
    type,
    width,
}) {
    return (
        <StyledMedia>
            {type === "image" ? (
                <img
                    alt={alternativeText}
                    height={height || "100"}
                    src={src}
                    width={width || "100"}
                    style={{
                        height: height || "100%",
                        width: width || "100%",
                        ...style,
                    }}
                />
            ) : (
                <video
                    height={height || "100"}
                    src={src}
                    width={width || "150"}
                    style={{
                        height: height || "100%",
                        width: width || "100%",
                    }}
                    controls
                />
            )}
        </StyledMedia>
    );
}

export default Media;
