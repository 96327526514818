import { viewsConstants } from "@constants";
import { Box, Grid, Typography } from "@mui/material";

function ForbiddenView() {
    const { forbidden: forbiddenConstant } = viewsConstants.forbidden;

    return (
        <Grid
            alignItems="center"
            flexDirection="column"
            height="100vh"
            justifyContent="center"
            margin="auto"
            textAlign="center"
            width="90%"
            container
        >
            <Box>
                <Typography
                    color="primary"
                    component="h1"
                    variant="h5"
                >
                    {forbiddenConstant}
                </Typography>
            </Box>
        </Grid>
    );
}

export default ForbiddenView;
