import * as types from "redux/types";

const initialState = {
    error: null,
    isLoggedIn: false,
    isLoggedOut: true,
    loader: true,
    loggingOut: false,
    token: null,
    user: null,
};

const authReducer = (state = initialState, action) => {
    const {
        payload,
        type,
    } = action;

    const {
        data,
        error,
    } = payload || {};

    switch (type) {
    case types.USER_LOG_IN_SUCCESS:
        return {
            ...state,
            isLoggedIn: true,
            isLoggedOut: false,
            loader: false,
            loggingOut: false,
            token: data.access_token,
            user: data.user,
        };

    case types.USER_LOG_OUT_SUCCESS:
        return {
            ...state,
            error: null,
            isLoggedIn: false,
            isLoggedOut: true,
            loader: false,
            loggingOut: false,
            token: null,
            user: null,
        };

    case types.USER_LOGGING_OUT:
        return {
            ...state,
            loggingOut: true,
        };

    case types.GET_USER_INFO_SUCCESS:
        return {
            ...state,
            isLoggedIn: true,
            isLoggedOut: false,
            loader: false,
            loggingOut: false,
            token: data.token,
            user: data.user,
        };

    case types.AUTH_REQUEST:
        return {
            ...state,
            error: null,
            loader: true,
        };

    case types.AUTH_FAIL:
        return {
            ...state,
            error,
            isLoggedIn: false,
            isLoggedOut: true,
            loader: false,
            loggingOut: false,
            token: null,
            user: null,
        };

    default: return state;
    }
};

export default authReducer;
