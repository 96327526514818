import { yupResolver } from "@hookform/resolvers/yup";
import { TextField } from "@mui/material";
import { createEventTemplateFormData } from "data";
import { FormLayout } from "layouts";
import { useForm } from "react-hook-form";
import { createEventTemplateFormSchema } from "schemas";
import { renderFormController } from "utils";

function CreateEventTemplateForm({
    action,
    onSubmitForm,
    values,
}) {
    const formMethods = useForm({
        defaultValues: values,
        mode: "onChange",
        resolver: yupResolver(createEventTemplateFormSchema),
    });

    const {
        control,
        formState: { errors },
        reset,
    } = formMethods;

    const createEventTemplateHandler = ({ title }) => {
        onSubmitForm(
            { title },
            reset,
        );
    };

    const renderFormControllerChildren = (field, label, name) => (
        <TextField
            {...field}
            error={errors[name]}
            helperText={errors[name] && errors[name]?.message}
            label={label}
            variant="outlined"
            fullWidth
        />
    );

    return (
        <FormLayout
            action={action}
            formMethods={formMethods}
            data={renderFormController(
                control,
                createEventTemplateFormData,
                renderFormControllerChildren,
            )}
            onSubmitForm={createEventTemplateHandler}
        />
    );
}

export default CreateEventTemplateForm;
