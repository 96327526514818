import { btnsNamesConstants } from "@constants";
import { yupResolver } from "@hookform/resolvers/yup";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import { Checkbox, FormControlLabel, TextField } from "@mui/material";
import { photographerFormData } from "data";
import { FormLayout } from "layouts";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { adminAndPhotographerFormSchema } from "schemas";
import { renderFormController } from "utils";

function PhotographerForm({
    action,
    onSubmitForm,
    values,
}) {
    const formMethods = useForm({
        defaultValues: values,
        mode: "onChange",
        resolver: yupResolver(adminAndPhotographerFormSchema),
    });

    const { setAsDefault: setAsDefaultBtnName } = btnsNamesConstants;

    const {
        control,
        formState: { errors },
        setValue,
    } = formMethods;

    const [isSelected, setIsSelected] = useState(values.isDefault);

    const renderFormControllerChildren = (field, label, name, type) => {
        if (type === "checkbox") {
            return (
                <FormControlLabel
                    {...field}
                    label={setAsDefaultBtnName}
                    style={{ marginLeft: 2 }}
                    control={(
                        <Checkbox
                            {...field}
                            checked={isSelected}
                            checkedIcon={<CheckBoxIcon color="primary" />}
                            icon={<CheckBoxOutlineBlankIcon />}
                            style={{ marginRight: 8 }}
                            onChange={(e) => {
                                setIsSelected(e.target.checked);

                                setValue(
                                    "isDefault",
                                    !isSelected,
                                    { shouldDirty: true },
                                );
                            }}
                        />
                    )}
                />
            );
        }

        return (
            <TextField
                {...field}
                error={errors[name]}
                helperText={errors[name] && errors[name]?.message}
                label={label}
                variant="outlined"
                fullWidth
            />
        );
    };

    return (
        <FormLayout
            action={action}
            formMethods={formMethods}
            data={renderFormController(
                control,
                photographerFormData,
                renderFormControllerChildren,
            )}
            onSubmitForm={onSubmitForm}
        />
    );
}

export default PhotographerForm;
